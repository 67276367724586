import { createGlobalStyle, css } from 'styled-components';
import 'modali/dist/modali.css';
import theme from '~/lib/theme';

// clerk overrides general css styles
const clerkFontFamilyCss = css`
  font-family: 'Neue Montreal';
  font-style: normal;
`;

const clerkBaseFontCss = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.4px;
`;

const clerkThinFontCss = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.42px;
`;

const clerkSmallFontCss = css`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.48px;
`;

const clerkSmallThickFontCss = css`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.48px;
`;

const clerkBigFontCss = css`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.32px;
`;

const clerkErrorCss = css`
  text-align: left;
  color: rgba(205, 0, 8, 0.76);
  ${clerkSmallFontCss}
`;

const clerkBlackButtonBaseCss = css`
  border-radius: 9999px;
  background: #1c2024;
  display: flex;
  height: 40px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
`;

const clerkFactorStepsBaseCss = css`
  gap: 40px;
  .cl-identityPreview {
    justify-content: unset;
    p {
      ${clerkFontFamilyCss}
      color: rgba(0, 7, 19, 0.62);
      ${clerkBaseFontCss}
    }
  }
  .cl-form {
    gap: 0px;
  }
  .cl-formHeaderTitle {
    text-align: left;
    color: #1c2024;
    ${clerkBaseFontCss}
    margin-top: 9px;
  }
  .cl-formHeaderSubtitle {
    text-align: left;
    color: #60646c;
    ${clerkThinFontCss}
  }
  .cl-otpCodeFieldInputs {
    width: 100%;
    justify-content: space-between;
  }
  .cl-otpCodeFieldInput {
    width: 48px;
    max-width: unset;
    color: #1c2024;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
  }
  .cl-otpCodeFieldErrorText {
    color: rgba(205, 0, 8, 0.76);
    ${clerkSmallFontCss}
    svg {
      display: none;
    }
  }
  .cl-internal-1s2rqwh {
    width: 100%;
  }
  .cl-formResendCodeLink {
    width: 100%;
    text-align: center;
    color: #60646c;
    ${clerkBaseFontCss}
    margin: 0px;
  }
`;

const clerkBackgroundImageCss = css`
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
`;

// https://gist.github.com/thisisadityarao/cc86efbdd916c6031f8ca3601c107d71
const GlobalStyles = createGlobalStyle`
  /****** IMPORTED FONTS ******/
  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-Light.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-Light.woff')}) format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-Italic.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-Italic.woff')}) format('woff');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-Medium.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-Medium.woff')}) format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-Regular.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-Regular.woff')}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-MediumItalic.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-MediumItalic.woff')}) format('woff');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-LightItalic.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-LightItalic.woff')}) format('woff');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-Bold.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-Bold.woff')}) format('woff');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-BoldItalic.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-BoldItalic.woff')}) format('woff');
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: 'Roboto';
    src: url(${require('~/assets/fonts/Roboto-Regular.ttf')});
    font-weight: 400;
    font-style: normal;
  }

  /* ==========================================================================
    GLOBAL STYLES
    ========================================================================== */

  /* Box Sizing
    ========================================================================== */
  html {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    font-size: 14px;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  body,
  button,
  input,
  select,
  textarea {
    font-family: 'NeueMontreal', sans-serif;
    font-weight: 300;
  }

  body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    line-height: 1.6;
    /* background-color: ${props => props.theme.bgColor.muted}; */
    background-color: ${props => props.theme.gray};
  }

  #root {
    padding: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  /* PARAGRAPHS
    ========================================================================== */

  p {
    font-family: 'Neue Montreal';
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: ${props => props.theme.darker};
    margin: 0;
    hyphens: auto;
  }

  /* HEADINGS
    ========================================================================== */

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Neue Montreal';
    color: ${props => props.theme.darker};
    margin: 0;
  }

  h1 {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
  }

  h2 {
    color: ${props => props.theme.dark};
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }

  h3 {
    color: ${props => props.theme.copperDarkest};
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  h4 {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: ${props => props.theme.darker};
  }

  /* TABLES
    ========================================================================== */

  th {
    font-family: 'Neue Montreal';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: ${props => props.theme.dark};
    vertical-align: bottom;
  }

  td {
    font-family: 'Neue Montreal';
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: ${props => props.theme.darker};
    margin: 0;
    hyphens: auto;
  }

  /* LINKS
    ========================================================================== */

  a {
    text-decoration: none;
    color: ${props => props.theme.link.color};
  }
  a[data-underline] {
    text-decoration: underline;
  }

  /* A better looking default horizontal rule
    ========================================================================== */

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
  }

  /*
  * Remove the gap between audio, canvas, iframes,
  * images, videos and the bottom of their containers:
  * https://github.com/h5bp/html5-boilerplate/issues/440
  ========================================================================== */

  img,
  svg {
    vertical-align: middle;
  }

  /* IMAGES
    ========================================================================== */

  img {
    height: auto;
    width: auto;
    /* max-width allows image to be responsive. */
    max-width: 100%;
  }

  /* LISTS
    ========================================================================== */
  ul {
    padding-left: 20px;
  }

  /* dont add padding from modali when it's open */
  @media (min-width: 1025px) {
    .modali-open {
      padding-right: 0 !important;
      overflow: unset !important;
    }
  }

  /* make modal body from modali scrollable */
  .modali-body-style {
    max-height: 70vh;
    overflow-y: auto;
  }

  .modali-overlay {
    z-index: ${theme.zIndex.modal};
  }
  .modali-wrapper {
    z-index: ${theme.zIndex.modal + 1};
  }

  /* stop modali from using both in animation-fill-mode */
  .modali-animated {
    animation-fill-mode: none !important;
  }

  /* clerk overrides */
  .cl-rootBox {
    ${clerkFontFamilyCss}
  }
  .cl-card {
    min-width: 344px;
    box-shadow: none;
    margin: 0px 17px;
    padding: 28.5px 2px 36px;
    @media (max-width: 1025px) {
      min-width: 0px;
    }
  }

  .cl-header {
    margin: 20px 0;
    gap: 8px;
  }

  .cl-headerTitle {
    color: #1C2024;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
  }

  .cl-headerSubtitle {
    text-align: center;
    color: #60646C;
    ${clerkThinFontCss}
  }

  .cl-headerBackRow {
    justify-content: center;
    a {
      gap: 8px;
      svg {
        height: 16px;
        width: 16px;
        color: #60646C;
      }
    }
  }

  .cl-headerBackLink {
    color: #60646C;
    ${clerkBaseFontCss}
  }

  .cl-alternativeMethods {
    gap: 12px;
  }

  .cl-socialButtons {
    gap: 12px;
  }

  .cl-alternativeMethodsBlockButton {
    ${clerkBlackButtonBaseCss}
    :hover {
      border-radius: 9999px;
      background: #1C2024;
      svg {
        width: 0px;
        height: 0px;
        stroke: #1C2024;
      }
    }
    svg {
      width: 0px;
      height: 0px;
    }
  }

  .cl-alternativeMethodsBlockButtonText {
    color: #FCFCFD;
    ${clerkBigFontCss}
  }

  .cl-internal-egu47 {
    justify-content: center;
  }

  .cl-identityPreview {
    height: 32px;
    max-width: unset;
    justify-content: space-between;
    gap: 8px;
    .cl-identityPreviewText {
      ${clerkFontFamilyCss}
      color: rgba(0, 7, 19, 0.62);
      ${clerkBaseFontCss}
    }
    div {
      margin-right: 0px;
    }
    p {
      margin-right: 0px;
    }
    button {
      margin-left: auto;
      padding: 0px;
      min-height: unset;
      svg {
        display: none;
      }
      background-image: url(${require('~/assets/graphics/edit-05.svg')});
      ${clerkBackgroundImageCss}
    }
  }

  .cl-signIn-forgotPasswordMethods {
    .cl-identityPreview {
        justify-content: unset;
    }
    .cl-internal-uqm3h2 {
      .cl-alternativeMethodsBlockButton {
        border-radius: 9999px;
        border: 1px solid rgba(1, 6, 47, 0.17);
        justify-content: center;
        gap: 8px;
        background: #ffffff;
        div {
          width: unset;
        }
        :hover {
          background: rgba(0, 0, 0, 0.04);
          svg {
            width: 0px;
            height: 0px;
            stroke: #1C2024;
          }
        }
      }
      .cl-alternativeMethodsBlockButtonText {
        color: #60646C;
        ${clerkBaseFontCss}
      }
    }
  }

  .cl-signIn-totp2Fa {
    ${clerkFactorStepsBaseCss}
    .cl-otpCodeField {
      width: 100%;
      align-items: center;
      margin-top: 16px;
      margin-bottom: 0px;
    }
    svg {
      background-image: url(${require('~/assets/graphics/shield-tick.svg')});
      ${clerkBackgroundImageCss}
      path {
        display: none;
      }
    }
  }

  .cl-signIn-emailLinkVerify {
    ${clerkFactorStepsBaseCss}
    .cl-formHeaderTitle {
      text-align: center;
    }
    .cl-formHeaderSubtitle {
      text-align: center;
    }
    .cl-formResendCodeLink {
      margin-top: 16px;
    }
  }

  .cl-signIn-resetPassword {
    ${clerkFactorStepsBaseCss}
    .cl-otpCodeField {
      width: 100%;
      align-items: center;
      margin-top: 16px;
      margin-bottom: 40px;
    }
    .cl-formResendCodeLink {
      width: 100%;
      text-align: center;
      color: #60646C;
      ${clerkBaseFontCss}
      margin: 0px;
    }
  }

  .cl-signIn-havingTrouble {
    .cl-main {
      button {
        ${clerkBlackButtonBaseCss}
        div {
          span {
            color: #FCFCFD;
            ${clerkBigFontCss}
          }
        }
        svg {
          width: 0px;
          heigth: 0px;
          stroke: #1C2024;
        }
      }
    }
  }

  .cl-avatarBox {
    background-image: url(${require('~/assets/graphics/user-03.svg')});
    ${clerkBackgroundImageCss}
    background-color: unset;
    img {
      display:none;
    }
  }

  .cl-socialButtonsBlockButtonText {
    color: #60646C;
    ${clerkBaseFontCss}
  }

  .cl-socialButtonsBlockButton {
    border-radius: 9999px;
    border: 1px solid rgba(1, 6, 47, 0.17);
    justify-content: center;
    gap: 8px;
    div {
      width: unset;
    }
  }

  .cl-main {
    gap: 40px;
    p {
      color: #60646C;
      text-align: center;
      ${clerkSmallFontCss}
    }
  }

  .cl-formButtonPrimary {
    ${clerkBlackButtonBaseCss}
    margin-top: 24px;
    color: #FCFCFD;
    ${clerkBigFontCss}
    text-transform: none;
  }

  .cl-form {
    gap: 0px;
  }

  .cl-formField {
    gap: 4px;
    display: flex;
  }

  .cl-formFieldLabel {
    color: #1C2024;
    ${clerkBaseFontCss}
  }

  .cl-formFieldInput {
    color: #1C2024;
    ${clerkThinFontCss}
  }

  .cl-formFieldAction {
    color: ${theme.copper};
    ${clerkSmallThickFontCss}
  }

  .cl-formFieldErrorText {
    svg {
      display:none;
    }
  }

  #error-password {
    ${clerkErrorCss}
  }

  #error-confirmPassword {
    ${clerkErrorCss}
  }

  #error-identifier {
    ${clerkErrorCss}
  }

  .cl-alert {
    display: flex;
    width: 100%;
    padding: 12px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #F9C6C6;
    background: #FFF7F7;
    align-items: center;

    .cl-alertText {
      color: rgba(187, 0, 7, 0.84);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.4px;
    }
    svg {
      margin-right: 0px;
      background-image: url(${require('~/assets/graphics/info-circle-red.svg')});
      ${clerkBackgroundImageCss}
      path {
        display: none;
      }
    }
  }

  .cl-footer {
    justify-content: center;
  }

  .cl-footerAction__signIn {
    margin-top: 24px;
    .cl-footerActionLink {
      color: ${theme.copper};
      ${clerkBaseFontCss}
    }
  }

  .cl-footerActionText {
    color: #1C2024;
    ${clerkThinFontCss}
  }

  .cl-footerActionLink {
    color: #60646C;
    ${clerkBaseFontCss}
  }

  .cl-main ~ div[class^='cl-internal'] {
    display: none;
  }

  .cl-modalBackdrop {
    .cl-modalContent {
      .cl-card {
        padding-top: 68px;
        padding-bottom: 24px;
        div:has(> .cl-formButtonPrimary), div:has(> .cl-formButtonReset) {
          background-color: #FFFFFF;
          position: absolute;
          right: 24px;
          bottom: 0px;
          width: 100%;
          margin-top: 0px;
        }
        .cl-formButtonPrimary {
          border-radius: 999px;
          background: #1C2024;
          display: flex;
          height: 32px;
          padding: 0px 12px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          color: #FCFCFD !important;
          font-style: normal;
          ${clerkBaseFontCss}
        }
        .cl-formButtonPrimary:disabled {
          color: #00082F46 !important;
          background: #00003B0D;
        }
        .cl-formButtonReset {
          border-radius: 9999px;
          background: rgba(0, 0, 59, 0.05);
          display: flex;
          height: 32px;
          padding: 0px 12px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          color: rgba(0, 7, 19, 0.62);
          font-style: normal;
          ${clerkBaseFontCss}
        }
      }
      .cl-navbar {
        padding: 0px 24px;
        max-width: 229px;
        .cl-navbarButtons {
          gap: 8px;
          width: 180px;
          .cl-navbarButton__account {
            svg {
              height: 16px;
              width: 16px;
              background-image: url(${require('~/assets/graphics/user-03.svg')});
              path {
                display: none;
              }
            }
          }
          .cl-navbarButton__security {
            svg {
              height: 16px;
              width: 16px;
              background-image: url(${require('~/assets/graphics/shield-tick.svg')});
              path {
                display: none;
              }
            }
          }
          .cl-navbarButton {
            gap: 8px;
            padding: 0px 12px;
            height: 32px;
            ${clerkFontFamilyCss}
            color: #60646C;
            ${clerkBaseFontCss}
            opacity: 1;
          }
        }
      }
      .cl-pageScrollBox {
        padding: 0px 24px;
        margin-bottom: 50px;
        .cl-internal-1l2uvec {
          gap: 24px;
          div:nth-child(3) {
            div:nth-child(2) {
              p:nth-child(2) {
                color: #60646C;
                font-style: normal;
                ${clerkThinFontCss}
              }
            }
            div:nth-child(4) {
              gap: 16px;
              button {
                color: #60646C;
                font-style: normal;
                ${clerkSmallThickFontCss}
              }
            }
          }
          .cl-internal-1b63r8w {
            color: #1C2024;
            font-style: normal;
            ${clerkThinFontCss}
          }
          .cl-internal-3vf5mz {
            color: #1C2024;
            font-style: normal;
            ${clerkBaseFontCss}
          }
          .cl-internal-13x0359 {
            gap: 8px;
              div {
              border-radius: 3px;
              background: rgba(0, 0, 59, 0.05);
              display: flex;
              padding: 2px 8px;
              justify-content: center;
              align-items: center;
              gap: 4px;
              flex: 1 0 0;
              p {
                color: rgba(0, 7, 19, 0.62);
                font-style: normal;
                ${clerkSmallThickFontCss}
              }
            }
          }
          .cl-formFieldRow {
            margin-bottom: 24px;
          }
        }
      }
      .cl-modalCloseButton {
        padding-right: 24px;
        padding-top: 24px;
        padding-left: 0px;
        padding-bottom: 0px;
        svg {
          height: 10px;
          width: 10px;
        }
      }
    }
  }

  div:has(> .cl-otpCodeField) {
    gap: 0px;
    .cl-formHeaderTitle {
      color: #1C2024;
      font-style: normal;
      ${clerkBaseFontCss}
    }
    .cl-formHeaderSubtitle {
      color: #60646C;
      font-style: normal;
      margin-bottom: 16px;
      ${clerkThinFontCss}
    }
    .cl-otpCodeField {
      height: 48px;
      .cl-otpCodeFieldInputs {
        height: 48px;
        width: 328px;
        gap: 8px;
        input {
          height: 48px;
          width: 48px;
          max-width: unset;
        }
      }
    }
  }

  .cl-page:has(.cl-qrCodeRow), .cl-page:has(div):has(.cl-formFieldInputCopyToClipboardButton) {
    div:nth-child(3) {
      div:nth-child(1) {
        gap: 0px;
        p {
          color: #1C2024;
          font-style: normal;
          ${clerkThinFontCss}
        }
        button {
          margin-top: 16px;
          padding-left: 8px;
          color: #60646C;
          font-style: normal;
          ${clerkSmallThickFontCss}
        }
        p:nth-child(1) {
          margin-bottom: 8px;
        }
      }
    }
  }

  .cl-page:has(.cl-qrCodeRow) {
    .cl-qrCodeContainer {
      padding: 8px;
    }
  }
  .cl-page:has(div):has(.cl-formFieldInputCopyToClipboardButton) {
    div:nth-child(3) {
      div:nth-child(1) {
        p:nth-child(2) {
          color: #60646C !important;
          font-size: 12px !important;
          line-height: 16px !important;
          letter-spacing: 0.48px !important;
          margin-bottom: 4px;
        }
        div:nth-child(3) {
          margin-bottom: 16px;
        }
        p:nth-child(4) {
          color: #60646C !important;
          font-size: 12px !important;
          line-height: 16px !important;
          letter-spacing: 0.48px !important;
          margin-bottom: 4px;
        }
      }
    }
    div:has(.cl-formFieldInputCopyToClipboardButton) {
      input {
        color: #1C2024;
        font-style: normal;
        ${clerkThinFontCss}
        opacity: 1;
      }
    }
    .cl-formFieldInputCopyToClipboardButton {
      margin-top: 0px !important;
      svg {
        height: 16px;
        width: 16px;
        background-image: url(${require('~/assets/graphics/clipboard.svg')});
        path {
          display: none;
        }
      }
    }
  }

  .cl-page {
    gap: 24px;
    .cl-breadcrumbs {
      margin-bottom: 32px;
      .cl-breadcrumbsItems {
        a {
          color: #60646C;
          font-style: normal;
          ${clerkSmallThickFontCss}
          svg {
            display: none;
          }
        }
        p {
          color: #1C2024;
          font-style: normal;
          ${clerkSmallThickFontCss}
          align-items: center;
        }
      }
    }
    .cl-form {
      .cl-formField__signOutOfOtherSessions {
        div:nth-child(1) {
          gap: 8px;
        }
      }
      .cl-avatarBox {
        display: none;
      }
      .cl-avatarBox + div {
        display: none;
      }
      .cl-formFieldInputShowPasswordButton {
        color: #00082F46 !important;
      }
    }
    .cl-profilePage__account {
      margin-bottom: 16px;
    }
    .cl-header {
      margin: 0px;
      h1 {
        color: #1C2024;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: -0.1px;
      }
      P {
        ${clerkThinFontCss}
        color: #60646C;
        font-style: normal;
      }
    }
    .cl-profileSection {
      gap: 8px;
      .cl-profileSectionTitle {
        p {
          margin-bottom: 8px;
          color: #1C2024;
          ${clerkBigFontCss}
          font-style: normal;
        }
      }
      .cl-profileSectionContent__activeDevices {
        .cl-activeDevice__current > div:nth-child(2) {
          gap: 0px;
          div {
            p {
              color: #1C2024;
              ${clerkBaseFontCss}
              font-style: normal;
            }
          }
          p {
            color: #60646C;
            font-style: normal;
            ${clerkThinFontCss}
          }
        }
        .cl-activeDevice > div:nth-child(2) {
          gap: 0px;
          div {
            p {
              color: #1C2024;
              ${clerkBaseFontCss}
              font-style: normal;
            }
          }
          p {
            color: #60646C;
            font-style: normal;
            ${clerkThinFontCss}
          }
        }
      }
      .cl-profileSectionContent {
        button {
          padding: 12px;
          .cl-userPreview {
            gap: 8px;
          }
        }
        .cl-accordionTriggerButton {
          gap: 8px;
        }
      }
      .cl-profileSectionContent__profile {
        .cl-userPreviewMainIdentifier {
          color: #60646C;
          ${clerkBaseFontCss}
          font-style: normal;
        }
      }
      .cl-profileSectionContent__password {
        p {
          padding: 4px 0px;
        }
        button {
          div {
            span {
              color: #60646C;
              ${clerkBaseFontCss}
              font-style: normal;
            }
          }
        }
        span {
          svg {
            height: 14px;
            width: 14px;
            background-image: url(${require('~/assets/graphics/edit-02.svg')});
            path {
              display: none;
            }
          }
        }
      }

      .cl-profileSectionContent__mfa > div:nth-child(1) {
        .cl-accordionTriggerButton {
          span {
            svg {
              height: 16px;
              width: 16px;
              background-image: url(${require('~/assets/graphics/fingerprint-01.svg')});
              path {
                display: none;
              }
            }
          }
        }
      }

      .cl-profileSectionContent__mfa > div:nth-child(2) {
        .cl-accordionTriggerButton {
          span {
            svg {
              height: 16px;
              width: 16px;
              background-image: url(${require('~/assets/graphics/passcode.svg')});
              path {
                display: none;
              }
            }
          }
        }
      }

      .cl-profileSectionContent__mfa {
        .cl-profileSectionPrimaryButton__mfa {
          div {
            span {
              color: #60646C;
              ${clerkBaseFontCss}
              font-style: normal;
            }
          }
        }
        .cl-accordionTriggerButton {
          div {
            span:nth-child(1) {
              color: #1C2024;
              ${clerkBaseFontCss}
              font-style: normal;
            }
          }
        }
        .cl-accordionContent {
          padding: 8px 12px;
        }
        .cl-accordionContent > div:nth-child(1) {
          gap: 8px;
        }
        .cl-accordionContent > div:nth-child(1) > div:nth-child(1) {
          gap: 8px;
          div {
            div {
              p {
                color: #1C2024;
                font-style: normal;
                ${clerkSmallThickFontCss}
              }
            }
            p {
              color: #60646C;
              ${clerkSmallFontCss}
              font-style: normal;
            }
          }
          button {
            color: #60646C;
            font-style: normal;
            ${clerkSmallThickFontCss}
            padding: 0px;
          }
        }
        .cl-accordionContent > div:nth-child(1) > div:nth-child(2) {
          div {
            div {
              p {
                color: #1C2024;
                font-style: normal;
                ${clerkSmallThickFontCss}
              }
            }
            p {
              color: #60646C;
              ${clerkSmallFontCss}
              font-style: normal;
            }
          }
          button {
            color: #C62A2F;
            font-style: normal;
            ${clerkSmallThickFontCss}
            padding: 0px;
          }
        }
        .cl-profileSectionPrimaryButton {
          span {
            svg {
              height: 16px;
              width: 16px;
              background-image: url(${require('~/assets/graphics/plus.svg')});
              path {
                display: none;
              }
            }
          }
        }
      }
      .cl-profileSectionContent__activeDevices {
        .cl-accordionContent {
          padding: 8px 12px;
        }
        .cl-accordionContent > div:nth-child(1) > div:nth-child(1) {
          gap: 4px;
          div {
            gap: 0px;
            div {
              p {
                color: #1C2024;
                font-style: normal;
                ${clerkSmallThickFontCss}
              }
            }
            p {
              color: #60646C;
              font-style: normal;
              ${clerkSmallFontCss}
            }
          }
          button {
            color: #C62A2F;
            font-style: normal;
            ${clerkSmallThickFontCss}
            padding: 0px;
          }
        }
      }
    }
    .cl-badge {
      border-radius: 3px;
      background: rgba(0, 0, 59, 0.05);
      display: flex;
      padding: 2px 8px;
      justify-content: center;
      align-items: center;
      color: rgba(0, 7, 19, 0.62);
      font-style: normal;
      ${clerkSmallThickFontCss}
    }
    .cl-profileSectionPrimaryButton:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  .cl-profileSection__emailAddresses, .cl-profileSection__connectedAccounts {
    display: none;
  }

  .cl-profileSectionPrimaryButton {
    color: #000000
  }

  .cl-userProfile-root {
    .cl-headerTitle, .cl-profileSectionContent, .cl-internal-egu47 {
      justify-content: start;
    }

    .cl-headerTitle, .cl-headerSubtitle {
      text-align: start;
    }

    .cl-navbar ~ div[class^='cl-internal'] {
      display: none;
    }

    .cl-formButtonReset, .cl-formButtonPrimary {
      ${clerkBlackButtonBaseCss}
      margin-top: 12px;
      color: #FCFCFD;
      ${clerkSmallFontCss}
      text-transform: none;
    }
  }
`;

export default GlobalStyles;
