import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DropZone = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  border: 3px dashed ${props => props.theme.bgColor.secondaryMuted};
  border-radius: 8px;

  min-height: 200px;
`;

function DragAndDropUploadFile({ onFile, allowedMimeTypes, allowedExtensions }) {
  const [isOverZone, setIsOverZone] = useState(false);

  return (
    <DropZone
      onDrop={e => {
        e.preventDefault();
        const isFile = e.dataTransfer.items[0].kind !== 'file';
        const isCorrectFileType = allowedMimeTypes.includes(e.dataTransfer.items[0].type);
        const isInputInvalid = !isFile && !isCorrectFileType;
        if (isInputInvalid) {
          setIsOverZone(false);
          return;
        }

        onFile(e.dataTransfer.items[0].getAsFile());
      }}
      onDragOver={e => {
        e.preventDefault();
        setIsOverZone(true);
      }}
      onDragLeave={e => {
        e.preventDefault();
        setIsOverZone(false);
      }}
    >
      <p>
        {isOverZone
          ? `Drop ${allowedExtensions.join(', ')} file here to upload`
          : `Drag and drop ${allowedExtensions.join(', ')} file here to upload or click the button below`}
      </p>

      {!isOverZone && (
        <input
          type='file'
          name='data'
          accept={allowedMimeTypes.join(', ')}
          onChange={e => onFile(e.target.files[0])}
        />
      )}
    </DropZone>
  );
}

DragAndDropUploadFile.propTypes = {
  onFile: PropTypes.func,
  allowedMimeTypes: PropTypes.arrayOf(PropTypes.string),
  allowedExtensions: PropTypes.arrayOf(PropTypes.string),
};

export default DragAndDropUploadFile;
