import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuthentication from '~/hooks/use-authentication';

function PrivateRoute({ children, requiredRoles = [], location, ...rest }) {
  const { userType, accessLevel, isLoggedIn } = useAuthentication();
  const history = useHistory();

  const areRolesRequired = requiredRoles.length !== 0;
  const hasAccess = !areRolesRequired || requiredRoles.includes(userType) || requiredRoles.includes(accessLevel);
  const shouldRedirect = !isLoggedIn || !hasAccess;

  useEffect(() => {
    if (!shouldRedirect) return;

    history.replace('/login', !areRolesRequired ? { from: location } : undefined);
  }, [shouldRedirect, areRolesRequired, history, location]);

  return (
    <Route
      {...rest}
      location={location}
    >
      {isLoggedIn && hasAccess ? children : null}
    </Route>
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  requiredRoles: PropTypes.arrayOf(PropTypes.string),
};

export default PrivateRoute;
