import { useState } from 'react';
import api from '~/lib/api';
import useNotification from '~/hooks/use-notification';

function useCountriesService() {
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const { addNotification } = useNotification();

  async function uploadFile() {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('data', file);
      await api.post(`/countries/countries-list`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      addNotification({
        type: 'success',
        message: 'Countries list uploaded successfully',
      });
    } catch (e) {
      addNotification({
        type: 'error',
        message: `Countries list upload failed`,
      });
    }
    setLoading(false);
  }

  return {
    file,
    loading,
    setFile,
    uploadFile,
  };
}

export default useCountriesService;
