import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PhoneInputWrapper = styled.div`
  .react-tel-input {
    width: 100%;
    height: 40px;
    .form-control {
      width: 100%;
      height: 40px;
      border: 1px solid ${props => props.theme.lightMedium};
    }
    .flag-dropdown {
      border: 1px solid ${props => props.theme.lightMedium};
      background-color: ${props => props.theme.lightest};
      .selected-flag {
      }
    }
  }
`;

function PhoneInputEl({ defaultCountry, placeholder, value, onChange, isValid }) {
  return (
    <PhoneInputWrapper>
      <PhoneInput
        country={defaultCountry}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        isValid={isValid}
        enableLongNumbers
      />
    </PhoneInputWrapper>
  );
}

PhoneInputEl.propTypes = {
  defaultCountry: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  isValid: PropTypes.func,
  error: PropTypes.string,
};

export default PhoneInputEl;
