/* eslint-disable react-hooks/rules-of-hooks */
// https://medium.com/@pitipatdop/little-neat-trick-to-capture-click-outside-with-react-hook-ba77c37c7e82
import { useEffect } from 'react';

function onClickOutside(node, onOutsideClick) {
  function handleClick(e) {
    if (!node?.current || node.current.contains(e.target)) return;
    onOutsideClick(e);
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });
}

export default onClickOutside;
