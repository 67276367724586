import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '~/components/Icon';

const PopoutList = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.bgColor.main};
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  width: 200px;
  top: 100%;
  position: absolute;
  right: -10px;
  border-radius: 8px;
`;

const HoverButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;

  padding: 0 8px;
  border-bottom: 2px solid transparent;
  transition: border-color 0.1s ease-in-out;

  & ${PopoutList} {
    display: none;
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
  &:hover {
    border-bottom: 2px solid ${props => props.theme.bgColor.muted};
    cursor: pointer;

    & ${PopoutList} {
      display: flex;
    }
  }
  &.active {
    border-bottom: 2px solid ${props => props.theme.bgColor.secondary};
  }
`;

const IconWrapper = styled.div`
  margin-left: 7px;
  height: 27px;
  width: 27px;
`;

function MenuHoverDropdown({ label, children }) {
  return (
    <HoverButton>
      {label}
      <IconWrapper>
        <Icon
          name='carret-down'
          width={27}
          height={27}
        />
      </IconWrapper>

      <PopoutList>{children}</PopoutList>
    </HoverButton>
  );
}

MenuHoverDropdown.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
};

export default MenuHoverDropdown;
