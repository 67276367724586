import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '~/components/Icon';
import theme from '~/lib/theme';

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
  width: 20px;
  height: 20px;
  margin-left: -20px;
  pointer-events: none;
`;

const StyledWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

const StyledCheckbox = styled.input`
  position: relative;
  height: 20px;
  width: 20px;

  margin: 0;
  padding: 0px 5px;

  background-color: ${props => props.theme.lighter};
  border: 1px solid ${props => props.theme.light};

  appearance: none;
  outline: none;
  font-size: 1.3rem;

  cursor: pointer;
  pointer-events: auto;

  &:disabled {
    cursor: not-allowed;
    color: #636363;
  }
`;

function Checkbox({ indeterminate, onChange, checked, disabled, ...rest }) {
  const ref = useRef();
  const indeterminateRef = useRef();
  const [isHovered, setIsHovered] = useState(false);

  const RenderX = ({ isHovered }) =>
    useMemo(() => {
      if (disabled) return null;
      if (checked) {
        return (
          <IconWrapper>
            <Icon
              name='close'
              width={16}
              height={16}
              color={theme.copperDark}
            />
          </IconWrapper>
        );
      }
      if (indeterminate) {
        return (
          <IconWrapper>
            <Icon
              name='minus'
              width={16}
              height={16}
              color={theme.copperDark}
            />
          </IconWrapper>
        );
      }
      if (isHovered) {
        return (
          <IconWrapper>
            <Icon
              name='close'
              width={16}
              height={16}
              color={theme.copperLightest}
            />
          </IconWrapper>
        );
      }
      return <IconWrapper></IconWrapper>;
    }, [isHovered]);

  useEffect(() => {
    ref.current.indeterminate = checked ? false : indeterminate;
  }, [ref, indeterminate, checked]);

  useEffect(() => {
    indeterminateRef.current = indeterminate;
  }, [indeterminate]);

  return (
    <StyledWrapper
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <StyledCheckbox
        {...rest}
        checked={checked}
        disabled={disabled}
        ref={ref}
        onChange={e => {
          e.target.canBeIndeterminate = indeterminateRef.current;
          onChange(e);
        }}
        type='checkbox'
      />
      <RenderX isHovered={isHovered} />
    </StyledWrapper>
  );
}

Checkbox.propTypes = {
  indeterminate: PropTypes.bool,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Checkbox;
