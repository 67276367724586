import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const StyledSiteWrapper = styled.div`
  position: relative;
  ${props =>
    props.isFullHeight &&
    css`
      height: 100%;
    `}
  width: 1728px;
  margin: 0px auto;
  padding: 20px 0 40px;
  @media (max-width: calc(1728px + 6vw)) {
    padding: 20px 3vw 40px 3vw;
    width: 100%;
  }

  ${props =>
    props.backgroundImageUrl &&
    css`
      background-image: url(${props.backgroundImageUrl});
      background-repeat: no-repeat;
      background-position: bottom left;
    `}
`;

function SiteWrapper({ backgroundImageUrl, children, isFullHeight }) {
  return (
    <StyledSiteWrapper
      backgroundImageUrl={backgroundImageUrl}
      isFullHeight={isFullHeight}
    >
      {children}
    </StyledSiteWrapper>
  );
}

SiteWrapper.propTypes = {
  backgroundImageUrl: PropTypes.string,
  children: PropTypes.node,
  isFullHeight: PropTypes.bool,
};

export default SiteWrapper;
