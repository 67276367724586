export const outerRings = [
  'M180.56,0h-6l0.75,50.48c1.5,0.49,2.99,1,4.47,1.54L180.56,0z',
  'M168.17,0.22l-5.99,0.42l4.01,47.22c1.56,0.39,3.12,0.8,4.66,1.25L168.17,0.22z',
  'M155.82,1.31l-5.94,0.84l6.81,43.71c1.62,0.28,3.23,0.59,4.83,0.93L155.82,1.31z',
  'M143.58,3.26l-5.87,1.25l9.13,40.02c1.67,0.16,3.33,0.36,4.98,0.58L143.58,3.26z',
  'M131.5,6.05l-5.77,1.65l10.97,36.21c1.71,0.04,3.41,0.11,5.1,0.21L131.5,6.05z',
  'M119.65,9.68l-5.64,2.05l12.33,32.35c1.72-0.09,3.45-0.15,5.19-0.18L119.65,9.68z',
  'M108.08,14.13l-5.48,2.44l13.21,28.51c1.74-0.23,3.49-0.43,5.25-0.6L108.08,14.13z',
  'M96.85,19.38l-5.3,2.82l13.64,24.75c1.74-0.38,3.49-0.73,5.26-1.04L96.85,19.38z',
  'M86.01,25.39l-5.09,3.18l13.65,21.14c1.73-0.53,3.47-1.02,5.23-1.48L86.01,25.39z',
  'M75.62,32.15l-4.85,3.53l13.3,17.74c1.7-0.68,3.42-1.33,5.16-1.94L75.62,32.15z',
  'M65.72,39.61l-4.6,3.86l12.63,14.6c1.66-0.83,3.33-1.64,5.03-2.41L65.72,39.61z',
  'M56.37,47.75l-4.32,4.17l11.68,11.74c1.6-0.99,3.22-1.94,4.87-2.86L56.37,47.75z',
  'M47.61,56.52l-4.01,4.46l10.52,9.19c1.52-1.13,3.07-2.23,4.65-3.3L47.61,56.52z',
  'M39.49,65.88l-3.69,4.73L45,77.59c1.43-1.27,2.89-2.52,4.38-3.73L39.49,65.88z',
  'M40.55,81.72l-8.52-5.94l-3.36,4.97l7.8,5.09C37.81,84.45,39.17,83.07,40.55,81.72z',
  'M25.29,86.19l-3,5.2l6.36,3.55c1.21-1.53,2.45-3.04,3.72-4.51L25.29,86.19z',
  'M19.28,97.03l-2.63,5.39l4.96,2.33c1.07-1.65,2.17-3.27,3.31-4.86L19.28,97.03z',
  'M14.05,108.27l-2.25,5.56l3.65,1.41c0.92-1.75,1.88-3.47,2.88-5.17L14.05,108.27z',
  'M9.61,119.84l-1.85,5.71l2.48,0.76c0.76-1.83,1.56-3.65,2.4-5.44L9.61,119.84z',
  'M6,131.7l-1.45,5.82l1.5,0.35c0.59-1.9,1.23-3.79,1.9-5.65L6,131.7z',
  'M3.22,143.78l-1.04,5.91l0.77,0.12c0.41-1.96,0.86-3.9,1.36-5.82L3.22,143.78z',
  'M1.28,156.02l-0.63,5.97l0.3,0.03c0.23-1.99,0.5-3.97,0.82-5.93L1.28,156.02z',
  'M0.21,168.37l-0.21,6l0.09,0c0.05-2.01,0.14-4,0.28-5.99L0.21,168.37z',
  'M0,180.77l0.21,6l0.16-0.01c-0.13-1.99-0.23-3.98-0.28-5.99L0,180.77z',
  'M0.66,193.15l0.63,5.97l0.49-0.06c-0.32-1.96-0.59-3.94-0.82-5.93L0.66,193.15z',
  'M2.17,205.45l1.04,5.91l1.09-0.21c-0.5-1.92-0.95-3.87-1.36-5.82L2.17,205.45z',
  'M4.55,217.61L6,223.44l1.95-0.52c-0.67-1.87-1.31-3.75-1.9-5.65L4.55,217.61z',
  'M7.76,229.59l1.85,5.71l3.03-1.03c-0.84-1.79-1.64-3.6-2.4-5.44L7.76,229.59z',
  'M11.8,241.3l2.25,5.56l4.28-1.8c-1-1.7-1.96-3.42-2.88-5.17L11.8,241.3z',
  'M16.65,252.71l2.63,5.39l5.64-2.86c-1.14-1.59-2.24-3.22-3.31-4.86L16.65,252.71z',
  'M22.29,263.75l3,5.2l7.08-4.23c-1.27-1.48-2.51-2.98-3.72-4.51L22.29,263.75z',
  'M36.48,269.28l-7.8,5.09l3.36,4.97l8.52-5.94C39.17,272.07,37.81,270.69,36.48,269.28z',
  'M35.79,284.53l3.69,4.73l9.9-7.97c-1.49-1.21-2.95-2.46-4.38-3.73L35.79,284.53z',
  'M43.6,294.15l4.01,4.46l11.15-10.35c-1.57-1.07-3.12-2.17-4.65-3.3L43.6,294.15z',
  'M52.06,303.22l4.32,4.17l12.23-13.05c-1.64-0.92-3.27-1.87-4.87-2.86L52.06,303.22z',
  'M61.13,311.66l4.6,3.86l13.06-16.05c-1.7-0.77-3.37-1.57-5.03-2.41L61.13,311.66z',
  'M70.77,319.46l4.85,3.53l13.61-19.33c-1.74-0.61-3.46-1.26-5.16-1.94L70.77,319.46z',
  'M80.92,326.56l5.09,3.18l13.8-22.84c-1.76-0.46-3.5-0.96-5.23-1.49L80.92,326.56z',
  'M91.55,332.94l5.3,2.82l13.6-26.53c-1.77-0.31-3.52-0.66-5.26-1.04L91.55,332.94z',
  'M102.6,338.56l5.48,2.44l12.97-30.35c-1.76-0.17-3.51-0.36-5.25-0.6L102.6,338.56z',
  'M114.01,343.4l5.64,2.05l11.88-34.23c-1.74-0.03-3.47-0.08-5.19-0.18L114.01,343.4z',
  'M125.74,347.43l5.77,1.65l10.31-38.08c-1.69,0.1-3.39,0.17-5.1,0.21L125.74,347.43z',
  'M137.71,350.63l5.87,1.25l8.24-41.85c-1.65,0.22-3.31,0.42-4.98,0.58L137.71,350.63z',
  'M149.88,352.99l5.94,0.83l5.7-45.47c-1.6,0.34-3.21,0.65-4.83,0.93L149.88,352.99z',
  'M162.19,354.49l5.99,0.42l2.68-48.88c-1.54,0.44-3.1,0.86-4.66,1.25L162.19,354.49z',
  'M174.56,355.14h6l-0.78-52.02c-1.48,0.54-2.97,1.05-4.47,1.54L174.56,355.14z',
  'M186.96,354.91l5.99-0.42l-4.66-54.84c-1.41,0.63-2.83,1.23-4.26,1.81L186.96,354.91z',
  'M199.31,353.83l5.94-0.83l-8.92-57.27c-1.33,0.71-2.68,1.4-4.04,2.06L199.31,353.83z',
  'M211.55,351.88l5.87-1.25l-13.52-59.25c-1.26,0.78-2.53,1.54-3.82,2.28L211.55,351.88z',
  'M223.62,349.08l5.77-1.65l-18.4-60.74c-1.18,0.84-2.38,1.65-3.59,2.45L223.62,349.08z',
  'M235.48,345.45l5.64-2.05l-23.52-61.73c-1.1,0.89-2.22,1.77-3.35,2.62L235.48,345.45z',
  'M247.05,341l5.48-2.44l-28.79-62.14c-1.03,0.93-2.07,1.85-3.12,2.75L247.05,341z',
  'M226.52,273.81l31.76,61.95l5.3-2.82l-34.17-62.01C228.46,271.91,227.5,272.87,226.52,273.81z',
  'M269.12,329.74l5.09-3.18l-39.58-61.28c-0.87,1-1.77,1.99-2.67,2.97L269.12,329.74z',
  'M279.51,322.99l4.85-3.53l-44.97-59.99c-0.8,1.03-1.61,2.05-2.44,3.06L279.51,322.99z',
  'M289.4,315.52l4.6-3.86l-50.26-58.12c-0.73,1.05-1.47,2.1-2.23,3.13L289.4,315.52z',
  'M298.76,307.38l4.32-4.17l-55.4-55.68c-0.66,1.07-1.34,2.13-2.02,3.18L298.76,307.38z',
  'M307.52,298.61l4.01-4.46l-60.33-52.71c-0.59,1.09-1.2,2.16-1.82,3.23L307.52,298.61z',
  'M315.64,289.25l3.69-4.73l-64.99-49.23c-0.53,1.1-1.07,2.19-1.62,3.27L315.64,289.25z',
  'M323.1,279.35l3.36-4.97l-69.33-45.27c-0.46,1.11-0.94,2.21-1.43,3.3L323.1,279.35z',
  'M329.84,268.95l3-5.2l-73.3-40.87c-0.4,1.11-0.82,2.22-1.25,3.32L329.84,268.95z',
  'M335.85,258.1l2.63-5.39l-76.86-36.07c-0.34,1.12-0.7,2.23-1.07,3.34L335.85,258.1z',
  'M341.08,246.87l2.25-5.56l-79.96-30.93c-0.28,1.12-0.58,2.24-0.89,3.35L341.08,246.87z',
  'M345.51,235.29l1.85-5.71l-82.58-25.48c-0.23,1.13-0.47,2.25-0.72,3.36L345.51,235.29z',
  'M349.13,223.44l1.45-5.82l-84.69-19.78c-0.17,1.13-0.36,2.25-0.56,3.37L349.13,223.44z',
  'M351.91,211.36l1.04-5.91l-86.26-13.88c-0.12,1.13-0.25,2.25-0.39,3.38L351.91,211.36z',
  'M353.85,199.11l0.63-5.97l-87.28-7.86c-0.06,1.13-0.14,2.26-0.23,3.38L353.85,199.11z',
  'M267.33,182.39l87.59,4.37l0.21-6l-87.72-1.75C267.39,180.14,267.37,181.27,267.33,182.39z',
  'M355.13,174.37l-0.21-6l-87.59,4.37c0.04,1.12,0.07,2.25,0.08,3.38L355.13,174.37z',
  'M354.47,161.99l-0.63-5.97l-86.88,10.45c0.09,1.12,0.17,2.25,0.23,3.38L354.47,161.99z',
  'M352.96,149.69l-1.04-5.91L266.3,160.2c0.15,1.12,0.28,2.25,0.39,3.38L352.96,149.69z',
  'M350.58,137.52l-1.45-5.82l-83.79,22.23c0.2,1.12,0.38,2.24,0.56,3.37L350.58,137.52z',
  'M347.37,125.55l-1.85-5.71l-81.45,27.82c0.26,1.12,0.5,2.24,0.72,3.36L347.37,125.55z',
  'M343.33,113.83l-2.25-5.56l-78.61,33.13c0.31,1.11,0.61,2.23,0.89,3.35L343.33,113.83z',
  'M338.48,102.42l-2.63-5.39l-75.29,38.13c0.37,1.11,0.72,2.22,1.07,3.34L338.48,102.42z',
  'M332.84,91.38l-3-5.2l-71.55,42.74c0.43,1.1,0.84,2.21,1.25,3.32L332.84,91.38z',
  'M326.45,80.76l-3.36-4.97l-67.4,46.94c0.49,1.09,0.97,2.19,1.43,3.3L326.45,80.76z',
  'M319.34,70.61l-3.69-4.73l-62.92,50.69c0.56,1.08,1.1,2.17,1.62,3.27L319.34,70.61z',
  'M311.53,60.98l-4.01-4.46l-58.14,53.94c0.62,1.07,1.23,2.14,1.82,3.23L311.53,60.98z',
  'M303.07,51.92l-4.32-4.17l-53.11,56.67c0.69,1.05,1.36,2.11,2.02,3.18L303.07,51.92z',
  'M294,43.47l-4.6-3.86l-47.9,58.85c0.76,1.03,1.5,2.07,2.23,3.13L294,43.47z',
  'M284.36,35.68l-4.85-3.53L236.95,92.6c0.83,1.01,1.64,2.03,2.44,3.06L284.36,35.68z',
  'M274.21,28.57l-5.09-3.18l-37.16,61.49c0.9,0.98,1.8,1.96,2.67,2.97L274.21,28.57z',
  'M229.41,84.2l34.17-62.01l-5.3-2.82l-31.76,61.95C227.5,82.27,228.46,83.23,229.41,84.2z',
  'M252.53,16.57l-5.48-2.44l-26.43,61.83c1.05,0.9,2.1,1.82,3.12,2.75L252.53,16.57z',
  'M241.12,11.73l-5.64-2.05l-21.23,61.16c1.13,0.86,2.25,1.73,3.35,2.62L241.12,11.73z',
  'M229.39,7.71l-5.77-1.65L207.4,66c1.21,0.8,2.41,1.61,3.59,2.45L229.39,7.71z',
  'M217.42,4.5l-5.87-1.25l-11.47,58.22c1.29,0.74,2.56,1.5,3.82,2.28L217.42,4.5z',
  'M205.25,2.15l-5.94-0.84l-7.02,56.04c1.36,0.66,2.71,1.35,4.04,2.06L205.25,2.15z',
  'M192.94,0.64l-5.99-0.42l-2.94,53.45c1.43,0.58,2.85,1.18,4.26,1.81L192.94,0.64z',
];

export const innerRings = [
  'M178.59,87.72l0.31-36.04c-0.87-0.31-1.73-0.62-2.61-0.91l0.32,36.95c0.33,0,0.66-0.01,0.99-0.01S178.26,87.72,178.59,87.72z',
  'M172.32,87.87l-2.39-39.06c-0.9-0.25-1.81-0.5-2.72-0.74L170.35,88C171,87.95,171.66,87.9,172.32,87.87z',
  'M166.07,88.44l-5.52-41.9c-0.94-0.19-1.88-0.37-2.82-0.54l6.38,42.71C164.77,88.62,165.42,88.53,166.07,88.44z',
  'M159.88,89.46l-9.06-44.52c-0.96-0.12-1.93-0.23-2.9-0.33l10.04,45.27C158.59,89.73,159.23,89.59,159.88,89.46z',
  'M153.78,90.91l-13-46.87c-0.99-0.05-1.98-0.09-2.97-0.12l14.08,47.53C152.51,91.26,153.14,91.08,153.78,90.91z',
  'M147.79,92.78l-17.31-48.88c-1.01,0.02-2.02,0.06-3.03,0.11l18.49,49.45C146.55,93.22,147.17,93,147.79,92.78z',
  'M141.95,95.06l-21.96-50.51c-1.02,0.1-2.04,0.22-3.06,0.35l23.22,50.96C140.74,95.59,141.34,95.33,141.95,95.06z',
  'M136.28,97.76l-26.9-51.68c-1.03,0.19-2.05,0.39-3.07,0.6l28.23,52C135.11,98.37,135.7,98.06,136.28,97.76z',
  'M130.81,100.84L98.74,48.49c-1.02,0.28-2.04,0.57-3.05,0.87l33.46,52.52C129.7,101.53,130.25,101.18,130.81,100.84z',
  'M125.58,104.29L88.16,51.83c-1.01,0.37-2.01,0.74-3.01,1.13l38.84,52.49C124.51,105.06,125.04,104.67,125.58,104.29z',
  'M120.6,108.1L77.75,56.12c-0.99,0.46-1.97,0.92-2.94,1.4l44.28,51.85C119.59,108.94,120.09,108.52,120.6,108.1z',
  'M115.89,112.25l-48.31-50.9c-0.95,0.54-1.9,1.1-2.84,1.67l49.73,50.61C114.94,113.16,115.42,112.7,115.89,112.25z',
  'M111.49,116.71l-53.69-49.2c-0.91,0.63-1.82,1.27-2.71,1.93l55.09,48.74C110.6,117.69,111.04,117.2,111.49,116.71z',
  'M107.4,121.47L48.46,74.59c-0.86,0.71-1.71,1.44-2.56,2.18l60.29,46.26C106.59,122.5,106.99,121.99,107.4,121.47z',
  'M103.66,126.5L39.69,82.54c-0.8,0.79-1.6,1.6-2.38,2.41l65.24,43.19C102.91,127.59,103.28,127.04,103.66,126.5z',
  'M100.27,131.78L31.57,91.32c-0.73,0.87-1.46,1.75-2.17,2.63l69.88,39.54C99.6,132.92,99.93,132.35,100.27,131.78z',
  'M97.26,137.29l-73.05-36.42c-0.66,0.94-1.3,1.88-1.94,2.84l74.12,35.35C96.67,138.46,96.96,137.87,97.26,137.29z',
  'M94.64,142.99l-76.95-31.87c-0.57,1-1.13,2-1.68,3.02l77.89,30.68C94.14,144.2,94.39,143.59,94.64,142.99z',
  'M92.43,148.86L12.1,121.98c-0.48,1.05-0.95,2.11-1.4,3.17l81.12,25.58C92.01,150.1,92.22,149.48,92.43,148.86z',
  'M90.63,154.87L7.52,133.37c-0.38,1.09-0.75,2.19-1.11,3.3l83.75,20.11C90.31,156.14,90.47,155.5,90.63,154.87z',
  'M89.27,160.99L3.99,145.19c-0.28,1.13-0.55,2.26-0.8,3.4l85.74,14.35C89.04,162.28,89.15,161.64,89.27,160.99z',
  'M88.34,167.2l-86.78-9.89c-0.17,1.15-0.33,2.3-0.48,3.46l87.06,8.38C88.2,168.5,88.26,167.85,88.34,167.2z',
  'M87.84,173.45l-87.56-3.82c-0.07,1.16-0.12,2.33-0.16,3.5l87.65,2.3C87.78,174.76,87.81,174.1,87.84,173.45z',
  'M87.77,179.72l-87.65,2.3c0.04,1.17,0.09,2.33,0.16,3.5l87.56-3.82C87.81,181.03,87.78,180.38,87.77,179.72z',
  'M88.13,185.98l-87.06,8.38c0.15,1.16,0.3,2.31,0.48,3.46l86.78-9.89C88.26,187.29,88.2,186.64,88.13,185.98z',
  'M88.93,192.2L3.19,206.55c0.25,1.14,0.52,2.27,0.8,3.4l85.28-15.81C89.15,193.5,89.04,192.85,88.93,192.2z',
  'M90.16,198.35L6.41,218.46c0.36,1.11,0.72,2.21,1.11,3.3l83.12-21.49C90.47,199.63,90.31,198.99,90.16,198.35z',
  'M91.82,204.4L10.7,229.98c0.45,1.06,0.92,2.12,1.4,3.17l80.32-26.87C92.22,205.66,92.01,205.03,91.82,204.4z',
  'M93.9,210.32L16.01,241c0.55,1.01,1.11,2.02,1.68,3.02l76.95-31.87C94.39,211.54,94.14,210.94,93.9,210.32z',
  'M96.39,216.08l-74.12,35.35c0.63,0.95,1.28,1.9,1.94,2.84l73.05-36.42C96.96,217.26,96.67,216.67,96.39,216.08z',
  'M99.28,221.65L29.4,261.18c0.71,0.89,1.44,1.77,2.17,2.63l68.69-40.46C99.93,222.79,99.6,222.22,99.28,221.65z',
  'M102.55,227l-65.25,43.19c0.78,0.81,1.58,1.62,2.38,2.41l63.97-43.96C103.28,228.09,102.91,227.55,102.55,227z',
  'M106.19,232.11L45.9,278.37c0.84,0.74,1.7,1.46,2.56,2.18l58.95-46.89C106.99,233.15,106.59,232.63,106.19,232.11z',
  'M110.17,236.96L55.08,285.7c0.9,0.65,1.8,1.3,2.71,1.93l53.69-49.2C111.04,237.94,110.6,237.45,110.17,236.96z',
  'M114.48,241.52l-49.73,50.61c0.94,0.57,1.89,1.12,2.84,1.67l48.31-50.9C115.42,242.44,114.94,241.98,114.48,241.52z',
  'M119.09,245.77l-44.28,51.85c0.97,0.48,1.95,0.95,2.94,1.4l42.85-51.98C120.09,246.62,119.59,246.19,119.09,245.77z',
  'M123.98,249.69l-38.84,52.49c1,0.39,2,0.77,3.01,1.13l37.42-52.46C125.04,250.47,124.51,250.08,123.98,249.69z',
  'M129.14,253.26l-33.46,52.52c1.01,0.3,2.03,0.59,3.05,0.87l32.08-52.34C130.25,253.96,129.7,253.61,129.14,253.26z',
  'M134.54,256.46l-28.23,52c1.02,0.21,2.04,0.41,3.07,0.6l26.9-51.68C135.7,257.08,135.11,256.77,134.54,256.46z',
  'M140.15,259.27l-23.22,50.96c1.02,0.13,2.04,0.25,3.06,0.35l21.96-50.51C141.34,259.81,140.74,259.54,140.15,259.27z',
  'M145.94,261.68l-18.49,49.45c1.01,0.05,2.02,0.09,3.03,0.11l17.31-48.88C147.17,262.14,146.55,261.92,145.94,261.68z',
  'M151.88,263.69l-14.08,47.53c0.99-0.03,1.99-0.07,2.97-0.12l13-46.87C153.14,264.06,152.51,263.87,151.88,263.69z',
  'M157.95,265.27l-10.04,45.27c0.97-0.1,1.94-0.21,2.9-0.33l9.06-44.52C159.23,265.55,158.59,265.41,157.95,265.27z',
  'M164.12,266.42l-6.38,42.71c0.94-0.17,1.88-0.35,2.82-0.54l5.52-41.9C165.42,266.61,164.77,266.52,164.12,266.42z',
  'M170.35,267.14l-3.14,39.92c0.91-0.23,1.82-0.48,2.72-0.74l2.39-39.06C171.66,267.23,171,267.19,170.35,267.14z',
  'M176.61,267.42l-0.32,36.95c0.87-0.29,1.74-0.6,2.61-0.91l-0.31-36.04c-0.33,0-0.66,0.01-0.99,0.01S176.94,267.42,176.61,267.42z',
  'M182.88,267.27l2.07,33.85c0.83-0.35,1.66-0.7,2.49-1.06l-2.59-32.92C184.2,267.19,183.54,267.23,182.88,267.27z',
  'M189.13,266.69l4.04,30.68c0.79-0.39,1.58-0.79,2.36-1.2l-4.45-29.75C190.43,266.52,189.78,266.61,189.13,266.69z',
  'M195.32,265.68l5.6,27.52c0.75-0.43,1.49-0.87,2.23-1.32l-5.9-26.61C196.61,265.41,195.97,265.55,195.32,265.68z',
  'M201.42,264.23l6.77,24.42c0.7-0.47,1.4-0.95,2.09-1.44l-6.97-23.53C202.69,263.87,202.06,264.06,201.42,264.23z',
  'M207.41,262.36l7.58,21.41c0.66-0.5,1.31-1.01,1.96-1.53l-7.69-20.56C208.65,261.92,208.03,262.14,207.41,262.36z',
  'M213.25,260.07l8.06,18.55c0.61-0.53,1.22-1.07,1.82-1.61l-8.08-17.73C214.46,259.54,213.86,259.81,213.25,260.07z',
  'M218.92,257.38l8.25,15.84c0.57-0.55,1.13-1.11,1.69-1.67l-8.19-15.09C220.09,256.77,219.5,257.08,218.92,257.38z',
  'M224.39,254.3l8.18,13.35c0.52-0.57,1.04-1.16,1.55-1.74l-8.06-12.65C225.5,253.61,224.95,253.96,224.39,254.3z',
  'M229.62,250.85l7.89,11.05c0.48-0.59,0.96-1.18,1.43-1.78l-7.72-10.44C230.69,250.08,230.16,250.47,229.62,250.85z',
  'M234.6,247.03l7.42,9c0.44-0.6,0.87-1.21,1.3-1.82l-7.21-8.44C235.61,246.19,235.11,246.62,234.6,247.03z',
  'M239.31,242.89l6.81,7.18c0.4-0.62,0.79-1.24,1.18-1.86l-6.57-6.68C240.26,241.98,239.78,242.44,239.31,242.89z',
  'M243.71,238.42l6.1,5.59c0.36-0.63,0.71-1.26,1.06-1.89l-5.83-5.16C244.6,237.45,244.16,237.94,243.71,238.42z',
  'M247.8,233.66l5.31,4.22c0.32-0.63,0.64-1.27,0.95-1.91l-5.04-3.87C248.61,232.63,248.21,233.15,247.8,233.66z',
  'M251.54,228.63l4.49,3.09c0.28-0.64,0.57-1.28,0.84-1.92l-4.23-2.8C252.29,227.55,251.92,228.09,251.54,228.63z',
  'M254.93,223.35l3.67,2.16c0.25-0.64,0.5-1.29,0.74-1.93l-3.41-1.93C255.6,222.22,255.27,222.79,254.93,223.35z',
  'M257.94,217.85l2.87,1.43c0.21-0.65,0.43-1.29,0.63-1.94l-2.64-1.26C258.53,216.67,258.24,217.26,257.94,217.85z',
  'M260.56,212.15l2.14,0.88c0.18-0.65,0.36-1.3,0.53-1.95l-1.92-0.76C261.06,210.94,260.81,211.54,260.56,212.15z',
  'M262.77,206.28l1.48,0.49c0.15-0.65,0.29-1.3,0.43-1.96l-1.3-0.41C263.19,205.03,262.98,205.66,262.77,206.28z',
  'M264.57,200.27l0.93,0.24c0.11-0.65,0.22-1.31,0.33-1.97l-0.78-0.19C264.89,198.99,264.73,199.63,264.57,200.27z',
  'M265.93,194.14l0.5,0.09c0.08-0.65,0.15-1.31,0.23-1.97l-0.38-0.06C266.16,192.85,266.05,193.5,265.93,194.14z',
  'M266.86,187.94l0.2,0.02c0.05-0.65,0.09-1.31,0.13-1.97l-0.12-0.01C267,186.64,266.94,187.29,266.86,187.94z',
  'M267.36,181.69l0.03,0c0.02-0.66,0.03-1.31,0.04-1.97l0,0C267.42,180.38,267.39,181.03,267.36,181.69z',
  'M267.39,173.45l-0.03,0c0.03,0.66,0.06,1.31,0.07,1.97l0,0C267.42,174.76,267.41,174.1,267.39,173.45z',
  'M267.07,169.16l0.12-0.01c-0.04-0.66-0.08-1.32-0.13-1.97l-0.2,0.02C266.94,167.85,267,168.5,267.07,169.16z',
  'M266.27,162.93l0.38-0.06c-0.07-0.66-0.14-1.32-0.23-1.97l-0.5,0.09C266.05,161.64,266.16,162.28,266.27,162.93z',
  'M265.04,156.78l0.78-0.19c-0.1-0.66-0.21-1.31-0.33-1.97l-0.93,0.24C264.73,155.5,264.89,156.14,265.04,156.78z',
  'M263.38,150.73l1.3-0.41c-0.14-0.66-0.28-1.31-0.43-1.96l-1.48,0.49C262.98,149.48,263.19,150.1,263.38,150.73z',
  'M261.3,144.81l1.92-0.76c-0.17-0.65-0.35-1.3-0.53-1.95l-2.14,0.88C260.81,143.59,261.06,144.2,261.3,144.81z',
  'M258.81,139.06l2.64-1.26c-0.2-0.65-0.42-1.3-0.63-1.94l-2.87,1.43C258.24,137.87,258.53,138.46,258.81,139.06z',
  'M255.92,133.49l3.41-1.93c-0.24-0.65-0.49-1.29-0.74-1.93l-3.67,2.16C255.27,132.35,255.6,132.92,255.92,133.49z',
  'M252.65,128.14l4.23-2.8c-0.27-0.64-0.56-1.28-0.84-1.92l-4.49,3.09C251.92,127.04,252.29,127.59,252.65,128.14z',
  'M249.01,123.03l5.04-3.87c-0.31-0.64-0.63-1.27-0.95-1.91l-5.31,4.22C248.21,121.99,248.61,122.5,249.01,123.03z',
  'M245.03,118.18l5.83-5.16c-0.35-0.63-0.7-1.26-1.06-1.89l-6.1,5.59C244.16,117.2,244.6,117.69,245.03,118.18z',
  'M240.72,113.62l6.57-6.68c-0.39-0.62-0.78-1.24-1.18-1.86l-6.81,7.18C239.78,112.7,240.26,113.16,240.72,113.62z',
  'M236.11,109.37l7.21-8.44c-0.43-0.61-0.86-1.22-1.3-1.82l-7.42,9C235.11,108.52,235.61,108.94,236.11,109.37z',
  'M231.21,105.45l7.72-10.44c-0.47-0.6-0.95-1.19-1.43-1.78l-7.89,11.05C230.16,104.67,230.69,105.06,231.21,105.45z',
  'M226.06,101.88l8.06-12.65c-0.51-0.58-1.03-1.17-1.55-1.74l-8.18,13.35C224.95,101.18,225.5,101.53,226.06,101.88z',
  'M220.66,98.68l8.19-15.09c-0.56-0.56-1.12-1.12-1.69-1.67l-8.25,15.84C219.5,98.06,220.09,98.37,220.66,98.68z',
  'M215.05,95.87l8.08-17.73c-0.6-0.54-1.21-1.08-1.82-1.61l-8.06,18.55C213.86,95.33,214.46,95.59,215.05,95.87z',
  'M209.26,93.45l7.69-20.56c-0.65-0.51-1.3-1.02-1.96-1.53l-7.58,21.41C208.03,93,208.65,93.22,209.26,93.45z',
  'M203.32,91.45l6.97-23.53c-0.69-0.48-1.39-0.97-2.09-1.44l-6.77,24.42C202.06,91.08,202.69,91.26,203.32,91.45z',
  'M197.25,89.87l5.9-26.61c-0.74-0.45-1.48-0.89-2.23-1.32l-5.6,27.52C195.97,89.59,196.61,89.73,197.25,89.87z',
  'M191.08,88.71l4.45-29.75c-0.78-0.41-1.57-0.81-2.36-1.2l-4.04,30.68C189.78,88.53,190.43,88.62,191.08,88.71z',
  'M184.85,88l2.59-32.92c-0.82-0.36-1.65-0.72-2.49-1.06l-2.07,33.85C183.54,87.9,184.2,87.95,184.85,88z',
];
