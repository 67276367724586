import styled from 'styled-components';
import Button from '~/components/Button';

export const Container = styled.div`
  width: 344px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Logo = styled.img`
  height: 23px;
  filter: invert(100%);
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.bgColor.main};
`;

export const HeaderImg = styled.img`
  width: 140px;
  height: 20px;
  margin-bottom: 40px;
`;

export const Form = styled.form`
  position: relative;
  padding: 20px 30px;
  width: 460px;
  background-color: ${props => props.theme.bgColor.main};
`;

export const FormBottomWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: ${props => (props.center ? 'center' : 'space-between')};
  align-items: center;
`;

export const SubmitButton = styled(Button)`
  width: 100px;
`;

export const Title = styled.h1`
  font-weight: normal;
  margin-bottom: 40px;
  color: #1c2024;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.1px;
`;

export const SsoWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Divider = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 15px;

  &::after,
  &::before {
    content: '';
    border-bottom: 1px solid ${props => props.theme.bgColor.muted};
    flex: 1;
  }

  &:not(:empty)::before {
    margin-right: 0.5em;
  }

  &:not(:empty)::after {
    margin-left: 0.5em;
  }
`;
