import React, { useRef } from 'react';
import Input from '~/components/Input';
import { Form, FormBottomWrapper, Title } from '~/components/LoginElements';
import Wrapper from '~/components/Wrapper';
import Button from '~/components/Button';
import PropTypes from 'prop-types';

function MultiFactorAuthentication({ onSubmit = () => {} }) {
  const authenticationCode = useRef();

  function onSubmitWrapper(e) {
    e.target.value = authenticationCode.current.value;
    onSubmit(e);
  }

  return (
    <Form onSubmit={onSubmitWrapper}>
      <Title>Two-factor authentication</Title>
      <Input
        ref={authenticationCode}
        type='number'
        label='Authentication code'
        placeholder='XXXXXX'
        helpText='Open your Authenticator app to view your authentication code'
        autoFocus
      />
      <FormBottomWrapper>
        <Wrapper center>
          <Button
            variant='primary'
            type='submit'
            width={200}
          >
            Verify
          </Button>
        </Wrapper>
      </FormBottomWrapper>
    </Form>
  );
}

export default MultiFactorAuthentication;

MultiFactorAuthentication.propTypes = {
  onSubmit: PropTypes.func,
};
