import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const EuroIcon = () => (
  <svg
    width='12'
    height='14'
    viewBox='0 0 12 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.5049 2.1767C11.0882 1.76003 9.83822 0.926697 7.75488 0.926697C4.72814 0.926697 3.33005 2.8268 2.90334 5.09336M11.5049 11.3434C11.0882 11.76 9.83822 12.5934 7.75488 12.5934C4.72814 12.5934 3.33005 10.6933 2.90334 8.4267M2.90334 5.09336C2.80138 5.63497 2.75488 6.1975 2.75488 6.76003C2.75488 7.32256 2.80138 7.88509 2.90334 8.4267M2.90334 5.09336H0.254883M2.90334 5.09336H8.58822M2.90334 8.4267H0.254883H8.58822'
      stroke='#191919'
    />
  </svg>
);

const SpinButtonIcon = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.14897 10.6247C7.9049 10.8688 7.50917 10.8688 7.26509 10.6247L3.51509 6.87474C3.27101 6.63066 3.27101 6.23494 3.51509 5.99086C3.75917 5.74678 4.1549 5.74678 4.39897 5.99086L7.70703 9.29892L11.0151 5.99086C11.2592 5.74678 11.6549 5.74678 11.899 5.99086C12.1431 6.23494 12.1431 6.63066 11.899 6.87474L8.14897 10.6247Z'
      fill='#757575'
    />
  </svg>
);

const BareBonesTextInput = styled.input`
  padding: 0px 5px;
  height: 38px;
  border: none;
  width: 100%;
  font-size: 1.3rem;
  background-color: transparent;

  &:focus {
    border: none;
    outline: none;
  }
`;

const SpinButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;
`;

const UnitContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 4px 0 0 4px;
  border-right: ${props => `1px solid ${props.theme.lightMedium}`};
  background-color: ${props => props.theme.bgColor.muted};
`;

const Wrapper = styled.div`
  display: flex;
  width: ${props => props.width || '130px'};
  height: 40px;
  border: ${props => `1px solid ${props.theme.lightMedium}`};
  border-radius: 5px;
  background-color: ${props => props.theme.lightest};

  &:focus-within {
    border-color: ${props => props.theme.copperLightest};
    outline: 1px solid ${props => props.theme.copperLightest};

    ${UnitContainer} {
      border-right-color: transparent;
    }
  }

  input {
    color: ${props => (props.error ? props.theme.dangerLight : props.theme.darkest)};

    &::placeholder {
      color: ${props => props.theme.lightMedium};
    }
  }

  ${props =>
    props.error &&
    css`
      &,
      ${UnitContainer} {
        border-color: ${props => props.theme.danger};
      }

      ${UnitContainer} {
        border-right-color: transparent;
      }
    `}

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      background-color: ${props => props.theme.lighter};
      ${BareBonesTextInput} {
        color: ${props => props.theme.lightMedium};
      }

      ${BareBonesTextInput}, ${SpinButtonGroup} {
        pointer-events: none;
      }
    `}

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
`;

const SpinButtonWrapper = styled.div`
  cursor: pointer;
  position: relative;
  height: 15px;
  width: 15px;

  /* prevent text selection when clicking the "button" */
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* safari */
  -ms-user-select: none; /* internet explorer */

  svg {
    position: absolute;
    ${props =>
      props.top &&
      `
      transform: scaleY(-1);
    `}
  }
`;

function FancyUnitInput({
  onChange,
  value = undefined,
  width,
  increment = 0.001,
  decimalPlace = 3,
  maxAmount,
  minAmount,
  unit,
  error,
  disabled,
  ...rest
}) {
  const [localValue, setLocalValue] = useState(value);

  function callOuterOnChange({ event, value }) {
    onChange({
      ...event,
      target: {
        ...event.target,
        value,
      },
    });
  }

  function localOnChange(e) {
    const { value } = e.target;

    const isValueEmptyString = value === '';
    if (isValueEmptyString) {
      setLocalValue(undefined);
      callOuterOnChange({ event: e, value: undefined });
      return;
    }

    const parsedValue = Number(value);
    const isMaxAmountReached = parsedValue > maxAmount;
    const isMinAmountReached = parsedValue < minAmount;

    let valueToSet = parsedValue;
    if (isMaxAmountReached) valueToSet = maxAmount;
    if (isMinAmountReached) valueToSet = minAmount;

    setLocalValue(valueToSet);
    callOuterOnChange({ event: e, value: valueToSet });
  }

  function spinButtonClickHandler(isTop) {
    const parsedLocalValue = Number(localValue) || 0;
    localOnChange({
      target: {
        value: isTop
          ? parseFloat(parsedLocalValue + increment).toFixed(decimalPlace)
          : parseFloat(parsedLocalValue - increment).toFixed(decimalPlace),
      },
    });
  }

  return (
    <Wrapper
      width={width}
      error={error}
      disabled={disabled}
    >
      <UnitContainer>{unit === 'euro' ? <EuroIcon /> : unit}</UnitContainer>
      <SpinButtonGroup>
        <SpinButtonWrapper
          top
          onClick={() => spinButtonClickHandler(true)}
        >
          <SpinButtonIcon />
        </SpinButtonWrapper>
        <SpinButtonWrapper onClick={() => spinButtonClickHandler()}>
          <SpinButtonIcon />
        </SpinButtonWrapper>
      </SpinButtonGroup>
      <BareBonesTextInput
        type='number'
        onChange={localOnChange}
        onKeyDown={e => {
          const isUp = e.code === 'ArrowUp';
          const isDown = e.code === 'ArrowDown';
          const isUpOrDown = isUp || isDown;
          if (!isUpOrDown) return;

          e.preventDefault();
          spinButtonClickHandler(isUp);
        }}
        value={localValue}
        error={error}
        disabled={disabled}
        {...rest}
      />
    </Wrapper>
  );
}

FancyUnitInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  isMetric: PropTypes.bool,
  unit: PropTypes.string,
  increment: PropTypes.number,
  decimalPlace: PropTypes.number,
  value: PropTypes.number,
  minAmount: PropTypes.number,
  maxAmount: PropTypes.number,
  width: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default FancyUnitInput;
