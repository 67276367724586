import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  ${props =>
    props.center &&
    css`
      justify-content: center;
      align-items: center;
    `}
`;

export default Wrapper;
