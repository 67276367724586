import React, { useState, useEffect, createContext, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import mixpanel from 'mixpanel-browser';
import api, { setOrganizationHeaders, setInterceptors, unsetAuthenticationHeaders, unsetInterceptors } from '~/lib/api';
import useNotification from '~/hooks/use-notification';
import { fromApi as featureFlagsFromApi } from '~/lib/data-parsers/feature-flags';
import { organizationEventsFromApi, userEventsFromApi } from '~/lib/data-parsers/event-flags';
import { useAuth } from '@clerk/clerk-react';

const fromApi = organization => ({
  id: organization.id,
  name: organization.name,
  featureFlags: featureFlagsFromApi(organization.feature_flags),
  eventFlags: organizationEventsFromApi(organization.event_flags),
});

const AuthenticationContext = createContext();

function AuthenticationProvider(props) {
  const history = useHistory();
  const { state: locationState } = useLocation();
  const cachedOrganizationId = window.localStorage.getItem('onomondo-organization-id') || null;
  const { getToken: clerkGetToken, isSignedIn, signOut } = useAuth();

  const { addNotification } = useNotification();
  const [email, setEmail] = useState(null);
  const [isMfaEnabled, setIsMfaEnabled] = useState(false);
  const [userType, setUserType] = useState(null);
  const [userId, setUserId] = useState(null);
  const [accessLevel, setAccessLevel] = useState(null);
  const [writeTags, setWriteTags] = useState(true);
  const [readTags, setReadTags] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [assumedOrganizationId, setAssumedOrganizationId] = useState(
    cachedOrganizationId && Number(cachedOrganizationId),
  );
  const [isViewingAsAssumedOrganization, setIsViewingAsAssumedOrganization] = useState(false);
  const [usersOrganizations, setUsersOrganizations] = useState([]);
  const [usersCurrentOrganizationId, setUsersCurrentOrganizationId] = useState(null);

  const logout = useCallback(
    async e => {
      if (!isSignedIn) return;

      await signOut();
      mixpanel.track('Log out');
      if (mixpanel.get_property('$user_id')) mixpanel.reset();

      const isIntercomDefined = typeof window.Intercom === 'function';
      if (isIntercomDefined) window.Intercom('shutdown');
      unsetAuthenticationHeaders();
      unsetInterceptors();
      window.localStorage.removeItem('x-onomondo-org-id');
      setAssumedOrganizationId(null);

      let message = 'You have been logged out';
      if (e) {
        message = 'You have been logged out due to an error, the team has been notified';
        Sentry.captureException(e);
      }

      addNotification({ message, type: e ? 'error' : null });
    },
    [addNotification, signOut, isSignedIn],
  );

  const changeOrganization = useCallback(
    async organizationId => {
      try {
        setOrganizationHeaders(organizationId);
        setUsersCurrentOrganizationId(organizationId);
        window.localStorage.setItem('x-onomondo-org-id', organizationId);
        history.replace(locationState?.from || '/');
      } catch (e) {
        logout(e);
      }
    },
    [logout, history],
  );

  const getOrganizations = useCallback(async () => {
    try {
      const clerkToken = await clerkGetToken();
      const { data } = await api.get('/me/organizations', {
        headers: {
          authorization: `Bearer ${clerkToken}`,
        },
      });
      setUsersOrganizations(data.map(o => ({ id: o?.id, name: o?.entity })));
    } catch (e) {
      logout(e);
    }
  }, [logout, clerkGetToken]);

  const setStuff = useCallback(async () => {
    try {
      const cachedOrganizationUuid = window.localStorage.getItem('x-onomondo-org-id') ?? null;

      setInterceptors(history, clerkGetToken);
      await getOrganizations();
      if (cachedOrganizationUuid) changeOrganization(cachedOrganizationUuid);
      setIsLoading(false);
    } catch (e) {
      logout(e);
    }
  }, [getOrganizations, history, logout, clerkGetToken, changeOrganization]);

  const getMe = useCallback(async () => {
    try {
      const { data } = await api.get('/me');
      const events = userEventsFromApi(data.events);
      const accessLevel = data.type;
      const userType = data.user_type;
      setWriteTags(data.tags.filter(t => t.can_write));
      setReadTags(data.tags.filter(t => !t.can_write));
      setEmail(data.email);
      setIsMfaEnabled(data.is_mfa_enabled);
      setUserId(data.id);
      setUserType(userType);
      setAccessLevel(accessLevel);
      setUsersOrganizations(data.organizations.map(fromApi));
      setEvents(events);
      changeOrganization(data.current_organization_id);

      mixpanel.identify(data.id);

      const currentOrganizationName = data.organizations.find(o => o.id === data.current_organization_id).name;
      const currentOrganizationId = data.organizations.find(o => o.id === data.current_organization_id).id;

      const isIntercomDefined = typeof window.Intercom === 'function';
      if (!isIntercomDefined) return;

      window.Intercom('boot', {
        api_base: 'https://api-iam.eu.intercom.io',
        app_id: process.env.INTERCOM_APP_ID,
        name: null,
        user_id: data.id,
        user_hash: data.user_hash,
        email: data.email,
        company: {
          name: currentOrganizationName,
          id: currentOrganizationId,
        },
        companies: data.organizations,
      });
    } catch (e) {
      logout(e);
    }
  }, [logout, changeOrganization]);

  useEffect(() => {
    if (!isSignedIn) return;
    setStuff();
  }, [isSignedIn, setStuff, getOrganizations]);

  useEffect(() => {
    const shouldRemoveCache = assumedOrganizationId === null;
    setIsViewingAsAssumedOrganization(false);
    if (shouldRemoveCache) {
      window.localStorage.removeItem('onomondo-organization-id');
    } else {
      window.localStorage.setItem('onomondo-organization-id', assumedOrganizationId);
    }
  }, [assumedOrganizationId]);

  useEffect(() => {
    if (!isSignedIn || !usersCurrentOrganizationId) return;
    getMe();
  }, [isSignedIn, getMe, usersCurrentOrganizationId]);

  const assumeOrganizationId = id => {
    setAssumedOrganizationId(id || null);
  };

  const canWriteNetworkList = networkList => {
    if (accessLevel !== 'member') return true;
    const flatWriteTagsIds = writeTags.map(wt => wt.id);
    const flatNetworkListTagsIds = networkList.tags.map(nt => nt.id);
    const canWrite = flatWriteTagsIds.some(id => flatNetworkListTagsIds.includes(id));
    return canWrite;
  };

  async function setUserCompletedSimPageOnboarding() {
    try {
      await api.post('/events/sim-page-onboarding-flow-completed');
    } catch (e) {
      logout(e);
    }
  }

  function toggleIsViewingAsAssumedOrganization() {
    setIsViewingAsAssumedOrganization(!isViewingAsAssumedOrganization);
  }

  return (
    <AuthenticationContext.Provider
      value={{
        logout,
        isLoggedIn: isSignedIn && !isLoading,
        isGod: userType === 'god',
        isDemiGod: userType === 'demigod',
        isOwner: accessLevel === 'owner',
        isAdmin: accessLevel === 'admin',
        isOrgMember: accessLevel === 'member',
        accessLevel,
        userId,
        userType,
        email,
        events,
        isMfaEnabled,
        setIsMfaEnabled,
        writeTags,
        readTags,
        canWrite: accessLevel === 'member' ? writeTags.length > 0 : true,
        canView: accessLevel === 'member' ? readTags.length > 0 || writeTags.length > 0 : true,
        isViewingAsAssumedOrganization,
        toggleIsViewingAsAssumedOrganization,
        assumedOrganizationId,
        assumeOrganizationId,
        usersOrganizations,
        usersCurrentOrganizationId,
        changeOrganization,
        canWriteNetworkList,
        setUserCompletedSimPageOnboarding,
        getMe,
      }}
      {...props}
    />
  );
}

export { AuthenticationProvider, AuthenticationContext };
