import { useState, useEffect } from 'react';

function useClipboard() {
  const [canWrite, setCanWrite] = useState(false);

  useEffect(() => {
    if (!navigator.permissions) return;
    navigator.permissions
      .query({ name: 'clipboard-write' })
      .then(result => {
        setCanWrite(['granted', 'prompt'].includes(result.state));
      })
      .catch(() => setCanWrite(false));
  }, []);

  return {
    canWrite,
    async write(text) {
      await navigator.clipboard.writeText(text);
    },
  };
}

export default useClipboard;
