import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MEGABYTE_IN_BYTES } from '~/lib/numbers';
import TextInput from './TextInput';

function BytesInput({ onChange, value, ...rest }) {
  const [localValue, setLocalValue] = useState('');

  useEffect(() => {
    if (value === '' || value === undefined || value === null) {
      setLocalValue('');
    } else {
      const megaBytes = value / MEGABYTE_IN_BYTES;
      setLocalValue(megaBytes);
    }
  }, []);

  function localOnChange(e) {
    const { value } = e.target;
    const bytes = value === '' ? '' : value * MEGABYTE_IN_BYTES;
    setLocalValue(value);
    onChange({
      ...e,
      target: {
        ...e.target,
        value: bytes,
      },
    });
  }

  return (
    <TextInput
      {...rest}
      type='number'
      min='0.5'
      onChange={localOnChange}
      value={localValue}
    />
  );
}

BytesInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default BytesInput;
