import { useCallback, useEffect } from 'react';

function useKeyPress(key, handler, dependencies) {
  const onKeyPress = useCallback(
    e => {
      const isKeyPressed = e.key === key;
      if (!isKeyPressed) return;

      handler();
    },
    [key, handler],
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyPress, false);

    return () => document.removeEventListener('keydown', onKeyPress, false);
  }, [handler, dependencies, onKeyPress]);
}

export default useKeyPress;
