import React, { useState } from 'react';
// import { useGoogleLogin } from '@react-oauth/google';
// import useAuthentication from '~/hooks/use-authentication';
import PropTypes from 'prop-types';
import {
  // GoogleBtn,
  StyledCheckbox,
  AllowDataProcessing,
  DataProcessingContent,
  StyledLink,
  PasswordInputWrapper,
  EyeIcon,
  ContinueBtn,
  StyledInput,
  CheckboxError,
  StyledForm,
  SubTitleWrapper,
} from './components/Elements';
import Icon from '~/components/Icon';
import { SignUpCardTitle, ModalParagraph } from '~/components/Text';

function FillLoginInfoAndTerms({
  formData,
  setFormData,
  errors,
  setErrors,
  isAgreedToTerms,
  setIsAgreedToTerms,
  nextStep,
}) {
  const handleCheckbox = e => {
    setIsAgreedToTerms(!!e?.target.checked);
  };
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  // const { authentication } = useAuthentication();
  // const googleLogin = useGoogleLogin({
  //   onSuccess: tokenResponse => authentication.loginWithGoogle(tokenResponse),
  // });

  return (
    <>
      <SignUpCardTitle>Try Onomondo for free</SignUpCardTitle>
      <SubTitleWrapper>
        <ModalParagraph>
          Already have an account? <StyledLink href='/login'>Log in.</StyledLink>
        </ModalParagraph>
      </SubTitleWrapper>
      <StyledForm>
        {/* <GoogleBtn onClick={() => googleLogin()}>
          <Icon
            name='google'
            color={null}
            width={20}
            height={20}
          />
          Sign in with Google
        </GoogleBtn> */}
        <StyledInput
          noMargin
          name='email'
          type='input'
          label='Company email'
          placeholder='email@company.com'
          onChange={e => {
            setFormData({ ...formData, emailAddress: e.target.value });
            setErrors({ ...errors, emailAddress: '' });
          }}
          value={formData.emailAddress}
          error={!!errors.emailAddress}
          helpText={errors.emailAddress}
        />
        <PasswordInputWrapper>
          <StyledInput
            noMargin
            name='password'
            type={isPasswordShown ? 'input' : 'password'}
            label='Create password'
            placeholder='Create password'
            onChange={e => {
              setFormData({ ...formData, password: e.target.value });
              setErrors({ ...errors, password: '' });
            }}
            value={formData.password}
            error={!!errors.password}
            helpText={errors.password || 'Minimum 10 characters, an uppercase, and a number.'}
          />
          <EyeIcon onClick={() => setIsPasswordShown(!isPasswordShown)}>
            <Icon
              name='eye'
              width={20}
              height={20}
              color={null}
            />
          </EyeIcon>
        </PasswordInputWrapper>
        <AllowDataProcessing error={isAgreedToTerms}>
          <StyledCheckbox
            type='checkbox'
            onChange={handleCheckbox}
            checked={!!isAgreedToTerms}
          />
          <DataProcessingContent>
            <p>
              I agree to the{' '}
              <StyledLink
                href='https://cdn.growth.onomondo.com/app/uploads/2024/07/04120814/Terms-of-Service-July-1st-2024-.pdf'
                target='_blank'
              >
                Terms of Service
              </StyledLink>
              <span> and </span>
              <StyledLink
                href='https://onomondo.com/privacy-policy/'
                target='_blank'
              >
                {' '}
                Privacy Policy
              </StyledLink>
              .
            </p>
            {!isAgreedToTerms && <CheckboxError>{errors.isAgreedToTerms}</CheckboxError>}
          </DataProcessingContent>
        </AllowDataProcessing>
        <ContinueBtn onClick={nextStep}>Continue</ContinueBtn>
      </StyledForm>
    </>
  );
}

FillLoginInfoAndTerms.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
  isAgreedToTerms: PropTypes.bool,
  setIsAgreedToTerms: PropTypes.func,
  nextStep: PropTypes.func,
};

export default FillLoginInfoAndTerms;
