import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import styled, { keyframes } from 'styled-components';
import Wrapper from '~/components/Wrapper';
import OnomondoLogo from '~/components/OnomondoLogo';

const SubHeader = styled.h4`
  color: ${props => props.theme.font.muted};
  margin-top: 5px;
`;

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const gradientAnimation = keyframes`
  0% {
    background-position:0% 50%
  }
  50% {
    background-position:100% 50%
  }
  100% {
    background-position:0% 50%
  }
`;

const GiantLetter = styled.h1`
  font-size: 65px;
  margin-top: 0;
  background: rgb(5, 253, 194);
  background: linear-gradient(90deg, rgba(5, 253, 194, 1) 0%, rgba(71, 214, 35, 1) 35%, rgba(255, 0, 159, 1) 100%);
  background-size: 600% 600%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${gradientAnimation} 5s ease infinite;
  user-select: none;
`;

const Muted = styled.p`
  margin-bottom: 15px;
`;

const Stack = styled.pre`
  overflow: auto;
  font-size: 0.7em;
  background-color: #e6e6e6;
  padding: 5px;
`;

const A = styled.a`
  font-size: 1.1rem;
  font-weight: bold;
`;

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    hasChunkError: false,
    error: null,
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    const isChunkLoadError = error.name === 'ChunkLoadError';
    return {
      hasChunkError: isChunkLoadError,
      hasError: !isChunkLoadError,
      error,
    };
  }

  componentDidCatch(error, errorInfo) {
    if (this.state.hasChunkError) {
      window.location.reload();
      return;
    }
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    if (!this.state.error) return this.props.children;
    const isProduction = process.env.NODE_ENV === 'production';

    return (
      <Wrapper center>
        {this.state.hasError && (
          <>
            <ErrorWrapper>
              <GiantLetter>ERR</GiantLetter>
              <OnomondoLogo
                size={50}
                primaryColor='#05FDC2'
                secondaryColor='#47D623'
                futureColor='#FF009F'
                playAnimation={isProduction}
                shiftColor={isProduction}
                animated={isProduction}
              />
              <GiantLetter>R</GiantLetter>
            </ErrorWrapper>
            <SubHeader>Something went wrong</SubHeader>
            <SubHeader>This was the message: {this.state.error.message}</SubHeader>
            <Muted>The team has been notified</Muted>

            <A href={window.location.href}>Click here to try again, or reload the page</A>

            {!isProduction && (
              <div>
                <Stack>{this.state.error.stack}</Stack>
              </div>
            )}
          </>
        )}
        {this.state.hasChunkError && (
          <>
            <SubHeader>The app has updated, reloading.</SubHeader>
            <A href={window.location.href}>Click here if it doesn&apos;t happen automatically</A>
          </>
        )}
      </Wrapper>
    );
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
