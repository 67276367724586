import React from 'react';
import PropTypes from 'prop-types';
import { ContinueBtn } from './components/Elements';
import SimpleInner from './components/SimpleInner';

function AccountVerified({ nextStep }) {
  return (
    <>
      <SimpleInner
        icon='check-circle-broken'
        title='Account verified'
        subTitle='You are now all set, go ahead and explore our platform. Welcome aboard!'
      />
      <ContinueBtn onClick={nextStep}>Access the platform</ContinueBtn>
    </>
  );
}

AccountVerified.propTypes = {
  nextStep: PropTypes.func,
};

export default AccountVerified;
