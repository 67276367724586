import styled, { css } from 'styled-components';
import TextInput from './TextInput';

const RoundedInput = styled(TextInput)`
  width: 100%;
  height: 2.85rem;
  border-radius: 2.15rem;
  border: 1px solid ${props => props.theme.lightMedium};

  ${props =>
    props.error &&
    css`
      border-color: ${props => props.theme.danger};
    `}
`;

const RoundedNameInput = styled(TextInput)`
  width: 100%;
  height: 2.857rem;
  border-radius: 2.143rem;
  border: 1px solid ${props => props.theme.lightMedium};

  ${props =>
    props.error &&
    css`
      border-color: ${props => props.theme.danger};
    `}
`;

export { RoundedInput, RoundedNameInput };
