import useEventFlags from './use-event-flags';

const events = [
  {
    id: 'softsimReady',
    page: '/dashboard',
    flags: ['haveSoftsimBeenExported'],
    antiFlags: ['haveSoftsimActivated'],
    hashtag: 'softsimReady',
  },
];

function useEvent() {
  const eventFlags = useEventFlags();

  function checkFlags({ flags = [], antiFlags = [] }) {
    const hasAllFlags = flags.every(flag => eventFlags[flag]);
    const hasAnyAntiFlags = antiFlags.some(antiFlag => eventFlags[antiFlag]);

    return hasAllFlags && !hasAnyAntiFlags;
  }

  function checkPath(event) {
    const { page } = event;
    if (!page) return true;

    const path = window.location.pathname;
    if (path !== page) return false;

    return true;
  }

  function testEvents() {
    events.forEach(event => {
      const storageEvent = localStorage.getItem('event-' + event.id);
      if (storageEvent) return;

      const flagCheck = checkFlags(event);
      if (!flagCheck) return;

      const pathCheck = checkPath(event);
      if (!pathCheck) return;

      window.location.hash = event.hashtag;
      localStorage.setItem('event-' + event.id, JSON.stringify({ date: new Date() }));
    });
  }

  testEvents();
}

export default useEvent;
