import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import useTrackPageView from '~/hooks/use-track-page-view';
import useAuthentication from '~/hooks/use-authentication';
import Wrapper from '~/components/Wrapper';
import Loader from '~/components/Loader';

function Logout() {
  useTrackPageView('Log out');
  const authentication = useAuthentication();

  useEffect(() => {
    authentication.logout();
  }, [authentication]);

  if (!authentication.isLoggedIn) {
    return <Redirect to='/login' />;
  }

  return (
    <Wrapper center>
      <Loader />
      <p>Logging you out</p>
    </Wrapper>
  );
}

export default Logout;
