import { useState, useEffect } from 'react';
import api from '~/lib/api';

function useLocationAndTechnologies() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function get() {
      try {
        const { data } = await api.get('/networks/location-and-technologies');

        const convertedData = data.map(d => ({
          countryCode: d.country_code,
          country: d.country,
          technologies: d.technologies.map(t => ({
            tech: t.tech,
            availableWithFreeTrial: t.available_with_free_trial,
            availableWithPoc: t.available_with_poc,
            permanentRoamingRestriction: t.permanent_roaming_restriction,
          })),
          shippingData: {
            shippingPossible: d.shipping_data.shipping_possible,
            importPossible: d.shipping_data.import_possible,
            customsFeeRequired: d.shipping_data.customs_fee_required,
          },
        }));

        setData(convertedData);
        setLoading(false);
      } catch (e) {
        setError(e);
        setLoading(false);
      }
    }

    setLoading(true);
    get();
  }, []);

  return {
    loading,
    error,
    data,
  };
}

export default useLocationAndTechnologies;
