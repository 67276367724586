import React from 'react';
import PropTypes from 'prop-types';
import { StyledLink, VerifyEmailContainer } from './components/Elements';
import { SignUpCardSubtitle } from '~/components/Text';
import useNotification from '~/hooks/use-notification';
import SimpleInner from './components/SimpleInner';

function VerifyEmail({ formData, startEmailVerification }) {
  const { addNotification } = useNotification();

  const onClick = () => {
    startEmailVerification();
    addNotification({
      message: 'Verification email sent!',
      type: 'success',
    });
  };

  return (
    <>
      <SimpleInner
        icon='verify-email'
        title='Verify your account'
        subTitle={`We have sent an email to ${formData.emailAddress}. Please verify your email address within 48 hours.`}
      />
      <VerifyEmailContainer>
        <SignUpCardSubtitle>{`If you didn't receive it, check your SPAM folder or`}</SignUpCardSubtitle>
        <StyledLink onClick={onClick}>Click to resend</StyledLink>
      </VerifyEmailContainer>
    </>
  );
}

VerifyEmail.propTypes = {
  formData: PropTypes.shape({
    emailAddress: PropTypes.string,
  }),
  startEmailVerification: PropTypes.func,
};

export default VerifyEmail;
