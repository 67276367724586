import React from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import Wrapper from '~/components/Wrapper';
import OnomondoLogo from '~/components/OnomondoLogo';

const SubHeader = styled.h4`
  color: ${props => props.theme.font.muted};
  margin-bottom: 20px;
  margin-top: 5px;
`;

const FourOhFourWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const gradientAnimation = keyframes`
  0% {
    background-position:0% 50%
  }
  50% {
    background-position:100% 50%
  }
  100% {
    background-position:0% 50%
  }
`;

const GiantFour = styled.h1`
  font-size: 185px;
  line-height: 150px;
  background: rgb(5, 253, 194);
  background: linear-gradient(90deg, rgba(5, 253, 194, 1) 0%, rgba(71, 214, 35, 1) 35%, rgba(255, 0, 159, 1) 100%);
  background-size: 600% 600%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${gradientAnimation} 5s ease infinite;
  user-select: none;
`;

function NotFound() {
  return (
    <Wrapper center>
      <FourOhFourWrapper>
        <GiantFour>4</GiantFour>
        <OnomondoLogo
          size={150}
          primaryColor='#05FDC2'
          secondaryColor='#47D623'
          futureColor='#FF009F'
          playAnimation
          shiftColor
          animated
        />
        <GiantFour>4</GiantFour>
      </FourOhFourWrapper>
      <h1>Page not found</h1>
      <SubHeader>Oops. Something went wrong</SubHeader>
      <Link to='/'>Take me back to the beginning</Link>
    </Wrapper>
  );
}

export default NotFound;
