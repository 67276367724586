import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Checkbox from '../Checkbox';
import useIsVisible from '~/hooks/use-is-visible';

const DropdownWrapper = styled.div`
  position: fixed;
  z-index: ${props => props.theme.zIndex.input + 1};
  width: calc(${props => props.width}px + 8px);
  margin-left: -4px;
  margin-top: -3px;
  max-height: ${props => props.maxHeight || '300px'};
  overflow-y: auto;

  background-color: ${props => props.theme.lightest};
  box-shadow: 1px 1px 12px 2px rgba(0, 0, 0, 0.15);
  border: 1px solid ${props => props.theme.lighter};
  border-radius: 5px;
`;

const BaseItemWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 50px;
  padding: 10px;
`;

const ItemWrapper = styled(BaseItemWrapper)`
  white-space: pre-wrap;
  cursor: pointer;

  ${props =>
    props.selected &&
    !props.checkbox &&
    css`
      background-color: ${props => props.theme.light};
    `}

  ${props =>
    props.isArrowSelectedIndex &&
    css`
      background-color: ${props => props.theme.lighter};
    `}
`;

const ItemLabel = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${props => props.theme.darker};

  ${props =>
    props.ellipsis &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}

  ${props =>
    props.checkbox &&
    css`
      margin-left: 8px;
    `}
`;

const CategoryWrapper = styled.div`
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;

  ${props =>
    props.icon &&
    css`
      padding-left: 28px;
      background-image: url(${props => props.icon});
      background-position: left center;
      background-repeat: no-repeat;
    `}
`;

const CategoryLabel = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
`;

const Item = ({ item, checked, indeterminate, lastItem, checkbox, onClick, startPaging, ...rest }) => {
  const lastListItem = useIsVisible(startPaging);

  return (
    <ItemWrapper
      selected={checked || indeterminate}
      onClick={() => onClick(item)}
      ref={lastItem ? lastListItem : null}
      checkbox={checkbox}
      {...rest}
    >
      {checkbox && (
        <Checkbox
          type='checkbox'
          noMargin={true}
          checked={checked}
          // onChange is handled by the ItemWrapper's onClick
          onChange={() => {}}
          indeterminate={indeterminate}
        />
      )}
      <ItemLabel
        checkbox={checkbox}
        ellipsis={rest.ellipsis}
      >
        {item.label}
      </ItemLabel>
    </ItemWrapper>
  );
};

export const ItemPropType = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  indeterminate: PropTypes.bool,
});

Item.propTypes = {
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func,
  item: ItemPropType.isRequired,
  checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  checkbox: PropTypes.bool,
  startPaging: PropTypes.func,
  lastItem: PropTypes.bool,
  isArrowSelectedIndex: PropTypes.bool,
};

const categoryPropTypesObj = {
  label: PropTypes.string,
  icon: PropTypes.string,
};

const CategoryPropType = PropTypes.shape(categoryPropTypesObj);

const Category = ({ label, icon }) => {
  return (
    <CategoryWrapper icon={icon}>
      <CategoryLabel>{label}</CategoryLabel>
    </CategoryWrapper>
  );
};

Category.propTypes = categoryPropTypesObj;

const RenderCategories = ({ categories, categoryItemIndex, categoryIndex, ...rest }) => {
  let prevItemsLength = 0;
  return categories.map((category, idx) => {
    const { items: categoryItems, category: categoryProps } = category;
    const r = (
      <React.Fragment key={idx}>
        <Category {...categoryProps} />
        <RenderItems
          items={categoryItems}
          categoryItemIndex={categoryIndex === idx ? categoryItemIndex : -1}
          prevItemsLength={prevItemsLength}
          {...rest}
        />
      </React.Fragment>
    );
    prevItemsLength += categoryItems.length;
    return r;
  });
};

RenderCategories.propTypes = {
  categories: PropTypes.arrayOf(CategoryPropType).isRequired,
  onClick: PropTypes.func.isRequired,
  selectedItems: PropTypes.arrayOf(ItemPropType),
  checkbox: PropTypes.bool,
  categoryItemIndex: PropTypes.number,
  categoryIndex: PropTypes.number,
};

const RenderItems = ({ items, categoryItemIndex, selectedItems, onMouseOverItem, prevItemsLength = 0, ...rest }) => {
  return items.map((item, idx) => {
    const selectedItem = selectedItems.find(selectedItem => selectedItem.value === item.value);
    const indeterminate = selectedItem?.indeterminate;
    const checked = !!selectedItem && !indeterminate;

    const isArrowSelectedIndex = idx === categoryItemIndex;

    return (
      <Item
        key={idx}
        item={item}
        indeterminate={indeterminate}
        checked={checked}
        lastItem={idx === items.length - 1}
        onMouseEnter={() => onMouseOverItem(idx + prevItemsLength)}
        isArrowSelectedIndex={isArrowSelectedIndex}
        {...rest}
      />
    );
  });
};

RenderItems.propTypes = {
  items: PropTypes.arrayOf(ItemPropType).isRequired,
  onClick: PropTypes.func.isRequired,
  onMouseOverItem: PropTypes.func,
  categoryItemIndex: PropTypes.number,
  selectedItems: PropTypes.arrayOf(ItemPropType).isRequired,
  checkbox: PropTypes.bool,
  startPaging: PropTypes.func,
};

const RenderNoItems = () => (
  <BaseItemWrapper>
    <ItemLabel>No items found</ItemLabel>
  </BaseItemWrapper>
);

const RenderOptions = ({ items, isCategory, ...rest }) => {
  const anyItems = useMemo(() => items.length > 0, [items]);

  if (!anyItems) return <RenderNoItems />;

  if (isCategory) {
    return (
      <RenderCategories
        categories={items}
        {...rest}
      />
    );
  }

  return (
    <RenderItems
      items={items}
      {...rest}
    />
  );
};

const Options = ({ maxHeight, selectorMode, items, arrowSelectedIndex, parentRef, ...rest }) => {
  const ref = useRef(null);
  const isCategory = useMemo(() => !!(items?.length && items[0].items && items[0].category), [items]);
  const scrollParent = useMemo(() => parentRef.current, [parentRef]);

  useEffect(() => {
    const calculateDistanceFromTop = () => {
      const parentDistanceFromTop = parentRef.current?.getBoundingClientRect().top;
      const parentHeight = parentRef.current?.offsetHeight;
      const newDistanceFromTop = parentDistanceFromTop + parentHeight;

      if (newDistanceFromTop === ref.current?.style?.top) return;

      if (ref.current) ref.current.style.top = `${newDistanceFromTop}px`;
    };

    calculateDistanceFromTop();

    const loopParentAddEvent = node => {
      if (!node || node.id === 'root') return;
      if (node.scrollHeight > node.clientHeight) node.addEventListener('scroll', calculateDistanceFromTop);
      return loopParentAddEvent(node.parentNode);
    };
    const loopParentRemoveEvent = node => {
      if (!node || node.id === 'root') return;
      if (node.scrollHeight > node.clientHeight) node.removeEventListener('scroll', calculateDistanceFromTop);
      return loopParentRemoveEvent(node.parentNode);
    };

    loopParentAddEvent(scrollParent);

    return () => loopParentRemoveEvent(scrollParent);
  }, [parentRef, scrollParent]);

  const [categoryIndex, categoryItemIndex] = useMemo(() => {
    if (!isCategory) return [0, arrowSelectedIndex];

    let leftoverItemIndex = arrowSelectedIndex;

    for (const [idx, category] of items.entries()) {
      const isInThisCategory = category.items.length > leftoverItemIndex;
      if (isInThisCategory) return [idx, leftoverItemIndex];
      leftoverItemIndex -= category.items.length;
    }
    return [0, arrowSelectedIndex];
  }, [arrowSelectedIndex, isCategory, items]);

  useEffect(() => {
    if (ref.current && selectorMode === 'keyboard') {
      ref.current.scrollTop = arrowSelectedIndex * 50 - 50 + (categoryIndex + 1) * 30;
    }
  }, [categoryItemIndex, categoryIndex, selectorMode, arrowSelectedIndex]);

  return (
    <DropdownWrapper
      maxHeight={maxHeight}
      ref={ref}
      width={parentRef.current?.offsetWidth}
    >
      <RenderOptions
        categoryItemIndex={categoryItemIndex}
        categoryIndex={categoryIndex}
        isCategory={isCategory}
        items={items}
        {...rest}
      />
    </DropdownWrapper>
  );
};

const OptionsPropType = {
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      ItemPropType,
      PropTypes.shape({
        items: ItemPropType,
        category: CategoryPropType,
      }),
    ]),
  ).isRequired,
  onClick: PropTypes.func.isRequired,
  selectedItems: PropTypes.arrayOf(ItemPropType),
  checkbox: PropTypes.bool,
  startPaging: PropTypes.func,
  maxHeight: PropTypes.string,
  arrowSelectedIndex: PropTypes.number,
  selectorMode: PropTypes.oneOf(['mouse', 'keyboard']),
  onMouseOverItem: PropTypes.func,
  parentRef: PropTypes.shape(),
};

Options.propTypes = OptionsPropType;
RenderOptions.propTypes = {
  ...OptionsPropType,
  isCategory: PropTypes.bool,
};

export default Options;
