import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 100%;

  border-bottom: 2px solid transparent;
  transition: border-color 0.1s ease-in-out;
  padding: 0 8px;

  ${props =>
    !props.$isVertical
      ? css`
          &:first-child {
            padding: 0 8px 0 0;
          }
        `
      : css`
          padding-left: 10px;
          padding-right: 10px;
        `}

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  ${props =>
    props.$isVertical
      ? css`
          &:hover {
            background-color: ${props => transparentize(0.6, props.theme.bgColor.muted)};
          }
          &.active {
            background-color: ${props => transparentize(0.9, props.theme.bgColor.secondary)};
          }
        `
      : css`
          &:hover {
            border-bottom: 2px solid ${props => props.theme.bgColor.muted};
          }
          &.active {
            border-bottom: 2px solid ${props => props.theme.bgColor.secondary};
          }
        `}
`;

StyledNavLink.propTypes = {
  isVertical: PropTypes.bool,
  to: PropTypes.string,
  icon: PropTypes.node,
  label: PropTypes.string,
};

export default StyledNavLink;
