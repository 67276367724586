import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

function InternalLink({ children, url, mixpanelMessage, mixpanelConfig }) {
  const history = useHistory();

  return (
    <a
      onClick={e => {
        if (e.metaKey) return;
        e.preventDefault();
        mixpanel.track(mixpanelMessage, mixpanelConfig);
        history.push(url);
      }}
      href={url}
    >
      {children}
    </a>
  );
}

InternalLink.propTypes = {
  children: PropTypes.node.isRequired,
  url: PropTypes.string.isRequired,
  mixpanelMessage: PropTypes.string,
  mixpanelConfig: PropTypes.object,
};

export default InternalLink;
