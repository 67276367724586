import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isFuture from 'date-fns/isFuture';
import subMonths from 'date-fns/subMonths';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import getDay from 'date-fns/getDay';
import styled, { css } from 'styled-components';
import DropDownCaret from '~/assets/graphics/dropdown-caret.svg';
import Button from '../Button';
import Icon from '~/components/Icon';
import theme from '~/lib/theme';
import { HeaderYearText } from '../Text';

const Container = styled.div`
  display: flex;
`;
const Wrapper = styled.div`
  position: relative;
  z-index: ${props => props.theme.zIndex.input};
  display: flex;
  flex-flow: wrap;
  width: 100%;
  min-height: 40px;

  font-size: 16px;
  line-height: 14px;
  color: ${props => props.theme.darker};
  cursor: pointer;

  background-color: ${props => props.theme.lightest};
  border: 1px solid ${props => props.theme.lightMedium};
  border-radius: 5px;

  background-image: url(${DropDownCaret});
  background-size: 9px;
  background-repeat: no-repeat;
  background-position: right 8px top 50%;
  font-weight: 400;

  ${props =>
    !props.active &&
    css`
      &:hover {
        border-color: ${props => props.theme.copperLightest};
        color: ${props => props.theme.lightMedium};
      }
    `}

  ${props =>
    props.active &&
    css`
      outline: 2px solid ${props => props.theme.copperLightest};
      outline-offset: 0px;
      border-color: transparent;
    `}
`;

const MonthWrapper = styled(Wrapper)`
  min-width: 130px;
  margin-right: ${props => (props.withDays ? '10px' : '0')};
`;

const Value = styled.div`
  padding: 2px 22px 2px 11px;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
`;
const Calendar = styled.div`
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.bgColor.main};
  position: absolute;
  top: 40px;
  left: -9px;
  border: 1px solid ${props => props.theme.bgColor.muted};
  border-radius: 4px;
  cursor: default;
`;
const Header = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.bgColor.muted};
`;

const Body = styled.div`
  padding: 0px;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: ${props => (props.wide ? 'repeat(7, 1fr)' : 'repeat(3, 1fr)')};
  justify-content: start;
`;
const Item = styled.div`
  margin: 10px;
  padding: 0 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  width: ${props => (props.wide ? '25px' : '50px')};
  height: 25px;

  ${props =>
    props.disabled &&
    css`
      color: ${props => props.theme.lightMedium};
      pointer-events: none;

      &:hover {
        cursor: not-allowed;
      }
    `}

  ${props =>
    !props.disabled &&
    css`
      &:hover {
        cursor: pointer;
        background-color: ${props => props.theme.lighter};
        border-radius: 4px;
      }
    `}
`;

const Underlay = styled.div`
  position: fixed;
  z-index: ${props => props.theme.zIndex.input};
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
`;

const Divider = styled.hr`
  margin: 0px;
  color: ${props => props.theme.darkMedium};
  margin: 0px 10px;
`;

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const DAYS = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

function MonthPicker({
  withDays,
  value,
  onChange,
  minYear = 2018,
  minMonth = false,
  minDay = false,
  limitMonths = false,
}) {
  const today = new Date();
  const [year, month, day] = value.split('-').map(s => Number(s));
  const [currentYear, setCurrentYear] = useState(year);
  const [isOpen, setIsOpen] = useState(false);
  const [daysIsOpen, setDaysIsOpen] = useState(false);
  const [tempMonth, setTempMonth] = useState(month);
  const jsMonth = withDays ? tempMonth - 1 : month - 1;
  const days = [...Array(getDaysInMonth(new Date(year, jsMonth))).keys()].map(d => d + 1);
  const firstDayOfTheMonth = new Date(currentYear, jsMonth, 1);
  const indexOfFirstDayOfTheMonth = getDay(firstDayOfTheMonth);

  function SunSatToMonSunConverter(dayOfWeekNumber) {
    // getting empty days to put in the day picker before the 1st day of the month
    // passing as a prop is an index of a weekday 0 - 6 (sunday being 0)
    // and in the day picker the days start with Monday (sunday being 6)
    if (dayOfWeekNumber === 1) {
      // here prop is Monday so there should be 0 empty cells in the day picker
      return 0;
    }
    if (dayOfWeekNumber === 0) {
      // here prop is Sunday so there should be 6 empty cells in the day picker
      return 6;
    }
    // for the rest of the days we remove 1 and we get the number of the empty cells in the day picker
    return dayOfWeekNumber - 1;
  }

  if (limitMonths) {
    const limit = subMonths(today, limitMonths);
    minYear = limit.getFullYear();
    minMonth = limit.getMonth() + 1;
  }

  function setValue(newMonth) {
    setIsOpen(false);
    if (withDays) {
      setDaysIsOpen(true);
      setTempMonth(newMonth);
    } else {
      onChange(`${currentYear}-${newMonth.toString().padStart(2, '0')}`);
    }
  }

  function setDayValue(day) {
    onChange(
      `${currentYear}-${tempMonth.toString().padStart(2, '0')}${day ? `-${day.toString().padStart(2, '0')}` : ''}`,
    );
    setIsOpen(false);
    setDaysIsOpen(false);
  }

  function toggleMonths() {
    setIsOpen(!isOpen);
    setDaysIsOpen(false);
  }

  function toggleDays() {
    setDaysIsOpen(!daysIsOpen);
    setIsOpen(false);
  }

  const closePopup = useCallback(() => {
    setIsOpen(false);
    setDaysIsOpen(false);
  }, []);

  const onKeyDown = useCallback(
    e => {
      if (e.key === 'Escape') {
        closePopup();
      }
    },
    [closePopup],
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => document.removeEventListener('keydown', onKeyDown);
  }, [onKeyDown]);

  return (
    <>
      {(isOpen || daysIsOpen) && <Underlay onClick={closePopup} />}
      <Container>
        <MonthWrapper
          active={isOpen}
          withDays={withDays}
        >
          <Value onClick={toggleMonths}>
            {currentYear} - {MONTHS[jsMonth]}
          </Value>
          {isOpen && (
            <Calendar>
              <Header>
                <Button
                  icon
                  variant='primary'
                  disabled={minYear === currentYear}
                  onClick={() => setCurrentYear(currentYear - 1)}
                  width='40'
                >
                  <Icon
                    name='chevrons-left'
                    width={16}
                    height={16}
                    color={minYear === currentYear ? theme.darkMedium : theme.lightest}
                  />
                </Button>
                <HeaderYearText>{currentYear}</HeaderYearText>
                <Button
                  icon
                  variant='primary'
                  disabled={currentYear === today.getFullYear()}
                  onClick={() => setCurrentYear(currentYear + 1)}
                  width='40'
                >
                  <Icon
                    name='chevrons-right'
                    width={16}
                    height={16}
                    color={currentYear === today.getFullYear() ? theme.darkMedium : theme.lightest}
                  />
                </Button>
              </Header>
              <Body>
                {MONTHS.map((month, monthIdx) => (
                  <Item
                    key={monthIdx}
                    onClick={() => setValue(monthIdx + 1)}
                    disabled={
                      isFuture(new Date(currentYear, monthIdx)) || (minYear === currentYear && minMonth > monthIdx + 1)
                    }
                  >
                    {month}
                  </Item>
                ))}
              </Body>
            </Calendar>
          )}
        </MonthWrapper>

        {withDays && (
          <Wrapper active={daysIsOpen}>
            <Value onClick={toggleDays}>{day}</Value>
            {daysIsOpen && (
              <Calendar>
                <Body wide>
                  {DAYS.map(day => {
                    return (
                      <Item
                        key={day}
                        onClick={() => setDayValue(day)}
                        disabled
                        wide
                      >
                        {day}
                      </Item>
                    );
                  })}
                </Body>
                <Divider />
                <Body wide>
                  {Array(SunSatToMonSunConverter(indexOfFirstDayOfTheMonth)).fill(
                    <Item
                      key={day}
                      onClick={() => setDayValue(day)}
                      disabled
                      wide
                    />,
                  )}
                  {days.map(day => {
                    const isInTheFuture = isFuture(new Date(currentYear, jsMonth, day));
                    const disabled = isInTheFuture || (minYear === currentYear && minMonth === month && minDay > day);
                    return (
                      <Item
                        key={day}
                        onClick={() => setDayValue(day)}
                        disabled={disabled}
                        wide
                      >
                        {day}
                      </Item>
                    );
                  })}
                </Body>
              </Calendar>
            )}
          </Wrapper>
        )}
      </Container>
    </>
  );
}

MonthPicker.propTypes = {
  withDays: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  minYear: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  minMonth: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  minDay: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  limitMonths: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

export default MonthPicker;
