import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Loader from '~/components/Loader';
import InternalLink from '../InternalLink';
import mixpanel from 'mixpanel-browser';

const primaryBaseStyle = css`
  background-color: ${props => props.theme.copper};
  color: ${props => props.theme.lightest};
`;

const primary = css`
  ${primaryBaseStyle}

  &:not(:disabled) {
    &:hover,
    &:active {
      background-color: ${props => props.theme.copperLight};
    }
  }
`;

const tertiary = css`
  background-color: ${props => props.theme.lightest};
  border: 1px solid ${props => props.theme.darker};
  color: ${props => props.theme.darkest};

  &:not(:disabled) {
    &:hover,
    &:active {
      background-color: ${props => props.theme.lighter};
    }
  }
`;

const secondary = css`
  background-color: ${props => props.theme.lighter};
  color: ${props => props.theme.copperDarkest};

  &:not(:disabled) {
    &:hover,
    &:active {
      background-color: ${props => props.theme.copperLightest};
    }
  }
`;

const dark = css`
  background-color: ${props => props.theme.darker};
  color: ${props => props.theme.lightest};

  &:not(:disabled) {
    &:hover,
    &:active {
      background-color: ${props => props.theme.darkMedium};
    }
  }
`;

const danger = css`
  background-color: ${props => props.theme.danger};
  color: ${props => props.theme.lightest};

  &:not(:disabled) {
    &:hover,
    &:active {
      background-color: ${props => props.theme.dangerLight};
    }
  }
`;

const large = css`
  height: 40px;
`;

const iconButton = css`
  width: 30px;
  height: 30px;
  padding: 0;

  svg {
    height: 16px;
  }
`;

const width = css`
  min-width: unset;
  width: ${props => props.width};
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 6px 10px;
  font-size: 14px;
  white-space: nowrap;

  border: 0;
  border-radius: 4px;

  appearance: none;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &:not(:disabled) {
    ${props => props.variant === 'primary' && primary}
    ${props => props.variant === 'tertiary' && tertiary}
    ${props => props.variant === 'secondary' && secondary}
    ${props => props.variant === 'dark' && dark}
    ${props => props.variant === 'danger' && danger}
  }

  ${props => props.size === 'large' && large}

  ${props => props.icon && iconButton}
  ${props =>
    !props.icon &&
    css`
      min-width: 85px;
      width: fit-content;
    `}

  ${props => props.width && width}

  &:disabled {
    background-color: ${props => props.theme.light};
    cursor: not-allowed;
  }

  &:active:not(:disabled) {
    transform: scale(0.95);
  }

  ${props =>
    props.flexRight &&
    css`
      margin-left: auto;
    `}
  ${props =>
    props.flexLeft &&
    css`
      margin-right: auto;
    `}
`;

const LoaderWrapper = styled.div`
  position: absolute;
`;

const ChildrenWrapper = styled.div`
  opacity: 0;
`;

const Button = props => {
  const { isLoading, linkProps = {} } = props;
  const { url, method } = linkProps;
  const hasLink = !!url;

  const children = isLoading ? (
    <>
      <LoaderWrapper>
        <Loader size={30} />
      </LoaderWrapper>
      <ChildrenWrapper>{props.children}</ChildrenWrapper>
    </>
  ) : (
    props.children
  );

  if (hasLink && method === 'internal') {
    return (
      <InternalLink {...linkProps}>
        <StyledButton {...props}>{children}</StyledButton>
      </InternalLink>
    );
  }

  if (hasLink && method === 'external') {
    const hasMixpanel = !!linkProps.mixpanelMessage;

    const onClick = () => {
      if (hasMixpanel) {
        mixpanel.track(linkProps.mixpanelMessage, linkProps.mixpanelConfig);
      }
    };

    return (
      <StyledButton
        as='a'
        href={url}
        {...linkProps}
        {...props}
        onClick={onClick}
      >
        {children}
      </StyledButton>
    );
  }

  return <StyledButton {...props}>{children}</StyledButton>;
};

Button.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  linkProps: PropTypes.shape({
    url: PropTypes.string,
    target: PropTypes.string,
    method: PropTypes.oneOf(['internal', 'external']),
    mixpanelMessage: PropTypes.string,
    mixpanelConfig: PropTypes.object,
  }),
};

export default Button;

const ToggleButtonWrapper = styled.div`
  display: flex;
`;

const ToggleButtonButtonEl = styled.button`
  ${props => (props.variant === 'primary' ? primaryBaseStyle : secondary)}

  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 100%;
  padding: 6px 10px;
  font-size: 14px;
  white-space: nowrap;

  border: 0;
  border-radius: 4px;

  appearance: none;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const ToggleButton = ({ options, onChange, defaultButtonIndex }) => {
  const [selectedIndex, setSelectedIndex] = React.useState(defaultButtonIndex || 0);

  const onClick = index => {
    setSelectedIndex(index);
    onChange(options[index].value);
  };

  return (
    <ToggleButtonWrapper>
      {options.map(({ value, label }, index) => {
        const isSelected = index === selectedIndex;
        const variant = isSelected ? 'primary' : 'secondary';
        return (
          <ToggleButtonButtonEl
            key={value}
            variant={variant}
            onClick={() => onClick(index)}
            isSelected={isSelected}
          >
            {label}
          </ToggleButtonButtonEl>
        );
      })}
    </ToggleButtonWrapper>
  );
};

ToggleButton.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  defaultButtonIndex: PropTypes.number,
};

export { ToggleButton };
