import * as Sentry from '@sentry/browser';
import mixpanel from 'mixpanel-browser';
import { createRoot } from 'react-dom/client';
import RootContainer from './containers/RootContainer';

const isDevelopment = process.env.NODE_ENV === 'development';

mixpanel.init(
  process.env.MIXPANEL_PROJECT_TOKEN,
  {
    api_host: process.env.MIXPANEL_API_ENDPOINT,
  },
  '',
);

if (isDevelopment) mixpanel.disable();

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  enabled: process.env.NODE_ENV === 'production',
  release: process.env.RELEASE,
});

const root = createRoot(document.getElementById('root'));

root.render(<RootContainer />);
