import React, { useState, Suspense } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Title, Form } from '~/components/LoginElements';
import Icon from '~/components/Icon';
import theme from '~/lib/theme';

const StyledArrowRightIcon = styled.div`
  transition: transform 0.1s ease-in-out;
`;

const CardButton = styled.div`
  display: flex;
  height: 32px;
  padding: 0px 12px;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 9999px;
  border: 1px solid rgba(1, 6, 47, 0.17);
  cursor: pointer;
  width: 344px;
  color: #60646c;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.4px;
  transition: background-color 0.1s ease-in-out;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.2;
      cursor: not-allowed;
    `}

  &:hover {
    background-color: ${props => props.theme.bgColor.muted};
    cursor: pointer;
    & ${StyledArrowRightIcon} {
      transform: translateX(+5px);
    }
  }
`;

const CardButtonWrapper = styled.div`
  display: grid;
  gap: 16px;
  justify-content: center;
`;

function ChooseOrganization({ organizations, onClick }) {
  const [loading, setLoading] = useState(false);

  if (organizations.length === 1) {
    onClick(organizations[0].id);
    return;
  }

  if (organizations.length < 1 && !loading) {
    return;
  }

  return (
    <Form as='div'>
      <Title>Choose organization</Title>
      <CardButtonWrapper>
        {organizations.map(t => (
          <CardButton
            key={t.id}
            disabled={loading}
            onClick={() => {
              setLoading(true);
              onClick(t.id);
            }}
          >
            {t.name}
            <Suspense fallback={<p>loading...</p>}>
              <StyledArrowRightIcon>
                <Icon
                  name='arrow-right'
                  width={16}
                  height={16}
                  color={theme.darkMedium}
                />
              </StyledArrowRightIcon>
            </Suspense>
          </CardButton>
        ))}
      </CardButtonWrapper>
    </Form>
  );
}

ChooseOrganization.propTypes = {
  organizations: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
};

export default ChooseOrganization;
