export const organizationEventsFromApi = apiEventFlagsData => {
  if (!apiEventFlagsData) return {};

  const flags = {
    isSelfSignUp: apiEventFlagsData.is_self_sign_up,
    haveOrderedPhysicalSims: apiEventFlagsData.have_ordered_physical_sims,
    havePhysicalSimAssigned: apiEventFlagsData.have_physical_sim_assigned,
    havePhysicalSimActivated: apiEventFlagsData.have_physical_sim_activated,
    haveOrderedSoftsims: apiEventFlagsData.have_ordered_softsims,
    haveSoftsimAssigned: apiEventFlagsData.have_softsim_assigned,
    haveSoftsimActivated: apiEventFlagsData.have_softsim_activated,
    haveSoftsimBeenExported: apiEventFlagsData.have_softsim_been_exported,
  };

  return flags;
};

export const userEventsFromApi = apiEventFlagsData => {
  if (!apiEventFlagsData) return {};

  const flags = {
    shouldHaveSimsPageOnboardingFlow: apiEventFlagsData.should_have_sims_page_onboarding_flow,
  };

  return flags;
};
