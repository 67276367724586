import { useEffect } from 'react';
import useAuthentication from '~/hooks/use-authentication';
import useTiering from '~/hooks/use-tiering';
import { hotjar } from 'react-hotjar';

let hasLoaded = false; // Make sure that hotjar can only be loaded once

function useHotjar() {
  const { isLoggedIn, email, accessLevel } = useAuthentication();
  const { names, loading } = useTiering();

  useEffect(() => {
    const shouldLoad = isLoggedIn && !loading && !hasLoaded && process.env.HOTJAR_ID;
    if (!shouldLoad) return;

    const isOnomondian = email?.includes('onomondo.com');
    const shouldLoadForOnomondian = email?.includes('+test');
    if (isOnomondian && !shouldLoadForOnomondian) return;

    hasLoaded = true;
    hotjar.initialize(process.env.HOTJAR_ID, 6);
    hotjar.identify(email, {
      'Access Level': accessLevel,
      'Software Package': names?.[0] ?? null, // technically we can have multiple packages, but we only use the first one
    });
  }, [accessLevel, email, isLoggedIn, names, loading]);

  return hotjar;
}

export default useHotjar;
