import React from 'react';
import SiteWrapper from '~/components/SiteWrapper';
import Menu from '../Menu';
import CountriesService from '~/containers/CountryService';

function CountriesServicePage() {
  return (
    <SiteWrapper>
      <Menu />
      <CountriesService />
    </SiteWrapper>
  );
}

export default CountriesServicePage;
