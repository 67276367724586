import { StrictMode } from 'react';
import { ThemeProvider } from 'styled-components';
import Routes from '~/containers/Routes';
import GlobalStyles from '~/components/GlobalStyles';
import theme from '~/lib/theme';

function RootContainer() {
  return (
    <StrictMode>
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyles />
          <Routes />
        </>
      </ThemeProvider>
    </StrictMode>
  );
}

export default RootContainer;
