import mixpanel from 'mixpanel-browser';
import { useCallback, useEffect, useState, createContext } from 'react';

import { useLocation } from 'react-router-dom';
import useAuthentication from '~/hooks/use-authentication';
import useFeatureFlags from '~/hooks/use-feature-flags';
import useInvoices from '~/hooks/use-invoices';
import useNotification from '~/hooks/use-notification';
import differenceInDays from 'date-fns/differenceInDays';

const AnnouncementContext = createContext();

const getAnnouncementsFromLocalStorage = () => {
  const announcements = localStorage.getItem('announcements');
  return announcements ? JSON.parse(announcements) : {};
};

const ANNOUNCEMENTS = [
  {
    id: 'invoices',
    coolDownDays: 10,
  },
];

function checkIfAnnouncementIsDue(announcement, lastAnnouncement) {
  const hasAnnouncementCoolDown = !!announcement.coolDownDays;
  const hasLastAnnouncement = !!lastAnnouncement;

  if (!hasAnnouncementCoolDown || !hasLastAnnouncement) return true;

  return differenceInDays(new Date(), new Date(lastAnnouncement));
}

function AnnouncementProvider(props) {
  const location = useLocation();
  const invoices = useInvoices();
  const { isOwner } = useAuthentication();
  const { addNotification } = useNotification();
  const [announcementsData, setAnnouncementsData] = useState(getAnnouncementsFromLocalStorage());
  const { shouldSuppressInvoiceNotification } = useFeatureFlags();

  const checkInvoices = useCallback(() => {
    setAnnouncementsData(prev => {
      const newAnnouncementsData = { ...prev, invoices: new Date() };
      localStorage.setItem('announcements', JSON.stringify(newAnnouncementsData));
      return newAnnouncementsData;
    });

    const outstandingAmount = invoices.data.reduce((acc, invoice) => {
      if (invoice.status === 'outstanding') return acc + 1;
      return acc;
    }, 0);
    if (outstandingAmount > 0) {
      mixpanel.track('Show outstanding invoice notification', { amount: outstandingAmount });
      addNotification({
        type: 'warning',
        mode: 'button',
        message: `There are outstanding invoices associated with your Onomondo account.`,
        buttonHref: '/invoices',
        buttonLabel: 'Invoice overview',
      });
    }

    const overdueAmount = invoices.data.reduce((acc, invoice) => {
      if (invoice.status === 'overdue') return acc + 1;
      return acc;
    }, 0);
    if (overdueAmount > 0) {
      mixpanel.track('Show overdue invoice notification', { amount: overdueAmount });
      addNotification({
        type: 'error',
        mode: 'button',
        message: 'There are overdue invoices associated with your Onomondo account.',
        buttonHref: '/invoices',
        buttonLabel: 'Invoice overview',
      });
    }
  }, [addNotification, invoices.data]);

  const runAnnouncement = useCallback(
    announcementId => {
      if (announcementId === 'invoices') {
        if (!isOwner) return;
        if (shouldSuppressInvoiceNotification) return;
        if (!invoices.loading) return checkInvoices();
        setTimeout(() => runAnnouncement(announcementId), 1000);
      }
    },
    [checkInvoices, shouldSuppressInvoiceNotification, invoices.loading, isOwner],
  );

  useEffect(() => {
    for (const announcement of ANNOUNCEMENTS) {
      const lastAnnouncement = announcementsData[announcement.id];
      if (checkIfAnnouncementIsDue(announcement, lastAnnouncement)) {
        runAnnouncement(announcement.id);
      }
    }
  }, [announcementsData, location.pathname, runAnnouncement]);

  return (
    <AnnouncementContext.Provider
      value={{}}
      {...props}
    />
  );
}

export { AnnouncementProvider, AnnouncementContext };
