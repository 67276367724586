import { Parser as BinaryParser } from 'binary-parser';

const ipHeader = new BinaryParser()
  .endianess('big')
  .bit4('version')
  .bit4('headerLength')
  .uint8('tos')
  .uint16('length')
  .uint16('identification')
  .bit3('offset')
  .bit13('fragmentOffset')
  .uint8('ttl')
  .uint8('protocol')
  .uint16('checksum')
  .array('src', {
    type: 'uint8',
    length: 4,
  })
  .array('dst', {
    type: 'uint8',
    length: 4,
  })
  .buffer('data', {
    readUntil: 'eof',
  });

export default buffer => {
  const parsed = ipHeader.parse(buffer);
  parsed.sourceIp = parsed.src.join('.');
  parsed.destinationIp = parsed.dst.join('.');

  return parsed;
};
