import useAuthentication from '~/hooks/use-authentication';
import useOrganizations from './use-organizations';

export default useFeatureFlags;

function useFeatureFlags() {
  const { usersCurrentOrganizationId, assumedOrganizationId, usersOrganizations, isViewingAsAssumedOrganization } =
    useAuthentication();
  const organizations = useOrganizations();

  const isWantingToViewAsDifferentOrganization =
    isViewingAsAssumedOrganization && organizations.data && assumedOrganizationId;
  const organization = !isWantingToViewAsDifferentOrganization
    ? usersOrganizations?.find(o => o.id === usersCurrentOrganizationId)
    : organizations.data?.find(o => o.id === assumedOrganizationId);

  return organization?.featureFlags || {};
}
