import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSpinner = styled.svg`
  animation: rotate 1s linear infinite;
  width: ${props => props.size ?? 20}px;
  height: ${props => props.size ?? 20}px;

  & .path {
    stroke: ${props => props.color ?? props.theme.bgColor.secondary};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

function Spinner({ size, color }) {
  return (
    <StyledSpinner
      viewBox='0 0 50 50'
      size={size}
      color={color}
    >
      <circle
        className='path'
        cx='25'
        cy='25'
        r='20'
        fill='none'
        strokeWidth='2'
      />
    </StyledSpinner>
  );
}

Spinner.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default Spinner;
