import React, { Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import SiteLoading from '~/containers/SiteLoading';
import CheckAccess from '~/containers/CheckAccess';
import Navigation from '~/containers/Navigation';
import Login from '~/pages/Login';
import Logout from '~/pages/Logout';
import SignUp from '~/pages/SignUp';
import PrivateRoute from '~/components/PrivateRoute';
import PrivateRoutes from './PrivateRoutes';
import CommandMenu from '~/components/CommandMenu';

const SetPassword = lazy(() => import(/* webpackChunkName: "setpassword" */ '~/pages/SetPassword'));

function Routes() {
  return (
    <Suspense fallback={<SiteLoading />}>
      <Switch>
        <Route path='/sign-up'>
          <SignUp />
        </Route>

        <Route path='/login'>
          <Login />
        </Route>

        <Route path='/logout'>
          <Logout />
        </Route>

        <Route path='/reset-password'>
          <SetPassword />
        </Route>

        <PrivateRoute to='/'>
          <CheckAccess>
            <CommandMenu />
            <Navigation />
            <PrivateRoutes />
          </CheckAccess>
        </PrivateRoute>
      </Switch>
    </Suspense>
  );
}

export default Routes;
