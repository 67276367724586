import { networkFromApi } from './network';

export function toApi(sim, isOrgMember, hasFeature) {
  return {
    id: sim.id,
    label: sim.label || null,
    activated: sim.activated,
    is_softsim: sim.isSoftSim || false,
    connector: sim.connector || null,
    network_whitelist: sim.networkWhitelist || null,
    imei_lock: sim.imeiLock || null,
    price_plan_alias: sim.pricePlan || null,
    tags: isOrgMember ? undefined : sim.tags.map(t => t.value || t.id),
    data_limit:
      sim.dataLimitTotal && sim.dataLimitType
        ? {
            total: hasFeature('sims.dataLimit') ? sim.dataLimitTotal : undefined,
            type: hasFeature('sims.dataLimit') ? sim.dataLimitType : undefined,
            alert_threshold: hasFeature('management.webhooks') ? sim.dataLimitAlertThreshold || null : undefined,
          }
        : undefined,
    technologies: sim.technologies,
    tech_fee: sim.techFee || null,
  };
}

export function fromApi(sim, isOrgMember) {
  const tags = sim.tags
    .map(t => ({
      name: t.name,
      id: t.id,
      canWrite: t.can_write,
      color: t.color,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
  return {
    id: sim.id,
    label: sim.label || '',
    activated: sim.activated,
    connector: sim.connector || '',
    iccid: sim.iccid,
    imeiLock: sim.imei_lock || '',
    imei: sim.imei || '',
    imsi: sim.imsi || '',
    isSoftSim: sim.is_softsim || false,
    msisdn: sim.msisdn || '',
    deviceInfo: sim.device_info,
    ipv4: sim.ipv4,
    networkWhitelist: sim.network_whitelist || '',
    usage: sim.usage,
    pricePlan: sim.price_plan_alias || '',
    online: sim.online,
    onlineAt: sim.online_at,
    lastCameOnlineAt: sim.last_came_online_at,
    network: networkFromApi(sim.network),
    location: sim.location
      ? {
          lat: sim.location.lat,
          lng: sim.location.lng,
          cellId: sim.location.cell_id,
          locationAreaCode: sim.location.location_area_code,
          accuracy: sim.location.accuracy,
        }
      : {},
    tags,
    dataLimit: sim.data_limit
      ? {
          used: sim.data_limit.used,
          total: sim.data_limit.total,
          type: sim.data_limit.type,
          period: sim.data_limit.period,
          resetsAt: sim.data_limit.resets_at,
          alertThreshold: sim.data_limit.alert_threshold,
        }
      : {},
    dataLimitType: sim?.data_limit?.type,
    dataLimitTotal: sim?.data_limit?.total,
    dataLimitAlertThreshold: sim?.data_limit?.alert_threshold,
    canEdit: isOrgMember ? !!tags.find(t => t.canWrite) : true,
    technologies: sim.technologies,
    checked: false,
    techFee: sim.tech_fee || '',
    apn: sim.apn_profile,
  };
}
