import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  &:first-child {
    margin-left: 0px;
  }

  margin-left: 10px;
`;

const HorizontalList = styled.div`
  display: flex;
`;

function Menu() {
  return (
    <div>
      <HorizontalList>
        <StyledLink to='/admin/organizations'>Organizations</StyledLink>
        <StyledLink to='/admin/pricelists'>Pricelists</StyledLink>
        <StyledLink to='/admin/network-service'>Network Service</StyledLink>
        <StyledLink to='/admin/countries-service'>Countries Service</StyledLink>
      </HorizontalList>
    </div>
  );
}

export default Menu;
