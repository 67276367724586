import React from 'react';
import PropTypes from 'prop-types';
import { ContinueBtn } from './components/Elements';
import SimpleInner from './components/SimpleInner';

function LinkExpired({ nextStep }) {
  return (
    <>
      <SimpleInner
        icon='expired-link'
        title='Expired link'
        subTitle='Looks like your verification link has expired. Not to worry, we can send you a new one.'
      />
      <ContinueBtn onClick={nextStep}>Send new link</ContinueBtn>
    </>
  );
}

LinkExpired.propTypes = {
  nextStep: PropTypes.func,
};

export default LinkExpired;
