import { useState, useEffect, useCallback } from 'react';
import useAuthentication from './use-authentication';
import useNotification from '~/hooks/use-notification';

const { default: api, useConfig } = require('~/lib/api');

const useInvoices = () => {
  const { assumedOrganizationId, isOwner, isDemiGod, isGod } = useAuthentication();
  const getConfig = useConfig(assumedOrganizationId);
  const { addNotification } = useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [customerData, setCustomerData] = useState(null);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [updateEmailLoading, setUpdateEmailLoading] = useState(false);

  const canSeeInvoices = isOwner || isDemiGod || isGod;

  const getInvoices = useCallback(async () => {
    if (!canSeeInvoices) return;
    setLoading(true);
    const { data } = await api.get('/billing/invoices/', getConfig());
    setData(data);
    setLoading(false);
  }, [canSeeInvoices, getConfig]);

  const getCustomerData = useCallback(async () => {
    if (!canSeeInvoices) return;
    setCustomerLoading(true);
    const { data } = await api.get(`/billing/customer-information`, getConfig());
    setCustomerData(data);
    setCustomerLoading(false);
  }, [canSeeInvoices, getConfig]);

  const updateEmail = async email => {
    if (!canSeeInvoices) return;
    setUpdateEmailLoading(true);
    try {
      await api.patch(`/billing/customer-information`, { email }, getConfig());
      setCustomerData(prev => ({ ...prev, email }));
      addNotification({
        type: 'success',
        message: `The e-mail was successfully updated!`,
        expire: 10000,
      });
      setUpdateEmailLoading(false);
      return true;
    } catch (e) {
      addNotification({
        type: 'error',
        message: `Update email failed. Error code: ${e.response?.status || 'Unknown error code'}. Error: ${
          e.response?.data?.error || 'Unknown error'
        }.`,
        expire: 10000,
      });
      setUpdateEmailLoading(false);
      return false;
    }
  };

  useEffect(() => {
    getInvoices();
  }, [getInvoices, assumedOrganizationId]);

  useEffect(() => {
    getCustomerData();
  }, [getCustomerData, assumedOrganizationId]);

  return {
    data,
    loading,
    customerData,
    customerLoading,
    updateEmail,
    updateEmailLoading,
  };
};

export default useInvoices;
