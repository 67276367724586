import styled, { css, keyframes } from 'styled-components';
import { transparentize } from 'polished';
import PropTypes from 'prop-types';

const fade = keyframes`
  0% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const StatusIndicator = styled.div`
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  ${({ type }) =>
    type === 'online' &&
    css`
      background-color: ${({ theme }) => theme.bgColor.good};
    `};
  ${({ type }) =>
    type === 'offline' &&
    css`
      background-color: ${({ theme }) => theme.bgColor.muted};
    `};
  ${({ type }) =>
    type === 'operational' &&
    css`
      background-color: ${({ theme }) => theme.bgColor.good};
    `};
  ${({ type }) =>
    type === 'degraded_performance' &&
    css`
      background-color: yellow;
    `};
  ${({ type }) =>
    type === 'partial_outage' &&
    css`
      background-color: orange;
    `};
  ${({ type }) =>
    type === 'major_outage' &&
    css`
      background-color: red;
    `};
  ${({ type }) =>
    type === 'under_maintenance' &&
    css`
      background-color: blue;
    `};

  ${props =>
    props.animated &&
    css`
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        content: '';
        animation: ${fade} 2s linear infinite;
        ${({ type }) =>
          type === 'online' &&
          css`
            box-shadow: 0 0 0 3px ${({ theme }) => theme.bgColor.good};
          `};
        ${({ type }) =>
          type === 'offline' &&
          css`
            box-shadow: 0 0 0 3px ${({ theme }) => theme.bgColor.muted};
          `};
        ${({ type }) =>
          type === 'operational' &&
          css`
            box-shadow: 0 0 0 3px ${({ theme }) => theme.bgColor.good};
          `};
        ${({ type }) =>
          type === 'degraded_performance' &&
          css`
            box-shadow: 0 0 0 3px ${transparentize(0.6, 'yellow')};
          `};
        ${({ type }) =>
          type === 'partial_outage' &&
          css`
            box-shadow: 0 0 0 3px ${transparentize(0.6, 'orange')};
          `};
        ${({ type }) =>
          type === 'major_outage' &&
          css`
            box-shadow: 0 0 0 3px ${transparentize(0.6, 'red')};
          `};
        ${({ type }) =>
          type === 'under_maintenance' &&
          css`
            box-shadow: 0 0 0 3px ${transparentize(0.6, 'blue')};
          `};
      }
    `}
`;

StatusIndicator.propTypes = {
  animated: PropTypes.bool,
  type: PropTypes.oneOf([
    'online',
    'offline',
    'operational',
    'degraded_performance',
    'partial_outage',
    'major_outage',
    'under_maintenance',
  ]).isRequired,
};

export default StatusIndicator;
