import styled, { css } from 'styled-components';
import TextInput from './TextInput';

const TextArea = styled(TextInput)`
  height: 100px;
  border-radius: 5px;
  display: block;
  resize: vertical;

  ${props =>
    props.error &&
    css`
      border-color: ${props => props.theme.danger};
    `}

  &:focus {
    border-color: transparent;
  }

  &::placeholder {
    color: ${props => props.theme.lightMedium};
  }

  &:disabled {
    color: ${props => props.theme.lightMedium};
    background-color: ${props => props.theme.lighter};
    border-color: ${props => props.theme.lightMedium};
    resize: none;
    cursor: auto;
  }
`;

export default TextArea;
