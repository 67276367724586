import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ItemPropType } from './Items';
import { TagLabel } from '~/components/Text';
import Icon from '~/components/Icon';

export const Tag = styled.div`
  height: 28px;
  display: inline-flex;
  align-items: center;
  min-height: 2rem;
  max-width: 100%;
  padding: 0 8px;
  margin: 2px 11px 2px 0;
  background-color: ${props => props.theme.lighter};
  color: ${props => props.theme.font.main};
  border-radius: 0.25rem;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'default')};

  transition: background-color 0.2s ease-in-out;

  ${props =>
    props.indeterminate &&
    css`
      background: transparent;
      border: 1px solid ${props => props.theme.lightMedium};
    `}

  &:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const StyledTagLabel = styled(TagLabel)`
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const IconWrapper = styled.div`
  cursor: pointer;
  height: 10px;
  width: 10px;
  margin-left: 8px;
`;

const Tags = ({ items, disabled, onRemove }) => {
  return items.map(item => (
    <Tag
      key={item.value}
      disabled={disabled}
      indeterminate={item.indeterminate}
      onClick={e => e.stopPropagation()}
    >
      <StyledTagLabel className='tagLabel'>{item.label}</StyledTagLabel>
      {!disabled && (
        <IconWrapper
          onClick={e => {
            e.stopPropagation();
            onRemove(item);
          }}
        >
          <Icon
            name='close'
            width={10}
            height={10}
          />
        </IconWrapper>
      )}
    </Tag>
  ));
};

Tags.propTypes = {
  items: PropTypes.arrayOf(ItemPropType),
  disabled: PropTypes.bool,
  onRemove: PropTypes.func,
};

export default Tags;
