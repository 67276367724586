import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Tooltip from '~/components/Tooltip';
import StatusIndicator from '~/components/StatusIndicator';

const Wrapper = styled.a`
  text-decoration: none !important;
  z-index: ${props => props.theme.zIndex.navbar};
`;
const StatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & + & {
    margin-top: 10px;
  }
`;
const StyledStatusIndicator = styled(StatusIndicator)`
  margin-left: 10px;
`;

function HealthStatus({ children, overall, network, webhooks, api, app }) {
  return (
    <Wrapper
      href='https://status.onomondo.com'
      target='_blank'
    >
      <Tooltip
        width={200}
        title={
          <>
            {children}
            <StyledStatusIndicator
              type={overall}
              animated
            />
          </>
        }
      >
        <StatusWrapper title={network}>
          Network
          <StatusIndicator
            type={network}
            animated
          />
        </StatusWrapper>
        <StatusWrapper title={webhooks}>
          Webhooks
          <StatusIndicator
            type={webhooks}
            animated
          />
        </StatusWrapper>
        <StatusWrapper title={api}>
          API
          <StatusIndicator
            type={api}
            animated
          />
        </StatusWrapper>
        <StatusWrapper title={app}>
          App
          <StatusIndicator
            type={app}
            animated
          />
        </StatusWrapper>
      </Tooltip>
    </Wrapper>
  );
}

HealthStatus.propTypes = {
  children: PropTypes.node,
  overall: PropTypes.oneOf([
    'operational',
    'degraded_performance',
    'partial_outage',
    'major_outage',
    'under_maintenance',
  ]).isRequired,
  network: PropTypes.oneOf([
    'operational',
    'degraded_performance',
    'partial_outage',
    'major_outage',
    'under_maintenance',
  ]).isRequired,
  webhooks: PropTypes.oneOf([
    'operational',
    'degraded_performance',
    'partial_outage',
    'major_outage',
    'under_maintenance',
  ]).isRequired,
  api: PropTypes.oneOf(['operational', 'degraded_performance', 'partial_outage', 'major_outage', 'under_maintenance'])
    .isRequired,
  app: PropTypes.oneOf(['operational', 'degraded_performance', 'partial_outage', 'major_outage', 'under_maintenance'])
    .isRequired,
};

export default HealthStatus;
