import { useContext } from 'react';
import { FeatureContext } from '~/context/feature';

function useTiering() {
  const context = useContext(FeatureContext);

  if (context === undefined) {
    throw new Error('useTiering must be used within a FeatureProvider');
  }

  return context;
}

export default useTiering;
