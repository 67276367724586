import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { HexColorPicker } from 'react-colorful';
import useOnClickOutside from '~/hooks/use-on-click-outside';
import useKeyPress from '~/hooks/use-key-press';
import TextInput from './TextInput';
import { Card, CardContent } from '~/components/Card';
import { Label } from '~/components/Text';
import Icon from '~/components/Icon';

const Wrapper = styled.div`
  position: relative;
  width: 235px;
  margin-top: 10px;
  margin-bottom: 20px;
  cursor: pointer;
`;

const RenderWrapper = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px solid ${props => props.theme.lightMedium};
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  margin-right: 5px;
`;

const Button = styled.div`
  margin: 0 6px 0 5px;
  width: 20px;
  height: 20px;
  background-color: ${props => props.color};
`;

const PickerWrapper = styled.div`
  width: 245px;
  position: absolute;
  top: 35px;
  left: 118px;
  transform: translateX(-50%);
  z-index: ${props => props.theme.zIndex.default};
  border-radius: 5px;
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.1);
`;

const StyledTextInput = styled(TextInput)`
  width: 225px;
  margin-top: 5px;
`;

const StyledCardContent = styled(CardContent)`
  align-items: center;
  & .react-colorful {
    width: 225px;
  }
`;

function Color({ color, onChange }) {
  const [isOpen, setIsOpen] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setIsOpen(false));
  useKeyPress('Escape', () => setIsOpen(false));

  return (
    <Wrapper ref={node}>
      <RenderWrapper onClick={() => setIsOpen(!isOpen)}>
        <LabelWrapper>
          <Button color={color} />
          <Label>Select color</Label>
        </LabelWrapper>
        <IconWrapper>
          <Icon
            name='chevron-down'
            height={10}
            width={10}
          />
        </IconWrapper>
      </RenderWrapper>
      {isOpen && (
        <PickerWrapper>
          <Card>
            <StyledCardContent>
              <HexColorPicker
                color={color}
                onChange={onChange}
              />
              <StyledTextInput
                value={color}
                onChange={e => onChange(e.target.value)}
              />
            </StyledCardContent>
          </Card>
        </PickerWrapper>
      )}
    </Wrapper>
  );
}

Color.propTypes = {
  onChange: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
};

export default Color;
