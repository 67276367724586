import { useState, useEffect, useRef, useCallback } from 'react';
import useAuthentication from '~/hooks/use-authentication';
import useDebounce from '~/hooks/use-debounce';
import api, { useConfig } from '~/lib/api';

function fromApi(item) {
  return {
    value: item.id,
    label: item.name || item.id,
  };
}

function removeDuplicates(items) {
  const seen = [];

  const unique = items.filter(item => {
    const isDuplicate = seen.includes(item.value);
    if (isDuplicate) return false;

    seen.push(item.value);
    return true;
  });

  return unique;
}

function useTagsSearch(resource, organizationId, shouldAddNoneOption = false) {
  const { isLoggedIn, assumedOrganizationId } = useAuthentication();
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [search, setSearch] = useState('');
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const prevSearch = useRef();
  const debouncedSearch = useDebounce(search, 400);
  const getConfig = useConfig(organizationId || assumedOrganizationId);
  const limit = 20;

  const searchApi = useCallback(async () => {
    setLoading(true);
    const searchHasChanged = prevSearch.current !== search;
    if (searchHasChanged) {
      prevSearch.current = search;
      setOffset(0);
    }
    const { data } = await api.get(
      `/tags/search/${resource}`,
      getConfig({
        params: { limit, offset, search },
      }),
    );
    setTotal(data.total);
    setItems(prevItems => {
      const result = data.result.map(fromApi);
      shouldAddNoneOption && result.unshift({ value: null, label: 'None' });
      const newItems = !searchHasChanged ? prevItems.concat(result) : result;
      return removeDuplicates(newItems);
    });
    setLoading(false);
  }, [search, offset, limit, getConfig, resource]);

  useEffect(() => {
    searchApi();
  }, [searchApi, isLoggedIn, debouncedSearch, offset]);

  return {
    loading,
    items,
    search,
    setSearch,
    startPaging() {
      const nextOffset = offset + limit;
      if (nextOffset >= total) return;
      setOffset(nextOffset);
    },
    canSearch: true,
  };
}

export default useTagsSearch;
