import { useState, useEffect } from 'react';

function useIsVisible(callback) {
  const [node, setNode] = useState(null);
  useEffect(() => {
    if (!callback) return;
    if (!node) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) return;
        callback();
      },
      { root: null, rootMargin: '0px', threshold: 0 },
    );

    observer.observe(node);

    return () => observer.disconnect();
  }, [callback, node]);
  return setNode;
}

export default useIsVisible;
