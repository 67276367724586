import React from 'react';
import PropTypes from 'prop-types';
import { SimpleInnerContainer, StyledTextWrapper } from './Elements';
import Icon from '~/components/Icon';
import { SignUpCardTitle, SignUpCardSubtitle } from '~/components/Text';

const SimpleInner = ({ icon, title, subTitle }) => {
  return (
    <SimpleInnerContainer>
      <Icon
        name={icon}
        height={115}
        width={115}
        color={null}
        inline
      />
      <StyledTextWrapper>
        <SignUpCardTitle>{title}</SignUpCardTitle>
        <SignUpCardSubtitle>{subTitle}</SignUpCardSubtitle>
      </StyledTextWrapper>
    </SimpleInnerContainer>
  );
};

SimpleInner.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

export default SimpleInner;
