import styled, { css, keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const success = css`
  color: ${props => props.theme.darker};
  background-color: ${props => props.theme.success};
`;
const error = css`
  color: ${props => props.theme.copperDarkest};
  background-color: ${props => props.theme.error};
`;
const normal = css`
  color: ${props => props.theme.darker};
  background-color: ${props => props.theme.lighter};
`;

const Notification = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  min-height: 40px;

  padding: 5px 20px;
  margin: 8px 0;
  border-radius: 2px;
  box-shadow: 2px 1px 12px 3px rgba(0, 0, 0, 0.2);

  animation: ${fadeIn} 0.1s linear;

  font-size: 0.9em;

  &:hover {
    cursor: pointer;
  }

  ${normal}

  ${props => props.type === 'success' && success}
  ${props => props.type === 'error' && error}
  ${props => props.type === 'normal' && normal}
`;

export default Notification;
