import { networkFromApi } from './network';

export function fromApi(doc) {
  if (doc.log_type === 'network-registration') {
    return {
      ...parseNr(doc),
      logType: doc.log_type,
    };
  }
  if (doc.log_type === 'network-authentication') {
    return {
      ...parseNa(doc),
      logType: doc.log_type,
    };
  }
  if (doc.log_type === 'sim-event') {
    return {
      ...parseSe(doc),
      logType: doc.log_type,
    };
  }
  console.warn('Unknown log_type found for network log:', doc.log_type);
}

function parseNr(networkRegistration) {
  return {
    id: networkRegistration.id,
    type: networkRegistration.type,
    time: networkRegistration.time,
    simId: networkRegistration.sim_id,
    iccid: networkRegistration.iccid,
    imei: networkRegistration.imei,
    imeiLock: networkRegistration.imeiLock,
    networkType: networkRegistration.network_type,
    countryCode: networkRegistration.country_code,
    network: networkFromApi(networkRegistration.network),
    isDataAttach: networkRegistration.is_data_attach,
    sessionBytes: networkRegistration.session_bytes,
  };
}

function parseNa(networkAuthentication) {
  return {
    id: networkAuthentication.id,
    time: networkAuthentication.time,
    simId: networkAuthentication.sim_id,
    iccid: networkAuthentication.iccid,
    imei: networkAuthentication.imei,
    imeiLock: networkAuthentication.imeiLock,
    countryCode: networkAuthentication.country_code,
    network: networkFromApi(networkAuthentication.network),
    reason: networkAuthentication.reason,
    status: networkAuthentication.status,
    networkType: networkAuthentication.network_type,
  };
}
function parseSe(simEvent) {
  return {
    time: simEvent.time,
    simId: simEvent.sim_id,
    event: simEvent.event,
    meta: simEvent.meta,
  };
}
