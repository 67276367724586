import * as Sentry from '@sentry/browser';
const MEGABYTE_IN_BYTES = 1000 * 1000;
const MEBIBYTE_IN_BYTES = 1024 * 1024;

const SIZES = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const MULTIPLIER = 1000;

function randomInRange(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

function bytesToSize(bytes) {
  if (isNaN(bytes) || bytes === 0 || !bytes) return '0 B';

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(MULTIPLIER)), 10);
  if (i === 0) return `${bytes} ${SIZES[i]}`;
  return `${(bytes / MULTIPLIER ** i).toFixed(1)} ${SIZES[i]}`;
}

function numberToEUR(number) {
  const n = number.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  return `€ ${n}`;
}

function numberToDKK(number) {
  const n = number.toLocaleString('da-DK', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  return `DKK ${n}`;
}

function numberToUSD(number) {
  const n = number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  return `$ ${n}`;
}

function numberToCurrency(number, currency) {
  switch (currency) {
    case 'dkk':
      return numberToDKK(number / 100);
    case 'usd':
      return numberToUSD(number / 100);
    case 'eur':
      return numberToEUR(number / 100);
    default:
      Sentry.withScope(scope => {
        scope.setExtra('currency', currency);
        scope.setExtra('number', number);
        Sentry.captureException('Missing formatter for currency.');
      });
      return number;
  }
}

export {
  MEBIBYTE_IN_BYTES,
  MEGABYTE_IN_BYTES,
  randomInRange,
  bytesToSize,
  numberToEUR,
  numberToDKK,
  numberToUSD,
  numberToCurrency,
};
