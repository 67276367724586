import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useOnClickOutside from '~/hooks/use-on-click-outside';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import DropDownCaret from '~/assets/graphics/dropdown-caret.svg';
import Options, { ItemPropType } from './Items';
import Tags from './Tags';

export const SelectWrapper = styled.div`
  position: relative;
  width: ${props => props.width || '100%'};
`;

export const SelectEl = styled.div`
  display: flex;
  flex-flow: wrap;
  width: 100%;
  min-height: 40px;
  padding: 2px 22px 2px 11px;

  background-color: ${props => props.theme.lightest};
  border: 1px solid ${props => (props.error ? props.theme.danger : props.theme.lightMedium)};
  border-radius: 5px;

  font-size: 12px;
  line-height: 14px;
  color: ${props => props.theme.darker};

  cursor: pointer;

  &:focus {
    outline: 2px solid ${props => props.theme.copperLightest};
    border-color: transparent;
  }

  ${props =>
    props.isOpen &&
    css`
      border-color: ${props => props.theme.copperLightest};
    `}

  ${props =>
    !props.icon &&
    css`
      background-image: url(${DropDownCaret});
      background-size: 9px;
      background-repeat: no-repeat;
      background-position: ${props.maxHeight ? 'right 20px top 50%' : 'right 8px top 50%'};
    `}

  ${props =>
    props.icon &&
    css`
      background-image: url(${props => props.icon}), url(${DropDownCaret});
      background-position: ${props.maxHeight ? '10px 8px, right 20px center' : '10px 8px, right 7px center'};
      background-repeat: no-repeat, no-repeat;
      padding-left: 42px;
    `}

  ${props =>
    props.canSearch &&
    css`
      cursor: text;
    `}

  ${props =>
    props.disabled &&
    css`
      background-color: ${props => props.theme.lighter};
      border-color: ${props => props.theme.light};
      color: ${props => props.theme.lightMedium};
      cursor: not-allowed;
    `}

  ${props =>
    props.maxHeight &&
    css`
      max-height: ${props => props.maxHeight}px;
      overflow-y: scroll;
    `}
`;

export const SelectElLabel = styled.p`
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${props =>
    props.canSearch &&
    css`
      margin-top: 1px;
      margin-right: 2px;
      padding-right: 2px;
    `}

  ${props =>
    props.placeholder &&
    css`
      color: ${props => props.theme.lightMedium};
    `}
`;

const SearchInput = styled.input`
  flex-shrink: 1;
  border: unset;
  outline: none;
  min-width: 50px;
  height: 24px;
  margin: 5px 0;
  padding: 0;

  &:placeholder-shown {
    text-overflow: ellipsis;
  }
`;

const SingleSearchInput = styled(SearchInput)`
  flex-grow: 1;
  max-width: unset;

  ${props =>
    props.anyItemSelected &&
    css`
      &::placeholder {
        color: ${props => props.theme.darker};
      }

      &:focus::placeholder {
        color: transparent;
      }
    `}
`;

const TagListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  min-height: 44px;

  border: 1px solid ${props => props.theme.lightMedium};
  border-radius: 5px 5px 0 0;
  ${props =>
    props.isOpen &&
    css`
      border-color: ${props => props.theme.copperLightest};
    `}

  margin-bottom: -5px;
  padding: 5px;
`;

const Select = React.forwardRef(
  (
    {
      items = [],
      onChange,
      onSelect,
      onDeselect,
      mode = 'single',
      tagsMode = 'default',
      canSearch,
      value,
      hideOptionIfSelected,
      startPaging: localStartPaging,
      setSearch,
      loading,
      width,
      optionsWrapperProps,
      checkbox,
      ...rest
    },
    ref,
  ) => {
    const { disabled, placeholder, searchFilterSelect, ellipsis } = rest;
    const node = useRef();
    const searchRef = useRef();

    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [arrowSelectedIndex, setArrowSelectedIndex] = useState(-1);
    const [selectorMode, setSelectorMode] = useState('mouse');

    useOnClickOutside(node, () => {
      setIsOpen(false);
      setSearchTerm('');
    });

    useEffect(() => {
      if (setSearch) setSearch(searchTerm);
      if (searchTerm) setIsOpen(true);
    }, [searchTerm, setSearch]);

    const startPaging = useCallback(() => {
      if (localStartPaging && !loading) localStartPaging();
    }, [localStartPaging, loading]);

    const itemsIsCategory = useMemo(() => {
      return !!(items[0]?.items && items[0]?.category);
    }, [items]);

    const flatItems = useMemo(() => {
      const areItemsPurelyStrings = typeof items[0] !== 'string';

      if (!itemsIsCategory) {
        return areItemsPurelyStrings ? items : items.map(item => ({ value: item, label: item }));
      }

      const flatItems = [];
      items.forEach(category => {
        const { items } = category;
        const itemsToPush = areItemsPurelyStrings ? items : items.map(item => ({ value: item, label: item }));

        flatItems.push(itemsToPush);
      });
      return flatItems.flat();
    }, [items, itemsIsCategory]);

    const [selectedItemsAsString, selectedItemsAsObject] = useMemo(() => {
      const valueAsArray = (Array.isArray(value) ? value : [value]).filter(Boolean);

      const isValueTypeofObject = typeof valueAsArray[0] === 'object';

      const selectedItemsAsString = !isValueTypeofObject ? valueAsArray : valueAsArray.map(item => item.value);

      const selectedItemsAsObject = isValueTypeofObject
        ? valueAsArray
        : valueAsArray.map(value => {
            const item = flatItems.find(item => item.value === value);
            const label = typeof value === 'number' ? value.toString() : value;
            return item || { value: value, label: label };
          });

      return [selectedItemsAsString, selectedItemsAsObject];
    }, [flatItems, value]);

    useEffect(() => {
      if (isOpen) searchRef.current?.focus();
    }, [isOpen, value]);

    const onSelectElClick = useCallback(() => {
      if (disabled) return;
      if (isOpen && searchRef.current && document.activeElement !== searchRef.current) searchRef.current.focus();
      if (isOpen && searchRef.current) return;
      setIsOpen(prev => !prev);
    }, [disabled, isOpen]);

    const localItems = useMemo(() => {
      const isArray = Array.isArray(items);
      const hasObjects = isArray && typeof items[0] === 'object';

      if (!isArray) return [{ value: items, label: items }];
      if (itemsIsCategory) return items;

      return hasObjects ? items : items.map(item => ({ label: item, value: item }));
    }, [items, itemsIsCategory]);

    const onItemClick = useCallback(
      item => {
        setSearchTerm('');

        if (mode === 'single') {
          const itemIsSelected = selectedItemsAsString.includes(item.value);
          if (searchFilterSelect && itemIsSelected && onDeselect) {
            onDeselect(item.value);
            setIsOpen(false);
            return;
          }
          onChange && onChange(item.value);
          setIsOpen(false);
          return;
        }

        const itemIsSelected = selectedItemsAsString.includes(item.value);

        if (itemIsSelected && onDeselect) onDeselect(item.value);
        if (!itemIsSelected && onSelect) onSelect(item.value);

        if (itemIsSelected && onChange) return onChange(selectedItemsAsString.filter(value => value !== item.value));
        onChange && onChange([...selectedItemsAsString, item.value]);
      },
      [mode, onChange, onDeselect, onSelect, selectedItemsAsString, searchFilterSelect],
    );

    const filteredItems = useMemo(() => {
      if (!searchTerm && !hideOptionIfSelected) return localItems;

      if (!itemsIsCategory) {
        let tempItems = localItems;
        if (searchTerm)
          tempItems = tempItems.filter(item => item.label.toLowerCase().includes(searchTerm.toLowerCase()));
        if (hideOptionIfSelected) tempItems = tempItems.filter(item => !selectedItemsAsString.includes(item.value));
        return tempItems;
      }

      const filteredCategories = [];
      localItems.forEach(category => {
        let tempItems = category.items;
        if (searchTerm)
          tempItems = tempItems.filter(item => item.label.toLowerCase().includes(searchTerm.toLowerCase()));
        if (hideOptionIfSelected) tempItems = tempItems.filter(item => !selectedItemsAsString.includes(item.value));
        if (tempItems.length) {
          filteredCategories.push({
            ...category,
            items: tempItems,
          });
        }
      });
      return filteredCategories;
    }, [hideOptionIfSelected, itemsIsCategory, localItems, searchTerm, selectedItemsAsString]);

    const flatFilteredItems = useMemo(() => {
      if (!itemsIsCategory) return filteredItems;
      return filteredItems.map(category => category.items).flat();
    }, [filteredItems, itemsIsCategory]);

    const increaseArrowSelectedIndex = useCallback(() => {
      const maxIndex = !itemsIsCategory
        ? filteredItems.length - 1
        : filteredItems.reduce((acc, category) => acc + category.items.length, 0) - 1;
      if (arrowSelectedIndex < maxIndex) {
        setArrowSelectedIndex(prev => prev + 1);
        setSelectorMode('keyboard');
      }
    }, [arrowSelectedIndex, filteredItems, itemsIsCategory]);

    const decreaseArrowSelectedIndex = useCallback(() => {
      if (arrowSelectedIndex > 0) {
        setArrowSelectedIndex(prev => prev - 1);
        setSelectorMode('keyboard');
      }
    }, [arrowSelectedIndex]);

    const resetArrowSelectedIndex = useCallback(() => {
      setArrowSelectedIndex(-1);
    }, []);

    const onMouseOverItem = useCallback(index => {
      setArrowSelectedIndex(index);
      setSelectorMode('mouse');
    }, []);

    useEffect(() => {
      resetArrowSelectedIndex();
    }, [resetArrowSelectedIndex, searchTerm]);

    const renderSelectElLabel = useMemo(() => {
      return (
        <SelectElLabel
          canSearch={canSearch}
          mode={mode}
        >
          {selectedItemsAsObject[0]?.label}
        </SelectElLabel>
      );
    }, [canSearch, mode, selectedItemsAsObject]);

    const renderSelectedEl = useMemo(() => {
      if (mode === 'multi' && tagsMode === 'list') return;
      if (mode === 'multi' || (mode === 'single' && searchFilterSelect)) {
        return (
          <Tags
            items={selectedItemsAsObject}
            disabled={disabled}
            onRemove={onItemClick}
          />
        );
      }
      if (!canSearch) return renderSelectElLabel;
    }, [
      canSearch,
      disabled,
      mode,
      onItemClick,
      renderSelectElLabel,
      selectedItemsAsObject,
      tagsMode,
      searchFilterSelect,
    ]);

    const renderLabel = useMemo(() => {
      if (selectedItemsAsObject.length) return renderSelectedEl;
      if (!canSearch) return <SelectElLabel placeholder={placeholder}>{placeholder}</SelectElLabel>;
      return null;
    }, [selectedItemsAsObject.length, renderSelectedEl, canSearch, placeholder]);

    const handleKeyDown = useCallback(
      event => {
        if (event.key === 'shift' || event.key === 'Control' || event.key === 'Alt') return;
        if (event.key === 'Tab') return setIsOpen(false);

        const isFocusedOnSearch = searchRef.current && document.activeElement === searchRef.current;
        const isFocusedOnParent = !!node.current && document.activeElement === node.current.children?.[0];
        const isCurrentTarget = isFocusedOnSearch || isFocusedOnParent;
        const isInFocus = isOpen || isCurrentTarget;

        if (!isInFocus) return;
        if (!isOpen) return setIsOpen(true);

        if (event.key === 'Backspace' && mode === 'single' && !searchTerm) {
          onChange('');
        }
        if (event.key === 'Escape') {
          event.stopPropagation();
          setSearchTerm('');
          setIsOpen(false);
        }
        if (event.key === 'ArrowDown') {
          event.preventDefault();
          increaseArrowSelectedIndex();
        }
        if (event.key === 'ArrowUp') {
          event.preventDefault();
          decreaseArrowSelectedIndex();
        }
        if (event.key === 'Enter') {
          event.stopPropagation();
          if (flatFilteredItems[arrowSelectedIndex]) {
            onItemClick(flatFilteredItems[arrowSelectedIndex]);
            searchRef.current?.blur();
          }
          if (mode === 'single') {
            resetArrowSelectedIndex();
          }
        }
      },
      [
        isOpen,
        mode,
        searchTerm,
        onChange,
        increaseArrowSelectedIndex,
        decreaseArrowSelectedIndex,
        flatFilteredItems,
        arrowSelectedIndex,
        onItemClick,
        resetArrowSelectedIndex,
      ],
    );

    useEffect(() => {
      document.addEventListener('keydown', handleKeyDown);
      return () => document.removeEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

    const renderSingleSearch = useMemo(() => {
      const anyItemSelected = selectedItemsAsString.length > 0;

      const localPlaceholder = anyItemSelected ? selectedItemsAsObject[0].label : placeholder ?? 'Search...';

      const searchFilterSelectPlaceholder = !anyItemSelected && placeholder;

      if (searchFilterSelect && !searchFilterSelectPlaceholder) {
        return;
      }

      return (
        <SingleSearchInput
          ref={searchRef}
          value={searchTerm}
          onChange={e => {
            setSearchTerm(e.target.value);
          }}
          placeholder={searchFilterSelect ? searchFilterSelectPlaceholder : localPlaceholder}
          anyItemSelected={anyItemSelected}
          mode={mode}
        />
      );
    }, [mode, placeholder, searchTerm, selectedItemsAsObject, selectedItemsAsString.length, searchFilterSelect]);

    const renderSearch = useMemo(() => {
      if (mode === 'single') return renderSingleSearch;

      return (
        <SearchInput
          ref={searchRef}
          value={searchTerm}
          onChange={e => {
            setSearchTerm(e.target.value);
          }}
          placeholder={placeholder ?? 'Search...'}
          mode={mode}
        />
      );
    }, [mode, placeholder, renderSingleSearch, searchTerm]);

    const renderTagList = useMemo(() => {
      return (
        <TagListWrapper isOpen={isOpen}>
          <Tags
            items={selectedItemsAsObject}
            disabled={disabled}
            onRemove={onItemClick}
          />
        </TagListWrapper>
      );
    }, [disabled, isOpen, onItemClick, selectedItemsAsObject]);

    const shouldShowCheckbox = useMemo(() => {
      return checkbox ?? (!hideOptionIfSelected && mode === 'multi');
    }, [checkbox, hideOptionIfSelected, mode]);

    return (
      <SelectWrapper
        ref={node}
        width={width}
      >
        {mode === 'multi' && tagsMode === 'list' && renderTagList}
        <SelectEl
          onClick={onSelectElClick}
          ref={ref}
          isOpen={isOpen}
          mode={mode}
          canSearch={canSearch}
          tabIndex={0}
          {...rest}
        >
          {renderLabel}
          {canSearch && !disabled && renderSearch}
        </SelectEl>
        {isOpen && (
          <Options
            items={filteredItems}
            onClick={onItemClick}
            selectedItems={selectedItemsAsObject}
            mode={mode}
            checkbox={shouldShowCheckbox}
            startPaging={startPaging}
            arrowSelectedIndex={arrowSelectedIndex}
            selectorMode={selectorMode}
            onMouseOverItem={onMouseOverItem}
            parentRef={node}
            ellipsis={ellipsis}
            {...optionsWrapperProps}
          />
        )}
      </SelectWrapper>
    );
  },
);

const baseSelectPropTypes = {
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, ItemPropType])).isRequired,
  value: PropTypes.oneOfType([PropTypes.arrayOf(ItemPropType), ItemPropType, PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  canSearch: PropTypes.bool,
  startPaging: PropTypes.func,
  optionsWrapperProps: PropTypes.shape({
    maxHeight: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  tagsMode: PropTypes.oneOf(['default', 'list']),
  checkbox: PropTypes.bool,
};

Select.displayName = 'Select';
Select.propTypes = {
  ...baseSelectPropTypes,
  mode: PropTypes.oneOf(['single', 'multi']),
  onDeselect: PropTypes.func,
};

export default Select;
