import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';

function useTrackPageView(page, extras = null) {
  useEffect(() => {
    mixpanel.track(`View ${page} page`, extras);
  }, [page, extras]);
}

export default useTrackPageView;
