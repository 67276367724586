import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useNotification from '~/hooks/use-notification';
import CompanyEmailValidator from 'company-email-validator';
import Qualification from './Qualification';
import FillLoginInfoAndTerms from './FillLoginInfoAndTerms';
import FillUserInfo from './FillUserInfo';
import VerifyEmail from './VerifyEmail';
import AccountVerified from './AccountVerified';
import LinkExpired from './LinkExpired';
import {
  MainContainer,
  StyledVideo,
  SignUpCard,
  OnoLogo,
  GoBackBtn,
  SignUpCardWrapper,
  SignUpFadeOut,
} from './components/Elements';
import { useSignUp } from '@clerk/clerk-react';
import Icon from '~/components/Icon';
import { object as YupObject, string } from 'yup';
import { SignUpBigTitle, ColorCopperDark } from '~/components/Text';
import * as Sentry from '@sentry/browser';
import useLocationAndTechnologies from '~/hooks/use-location-and-technologies';
import mixpanel from 'mixpanel-browser';

const LOGIN_INFO_AND_TERMS = {
  key: 'login-info-and-terms',
};

const USER_INFO = {
  key: 'user-info',
  backStep: LOGIN_INFO_AND_TERMS.key,
};

const QUALIFICATION = {
  key: 'qualification',
  backStep: USER_INFO.key,
};

const VERIFY_EMAIL = {
  key: 'verify-email',
};

const ACCOUNT_VERIFIED = {
  key: 'account-verified',
};

const LINK_EXPIRED = {
  key: 'link-expired',
};

const START_STEP = LOGIN_INFO_AND_TERMS;

export const STEP_MAP = {
  qualification: QUALIFICATION,
  loginInfoAndTerms: LOGIN_INFO_AND_TERMS,
  userInfo: USER_INFO,
  verifyEmail: VERIFY_EMAIL,
  accountVerified: ACCOUNT_VERIFIED,
  linkExpired: LINK_EXPIRED,
};

const CLERK_EXPECTED_ERRORS = {
  codes: ['not_allowed_access'],
  paramNames: ['email_address', 'password'],
};

const VALIDATION_ERROR_MAP = {
  'emailAddress is a required field': 'Please provide a valid email address.',
  'password is a required field': 'Please provide a password.',
  'password must match the following: "/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{10,}$/"':
    'Minimum 10 characters, an upper and a number.',
  'emailAddress must be a valid email': 'Please provide a valid email address.',
  'firstName is a required field': 'Field required.',
  'lastName is a required field': 'Field required.',
  'companyName is a required field': 'Please provide your company name.',
  'jobTitle is a required field': 'Please provide your job title.',
  'heardAboutOnomondoFrom is a required field': 'Please let us know how you heard of Onomondo.',
  'Please provide a valid company email address.': 'Please provide a valid company email address.',
  'countryCode is a required field': 'Please provide a country.',
  'technology field must have at least 1 items': 'Please provide a technology.',
};

const addCustomErrorMessage = message => {
  const rawErrors = Object.keys(VALIDATION_ERROR_MAP);
  for (const rawError of rawErrors) {
    if (message.includes(rawError)) return VALIDATION_ERROR_MAP[rawError];
  }
};

const BACKGROUND_VIDEO_LINK = process.env.SIGN_UP_BACKGROUND_VIDEO_URL;

const SignUp = () => {
  const locationAndTechnologies = useLocationAndTechnologies();
  const location = useLocation();
  const history = useHistory();
  const { addNotification } = useNotification();
  const { signUp, isLoaded } = useSignUp();

  const [unsafeMetadata, setUnsafeMetadata] = useState({});
  const [step, setStep] = useState(STEP_MAP.loginInfoAndTerms.key);
  const [isAgreedToTerms, setIsAgreedToTerms] = useState(false);
  const [formData, setFormData] = useState({
    technology: [],
    countryCode: '',
    emailAddress: '',
    password: '',
    firstName: '',
    lastName: '',
  });
  const [errors, setErrors] = useState({
    technology: null,
    countryCode: null,
    emailAddress: null,
    password: null,
    firstName: null,
    lastName: null,
    companyName: null,
    useCase: null,
    heardAboutOnomondoFrom: null,
    emailVerificationCode: null,
    isAgreedToTerms: null,
    jobTitle: null,
  });

  useEffect(() => {
    if (location.search.includes('__clerk_status=verified')) setStep(STEP_MAP.accountVerified.key);
    if (location.search.includes('__clerk_status=expired')) setStep(STEP_MAP.linkExpired.key);
  }, [location]);

  const loginInfoAndTermsSchema = YupObject({
    emailAddress: string()
      .email()
      .test('is-company-email', 'Please provide a valid company email address.', () =>
        CompanyEmailValidator.isCompanyEmail(formData.emailAddress),
      )
      .required(),
    password: string()
      .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{10,}$/)
      .required(),
  });
  const userInfoSchema = YupObject({
    firstName: string().required(),
    lastName: string().required(),
    companyName: string().required(),
    useCase: string(),
    heardAboutOnomondoFrom: string().required(),
    jobTitle: string().required(),
  });

  const backStepKey = Object.values(STEP_MAP).find(s => s.key === step)?.backStep;

  function RenderBackButton() {
    return (
      <GoBackBtn onClick={() => setStep(backStepKey)}>
        <Icon
          name='arrow-left'
          height={16}
          width={16}
          color='gray'
          inline
        />{' '}
        Go back
      </GoBackBtn>
    );
  }

  const stepProps = {
    formData,
    setFormData,
    errors,
    setErrors,
    setStep,
    nextStep,
    unsafeMetadata,
    setUnsafeMetadata,
    startEmailVerification,
    isAgreedToTerms,
    setIsAgreedToTerms,
    RenderBackButton,
  };

  const CurrentStep = useMemo(() => {
    if (step === STEP_MAP.qualification.key) return Qualification;
    if (step === STEP_MAP.userInfo.key) return FillUserInfo;
    if (step === STEP_MAP.verifyEmail.key) return VerifyEmail;
    if (step === STEP_MAP.accountVerified.key) return AccountVerified;
    if (step === STEP_MAP.linkExpired.key) return LinkExpired;
    return FillLoginInfoAndTerms;
  }, [step]);

  async function startEmailVerification() {
    if (!isLoaded) return;

    const appUrl = process.env.APP_URL;

    try {
      const hubspotToken = document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*=\s*([^;]*).*$)|^.*$/, '$1');
      const { firstName, lastName } = formData;
      await signUp.update({
        firstName,
        lastName,
        unsafeMetadata: {
          ...unsafeMetadata,
          hubspotToken,
        },
      });
      await signUp.prepareVerification({
        strategy: 'email_link',
        redirectUrl: `${appUrl}/sign-up`,
      });
      return true;
    } catch (err) {
      const isAlreadySignedUpError = err?.errors?.some(({ code }) => code === 'session_exists');
      if (isAlreadySignedUpError) {
        addNotification({
          message: 'Looks like you already signed up once',
          type: 'error',
        });
        return false;
      }
      addNotification({
        message: 'Something went wrong.',
        type: 'error',
      });
    }
  }

  async function nextStep(e) {
    e?.preventDefault?.();
    if (e.mixpanel) {
      mixpanel.track(e.mixpanel.event, e.mixpanel.properties);
    }
    if (step === STEP_MAP.qualification.key) {
      const shouldMoveOn = await startEmailVerification();
      if (shouldMoveOn) setStep(STEP_MAP.verifyEmail.key);
    }
    if (step === STEP_MAP.loginInfoAndTerms.key) {
      const errors = await validateInputs(formData, unsafeMetadata);
      try {
        await signUp.create({
          emailAddress: formData.emailAddress,
          password: formData.password,
        });
      } catch (e) {
        const hasExpectedErrors =
          e.errors &&
          e.errors.some(({ code, meta: { paramName } }) => {
            return CLERK_EXPECTED_ERRORS.codes.includes(code) || CLERK_EXPECTED_ERRORS.paramNames.includes(paramName);
          });
        if (!hasExpectedErrors) {
          addNotification({
            message: 'Something unexpected went wrong, try again later.',
            type: 'error',
          });
          Sentry.captureException(e);
          return;
        }

        e.errors.forEach(({ meta, code, longMessage }) => {
          // The code "not_allowed_access" signifies that an email or account is not allowed to be created due to
          // rules set in Clerk, of course there's no good documentation on this in Clerk so here's a comment
          const errorKey =
            meta.paramName === 'email_address' || code === 'not_allowed_access' ? 'emailAddress' : meta.paramName;

          const doesErrorAlreadyExist = errors.some(({ key }) => key === errorKey);
          if (doesErrorAlreadyExist) return;

          errors.push({
            key: errorKey,
            message: longMessage,
          });
        });
      }
      errors.forEach(error => {
        setErrors(prev => {
          return { ...prev, [error.key]: error.message };
        });
      });
      if (!errors.length && isAgreedToTerms) {
        mixpanel.track('Self sign up submit email and terms', {
          Email: formData.emailAddress,
        });
        setStep(STEP_MAP.userInfo.key);
      }
    }
    if (step === STEP_MAP.userInfo.key) {
      const errors = await validateInputs(formData, unsafeMetadata);
      errors.forEach(error => {
        setErrors(prev => {
          return { ...prev, [error.key]: error.message };
        });
      });
      if (!errors.length) {
        mixpanel.track('Self sign up submit personal information', {
          Email: formData.emailAddress,
          Firstname: formData.firstName,
          Lastname: formData.lastName,
          Company: unsafeMetadata.companyName,
          'How did you hear about Onomondo?': unsafeMetadata.heardAboutOnomondoFrom,
          'Job title': unsafeMetadata.jobTitle,
          'Self sign up use case': unsafeMetadata.useCase,
        });
        if (locationAndTechnologies.error) {
          const shouldMoveOn = await startEmailVerification();
          if (shouldMoveOn) setStep(STEP_MAP.verifyEmail.key);
          return;
        }
        setStep(STEP_MAP.qualification.key);
      }
    }
    if (step === STEP_MAP.accountVerified.key) {
      history.push('/login');
    }
    if (step === STEP_MAP.linkExpired.key) {
      history.replace('/sign-up');
      setStep(START_STEP.key);
    }
  }

  async function validateInputs(formData, unsafeMetadata) {
    try {
      if (step === STEP_MAP.loginInfoAndTerms.key) {
        if (!isAgreedToTerms) setErrors({ ...errors, isAgreedToTerms: 'This field is required.' });
        await loginInfoAndTermsSchema.validate(
          {
            emailAddress: formData.emailAddress,
            password: formData.password,
          },
          { abortEarly: false },
        );
      }
      if (step === STEP_MAP.userInfo.key) {
        await userInfoSchema.validate(
          {
            firstName: formData.firstName,
            lastName: formData.lastName,
            companyName: unsafeMetadata.companyName,
            useCase: unsafeMetadata.useCase,
            heardAboutOnomondoFrom: unsafeMetadata.heardAboutOnomondoFrom,
            jobTitle: unsafeMetadata.jobTitle,
          },
          { abortEarly: false },
        );
      }
      return [];
    } catch (validationErrors) {
      const formattedErrors = [];
      for (const validationError of validationErrors.inner) {
        formattedErrors.push({ key: validationError.path, message: addCustomErrorMessage(validationError.message) });
      }
      return formattedErrors;
    }
  }

  return (
    <MainContainer>
      <OnoLogo src={require('~/assets/graphics/onomondo-logo-type-negative.svg')} />
      <StyledVideo
        autoPlay={true}
        muted={true}
        loop={true}
        src={BACKGROUND_VIDEO_LINK}
      />
      <SignUpBigTitle>
        A global <ColorCopperDark>network</ColorCopperDark>
        <br />
        made for IoT.
      </SignUpBigTitle>
      <SignUpCard>
        <SignUpCardWrapper>
          <CurrentStep {...stepProps} />
          <SignUpFadeOut />
        </SignUpCardWrapper>
      </SignUpCard>
    </MainContainer>
  );
};

export default SignUp;
