import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Card, CardContent } from '~/components/Card';
import { ToolTipText } from '~/components/Text';
import Icon from '~/components/Icon';

const IconWithCursor = styled(Icon)`
  cursor: pointer;
`;

const HoverWrapper = styled.div`
  position: absolute;
  top: 100%;
  z-index: ${props => props.theme.zIndex.tooltip};
  left: 50%;
  transform: translate(${props => (props.offset ? '-' + props.offset : '-50%')}, 0);
  color: ${props => props.theme.lightest};
  ${props =>
    props.width &&
    css`
      width: ${props => props.width}px;
    `}
`;

const Wrapper = styled.div`
  display: flex;
  position: relative;

  & ${HoverWrapper} {
    display: none;
  }

  &:hover ${HoverWrapper} {
    display: block;
  }
`;

const HoverContent = styled.div`
  position: relative;
  margin-top: 10px;

  &::after,
  &::before {
    bottom: 100%;
    left: ${props => props.offset || '50%'};
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &::after {
    border-bottom-color: ${props => props.theme.darker};
    border-width: 10px;
    margin-left: -10px;
  }
  &::before {
    border-bottom-color: rgba(0, 0, 0, 0.05);
    border-width: 11px;
    margin-left: -11px;
  }
`;

const TitleWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  ${props =>
    props.underlined &&
    css`
      color: ${props => props.theme.font.muted};
      border-bottom: 1px dashed ${props => props.theme.font.muted};
      margin-bottom: -1px;
    `}
`;

const StyledCardContent = styled(CardContent)`
  font-size: 14px;
  display: block;
`;

const StyledCard = styled(Card)`
  background-color: ${props => props.theme.darker};
`;

function Tooltip({ title, underlined, width, children, offset }) {
  const ChildrenElement = typeof children === 'string' ? <ToolTipText>{children}</ToolTipText> : children;
  const TitleElement = typeof title === 'string' ? <p>{title}</p> : title;
  return (
    <Wrapper>
      <TitleWrapper underlined={underlined}>
        {title ? (
          TitleElement
        ) : (
          <IconWithCursor
            name={'information'}
            width={20}
            height={20}
          />
        )}
      </TitleWrapper>
      <HoverWrapper
        width={width}
        offset={offset}
      >
        <HoverContent offset={offset}>
          <StyledCard>
            <StyledCardContent>{ChildrenElement}</StyledCardContent>
          </StyledCard>
        </HoverContent>
      </HoverWrapper>
    </Wrapper>
  );
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node,
  underlined: PropTypes.bool,
  width: PropTypes.number,
  offset: PropTypes.string,
};

export default Tooltip;
