import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuthentication from '~/hooks/use-authentication';
import { Wrapper, HeaderImg, Form, Title, FormBottomWrapper } from '~/components/LoginElements';

function CheckAccess({ children }) {
  const { canView } = useAuthentication();

  if (canView) return children;
  return (
    <Wrapper>
      <HeaderImg
        width={145}
        height={20}
        src={require('~/assets/graphics/onomondo-logo-type-negative.svg')}
      />
      <Form as='div'>
        <Title>No access</Title>
        <p>Please contact an administrator of your team</p>
        <FormBottomWrapper>
          <Link to='/logout'>Click here to log out</Link>
        </FormBottomWrapper>
      </Form>
    </Wrapper>
  );
}

CheckAccess.propTypes = {
  children: PropTypes.node,
};

export default CheckAccess;
