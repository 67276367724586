import { Buffer } from 'buffer';
import ipRangeCheck from 'ip-range-check';
import ipParser from './ip';

export function fromApi(doc) {
  return {
    ...parse(doc),
    logType: 'signalling',
  };
}

function parse(log) {
  const parsed = ipParser(Buffer.from(log.packet, 'hex'));
  const isFromOnomondo = ipRangeCheck(parsed.sourceIp, [
    '185.228.68.0/22',
    '147.78.72.0/22',
    '212.9.6.127',
    '185.3.54.15',
  ]);

  return {
    info: log.info,
    simId: log.sim_id,
    packet: log.packet,
    protocol: log.protocol,
    timestamp: log.timestamp,
    isFromOnomondo,
  };
}
