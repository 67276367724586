const { default: useAuthentication } = require('./use-authentication');
const { default: useOrganizations } = require('./use-organizations');

function useEventFlags() {
  const { usersCurrentOrganizationId, assumedOrganizationId, usersOrganizations, isViewingAsAssumedOrganization } =
    useAuthentication();
  const organizations = useOrganizations();

  const isWantingToViewAsDifferentOrganization =
    isViewingAsAssumedOrganization && organizations.data && assumedOrganizationId;
  const organization = !isWantingToViewAsDifferentOrganization
    ? usersOrganizations?.find(o => o.id === usersCurrentOrganizationId)
    : organizations.data?.find(o => o.id === assumedOrganizationId);

  return organization?.eventFlags || {};
}

export default useEventFlags;
