import mixpanel from 'mixpanel-browser';
import React, { useState, createContext, useCallback } from 'react';

const NotificationContext = createContext();

function NotificationProvider(props) {
  const [notifications, setNotifications] = useState([]);

  const addNotification = useCallback(({ type = 'normal', expire = 5000, mode = 'timed', ...rest }) => {
    const id = Date.now();
    const token =
      mode === 'timed' &&
      setTimeout(() => {
        mixpanel.track('Expire notification', { notification: { id, type, token, ...rest } });
        setNotifications(prev => prev.filter(m => m.id !== id));
      }, expire);

    setNotifications(prev => [{ id, type, token, mode, ...rest }, ...prev]);
  }, []);

  const removeNotification = useCallback(
    id => {
      const notification = notifications.find(n => n.id === id);
      mixpanel.track('Close notification', { notification });

      setNotifications(prev => prev.filter(n => n.id !== id));
    },
    [notifications],
  );

  const cancelNotification = useCallback(
    id => {
      const notification = notifications.find(n => n.id === id);
      if (!notification) return;
      clearTimeout(notification.token);
      removeNotification(id);
    },
    [notifications, removeNotification],
  );

  const cancelAllNotifications = useCallback(() => {
    for (const { token } of notifications) {
      token && clearTimeout(token);
    }
    setNotifications([]);
  }, [notifications]);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        addNotification,
        cancelNotification,
        cancelAllNotifications,
      }}
      {...props}
    />
  );
}

export { NotificationProvider, NotificationContext };
