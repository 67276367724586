import { useState, useEffect, useCallback } from 'react';
import useAuthentication from '~/hooks/use-authentication';
import api from '~/lib/api';
import { fromApi as featureFlagsFromApi } from '~/lib/data-parsers/feature-flags';
import { organizationEventsFromApi } from '~/lib/data-parsers/event-flags';

function toApi(organization) {
  return {
    entity: organization.entity,
    hubspot_company_id: organization.hubspotCompanyId || null,
    tech_fee: organization.techFee || null,
    feature_bundle_name: organization.featureBundleName || null,
  };
}

function fromApi(organization) {
  return {
    id: organization.id,
    uuid: organization.uuid,
    activityUrl: organization.activity_url,
    entity: organization.entity,
    hubspotCompanyId: organization.hubspot_company_id || '',
    featureBundleName: organization.feature_bundle_name || '',
    techFee: organization.tech_fee || '',
    featureFlags: featureFlagsFromApi(organization.feature_flags),
    eventFlags: organizationEventsFromApi(organization.event_flags),
  };
}

function useOrganizations() {
  const { isLoggedIn, isGod, isDemiGod } = useAuthentication();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const isAllowedAccess = isGod || isDemiGod;

  const get = useCallback(async () => {
    if (!isLoggedIn) return;
    if (!isAllowedAccess) return;
    try {
      const response = await api.get('/organizations');
      setData(response.data.map(fromApi).sort((a, b) => a.entity.localeCompare(b.entity)));
      setLoading(false);
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  }, [isLoggedIn, isAllowedAccess]);

  async function create(organization) {
    const response = await api.post('/organizations', toApi(organization));
    setData([...data, fromApi(response.data)]);
  }

  async function update(organization) {
    const response = await api.patch(`/organizations/${organization.id}?force=true`, toApi(organization));
    setData(
      data.map(a => {
        if (a.id !== organization.id) return a;
        return fromApi(response.data);
      }),
    );
  }

  async function updateTest(organization) {
    const response = await api.patch(`/organizations/${organization.id}`, toApi(organization));
    return response;
  }

  useEffect(() => {
    get();
  }, [get, isLoggedIn]);

  return {
    loading,
    error,
    data,
    create,
    update,
    updateTest,
  };
}

export default useOrganizations;
