import React, { useState, createContext, useEffect, useCallback } from 'react';
import api, { useConfig } from '~/lib/api';
import useAuthentication from '~/hooks/use-authentication';
import mixpanel from 'mixpanel-browser';

const FeatureContext = createContext();

const fromAPI = feature => ({
  name: feature.name,
  features: feature.features,
  upgradeToOrder: feature.upgrade_to_order,
});

function FeatureProvider(props) {
  const [names, setNames] = useState(null);
  const [features, setFeatures] = useState(null);
  const [featureList, setFeatureList] = useState(null);
  const [loading, setLoading] = useState(true);
  const { userId, isLoggedIn, usersCurrentOrganizationId, assumedOrganizationId, isViewingAsAssumedOrganization } =
    useAuthentication();
  const getConfig = useConfig(assumedOrganizationId);

  useEffect(() => {
    const getFeatures = async () => {
      if (!isLoggedIn) return;
      try {
        const response = await api.get('/features', isViewingAsAssumedOrganization ? getConfig() : undefined);
        setNames(response.data.names);
        setFeatures(response.data.features);
        mixpanel.register({
          'Software Package': response.data.names[0], // technically we can have multiple packages, but we only use the first one
        });
        window.Appcues?.identify(userId, { softwarePackage: response.data.names[0] });
      } catch (err) {
        console.error(err);
      }
    };

    const getFeatureList = async () => {
      if (!isLoggedIn) return;
      try {
        const response = await api.get('/features/list');
        setFeatureList(response.data.map(fromAPI));
      } catch (err) {
        console.error(err);
      }
    };

    const runGetFunctions = async () => {
      setLoading(true);
      await Promise.all([getFeatures(), getFeatureList()]);
      setLoading(false);
    };

    runGetFunctions();
  }, [
    usersCurrentOrganizationId,
    isViewingAsAssumedOrganization,
    assumedOrganizationId,
    getConfig,
    isLoggedIn,
    userId,
  ]);

  function getObjPathValue(path, obj) {
    return path?.split('.').reduce((acc, path) => acc?.[path] || null, obj) ?? null;
  }

  const hasFeature = useCallback(
    feature => {
      return getObjPathValue(feature, features) ?? false;
    },
    [features],
  );

  function getTierByFeature(feature) {
    return (
      !!featureList &&
      featureList.find(({ features, upgradeToOrder }) => upgradeToOrder && getObjPathValue(feature, features))?.name
    );
  }

  function hasSoftwarePackage(softwarePackageName) {
    return names?.includes(softwarePackageName);
  }

  function getSoftwarePackage() {
    return names?.[0];
  }

  return (
    <FeatureContext.Provider
      value={{
        names,
        features,
        featureList,
        hasFeature,
        hasSoftwarePackage,
        getTierByFeature,
        getSoftwarePackage,
        loading,
      }}
      {...props}
    />
  );
}

export { FeatureProvider, FeatureContext };
