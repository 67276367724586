import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import OnomondoLogo from '~/components/OnomondoLogo';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  opacity: 0.5;
`;

const FillWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function Loader({ size = 140, animated = true }) {
  return (
    <Wrapper>
      <OnomondoLogo
        size={size}
        animated={animated}
      />
    </Wrapper>
  );
}

Loader.propTypes = {
  size: PropTypes.number,
  animated: PropTypes.bool,
};

function FillLoader(props) {
  return (
    <FillWrapper>
      <Loader {...props} />
    </FillWrapper>
  );
}

FillLoader.propTypes = Loader.propTypes;

export default Loader;
export { FillLoader };
