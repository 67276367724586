import subMinutes from 'date-fns/subMinutes';

function toInput(value) {
  if (!value) return value;
  return value.substring(0, 16);
}

function transformToUtc(dateObject) {
  const offsetInMinutes = dateObject.getTimezoneOffset();
  return subMinutes(dateObject, offsetInMinutes);
}

function fromInput(value) {
  if (!value) return value;
  return `${value}:00.000Z`;
}

export { toInput, fromInput, transformToUtc };
