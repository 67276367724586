import * as Dialog from '@radix-ui/react-dialog';
import { Command } from 'cmdk';
import mixpanel from 'mixpanel-browser';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useSims from '~/hooks/use-sims';
import useAuthentication from '~/hooks/use-authentication';
import useOrganizations from '~/hooks/use-organizations';
import Muted from '~/components/Typography/Muted';
import Icon from '~/components/Icon';
import styled, { keyframes } from 'styled-components';
import { transparentize } from 'polished';
import Spinner from '~/components/Spinner';

const ALLOWED_LABEL_LENGTH = 10;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Overlay = styled(Dialog.Overlay)`
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${props => transparentize(0.5, props.theme.darkest)};
  width: 100vw;
  height: 100vh;
  z-index: ${props => props.theme.zIndex.commandPalette};
  animation: ${fadeIn} 100ms cubic-bezier(0.16, 1, 0.3, 1);
`;

const Content = styled(Dialog.Content)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 524px;
  z-index: ${props => props.theme.zIndex.commandPalette};
  animation: ${fadeIn} 300ms cubic-bezier(0.16, 1, 0.3, 1);
`;

const CommandRoot = styled(Command)`
  width: 100%;
  background: ${props => props.theme.bgColor.main};
  border-radius: 8px;
  overflow: hidden;
  padding: 0;
`;

const Input = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  column-gap: 0.5rem;
  padding-left: 0.75rem;
  border-bottom: 1px solid ${props => props.theme.bgColor.muted};
`;

const CommandInput = styled(Command.Input)`
  flex: 1;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: none;
  outline: none;
`;

const CommandGroup = styled(Command.Group)`
  [cmdk-group-heading] {
    font-weight: 500;
    padding: 0 0.875rem 0.25rem;
  }
`;

const CommandList = styled(Command.List)`
  height: min(300px, var(--cmdk-list-height));
  max-height: 450px;
  padding: 0.5rem;
  overflow: auto;
  overscroll-behavior: contain;
  transition: 100ms ease;
  transition-property: height;
  box-sizing: content-box;
`;

const CommandItem = styled(Command.Item)`
  cursor: pointer;
  display: flex;
  padding: 0.625rem 0.875rem;
  justify-content: space-between;
  align-items: center;
  position: relative;
  content-visibility: auto;
  border-radius: 0.375rem;

  &[data-selected='true'] {
    background-color: ${props => transparentize(0.5, props.theme.bgColor.muted)};
  }
`;

const CommandEmpty = styled(Command.Empty)`
  padding: 0 0.875rem;
`;

const CommandMenu = () => {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const { isGod, isDemiGod, assumeOrganizationId } = useAuthentication();
  const { data: simsSearchData, search: simsSearch } = useSims({
    disableFetchSimsOnChange: true,
    disableSetUrlParamsOnSearch: true,
    from: 'CommandMenu',
  });
  const organizations = useOrganizations();
  const history = useHistory();

  function navigate(path) {
    history.push(path);
    mixpanel.track('Click command palette action', { path });
    setIsOpen(false);
    setPage(null);
    setSearch('');
    simsSearch.clear();
  }

  useEffect(() => {
    function down(e) {
      if (e.key === 'k' && e.metaKey) {
        setIsOpen(open => {
          if (!open) mixpanel.track('Command palette opened');
          return !open;
        });
      }
    }

    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, []);

  const defaultItems = [
    {
      id: 'dashboard',
      children: 'Go to Dashboard',
      onClick: () => {
        navigate('/');
      },
    },
    {
      id: 'sims',
      children: 'Go to SIMs',
      onClick: () => {
        navigate('/sims');
      },
    },
    {
      id: 'usage',
      children: 'Go to Usage',
      onClick: () => {
        navigate('/usage');
      },
    },
    {
      id: 'connectors',
      children: 'Go to Connectors',
      onClick: () => {
        navigate('/connectors');
      },
    },
    {
      id: 'network-lists',
      children: 'Go to Network Lists',
      onClick: () => {
        navigate('/network-lists');
      },
    },
    {
      id: 'webhooks',
      children: 'Go to Webhooks',
      onClick: () => {
        navigate('/webhooks');
      },
    },
    {
      id: 'api-keys',
      children: 'Go to API Keys',
      onClick: () => {
        navigate('/api-keys');
      },
    },
    {
      id: 'tags',
      children: 'Go to Tags',
      onClick: () => {
        navigate('/tags');
      },
    },
    {
      id: 'users',
      children: 'Go to Users',
      onClick: () => {
        navigate('/users');
      },
    },
    {
      id: 'error-log',
      children: 'Go to Error Log',
      onClick: () => {
        navigate('/error-log');
      },
    },
    {
      id: 'audit-log',
      children: 'Go to Audit Log',
      onClick: () => {
        navigate('/audit-logs');
      },
    },
  ];

  if (isGod || isDemiGod) {
    defaultItems.push(
      {
        id: 'organizations',
        children: 'Go to Organizations',
        onClick: () => {
          navigate('/admin/organizations');
        },
      },
      {
        id: 'price-lists',
        children: 'Go to Pricelists',
        onClick: () => {
          navigate('/admin/pricelists');
        },
      },
      {
        id: 'switch-organization',
        children: 'Switch organization',
        closeOnSelect: false,
        onClick: () => {
          setPage('organizations');
          setSearch('');
        },
      },
    );
  }

  return (
    <Dialog.Root
      open={isOpen}
      onOpenChange={value => {
        setIsOpen(value);
        setSearch('');
        setPage(null);
        simsSearch.clear();
      }}
    >
      <Dialog.Portal>
        <Overlay />
        <Content aria-label='Command Menu'>
          <CommandRoot
            loop
            onKeyDown={e => {
              const isEscape = e.key === 'Escape';
              const isBackspace = e.key === 'Backspace';

              const shouldClose = isEscape && !page && !search;
              const shouldClearSearch = isEscape && search;
              const shouldUnsetPage = isEscape || (isBackspace && !search);

              if (shouldClose) {
                e.preventDefault();
                setIsOpen(false);
              }

              if (shouldClearSearch) {
                e.preventDefault();
                simsSearch.clear();
                setSearch('');
              }

              if (shouldUnsetPage) {
                e.preventDefault();
                setPage(null);
              }
            }}
          >
            <Input>
              {simsSearch.loading ? (
                <Spinner size={16} />
              ) : (
                <Icon
                  name='search-dark-medium'
                  height={16}
                  width={16}
                  inline
                />
              )}
              <CommandInput
                placeholder='Search'
                value={search}
                onValueChange={value => {
                  setSearch(value);
                  const isSearchEmptyString = value === '';
                  if (isSearchEmptyString) {
                    simsSearch.clear();
                    return;
                  }
                  simsSearch.onChange({ target: { value } });
                }}
              />
            </Input>
            <CommandList>
              {!page && (
                <>
                  <CommandGroup heading='Navigation'>
                    {defaultItems.map(({ id, children, onClick }) => (
                      <CommandItem
                        key={id}
                        value={children}
                        onSelect={onClick}
                      >
                        {children}
                      </CommandItem>
                    ))}
                  </CommandGroup>

                  {simsSearchData?.length > 0 && (
                    <CommandGroup heading='SIMs'>
                      {simsSearchData?.map(sim => (
                        <CommandItem
                          key={sim.id}
                          value={`${sim.id} ${sim.label} ${sim.ipv4}`} // value is used for fuzzy search hence the weird string
                          onSelect={() => {
                            mixpanel.track('Go to SIM from command palette', { simId: sim.id });
                            navigate(`/sims/${sim.id}`);
                          }}
                        >
                          {sim.id}
                          <Muted>
                            {`${
                              sim.label?.length > 0
                                ? `Label → "${
                                    sim.label?.length <= ALLOWED_LABEL_LENGTH
                                      ? sim.label
                                      : `${sim.label.substring(0, ALLOWED_LABEL_LENGTH - 3)}...`
                                  }" `
                                : ''
                            }IP → ${sim.ipv4}`}
                          </Muted>
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  )}
                </>
              )}
              {page === 'organizations' && (
                <CommandGroup heading='Organizations'>
                  {organizations.data.map(org => (
                    <CommandItem
                      key={org.id}
                      onSelect={() => {
                        assumeOrganizationId(org.id);
                        setPage(null);
                        setSearch('');
                        setIsOpen(false);
                      }}
                    >
                      {org.entity}
                      <Muted>{`ID → "${org.id}"`}</Muted>
                    </CommandItem>
                  ))}
                </CommandGroup>
              )}
              <CommandEmpty>No results found.</CommandEmpty>
            </CommandList>
          </CommandRoot>
        </Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default CommandMenu;
