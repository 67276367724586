import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import StyledNavLink from '~/components/NavLink';
import useTiering from '~/hooks/use-tiering';
import { capitalize } from '~/lib/strings';

const Wrapper = styled.div`
  height: 60px;

  ${props =>
    !props.$isVertical &&
    css`
      margin-left: 10px;
      margin-right: 10px;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    `}
`;

const LocalStyledNavLink = styled(StyledNavLink)`
  flex-direction: column;
  align-items: flex-start;
`;

const IconWrapper = styled.div`
  margin-right: 8px;
  align-self: center;
  display: flex;
`;

const LowerWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const FeatureBanner = styled.p`
  display: flex;
  justify-content: center;
  margin: 10px 20px -10px 0;
  align-self: flex-end;
  background-color: ${props => props.theme.orangeHalf};
  border: 1px solid ${props => props.theme.orange};
  border-radius: 10px;
  min-width: 45px;
  width: min-content;
  padding: 0 5px;
`;

function MenuLink({ featureName, isVertical = false, icon, label, to }) {
  const tiering = useTiering();
  const hasFeature = featureName ? tiering.hasFeature(featureName) : true;
  const tierName = tiering.getTierByFeature(featureName);

  return (
    <Wrapper $isVertical={isVertical}>
      <LocalStyledNavLink
        $isVertical={isVertical}
        to={to}
      >
        {!hasFeature && <FeatureBanner>{capitalize(tierName)}</FeatureBanner>}
        <LowerWrapper>
          <IconWrapper>{icon}</IconWrapper>
          {label}
        </LowerWrapper>
      </LocalStyledNavLink>
    </Wrapper>
  );
}

MenuLink.propTypes = {
  featureName: PropTypes.string,
  isVertical: PropTypes.bool,
  to: PropTypes.string,
  icon: PropTypes.node,
  label: PropTypes.string,
};

export default MenuLink;
