import React from 'react';
import TextInput from './index';
import { propTypes as InputWrapperPropTypes } from '../InputWrapper';
import styled, { css } from 'styled-components';
import Icon from '~/components/Icon';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  padding-left: 5px;

  background-color: ${props => props.theme.lightest};

  border: 1px solid ${props => props.theme.lightMedium};
  border-radius: 5px;

  &:focus-within {
    outline: 2px solid ${props => props.theme.copperLightest};
    border-color: transparent;
  }

  ${props =>
    props.error &&
    css`
      border-color: ${props => props.theme.danger};
    `}

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      background-color: ${props => props.theme.lighter};
      border-color: ${props => props.theme.lightMedium};
    `}
`;

const InputIconWrapper = styled.div`
  display: flex;
  width: 35px;
  &:disabled-within {
    cursor: not-allowed;
    background-color: ${props => props.theme.lighter};
    border-color: ${props => props.theme.lightMedium};
  }
`;

function TextInputWrapper(props) {
  return (
    <Wrapper {...props}>
      {props.icon && (
        <InputIconWrapper {...props}>
          <Icon
            name='search-dark-medium'
            height={20}
            width={20}
          />
        </InputIconWrapper>
      )}
      <TextInput
        {...props}
        search={true}
      />
    </Wrapper>
  );
}

TextInputWrapper.propTypes = {
  ...InputWrapperPropTypes,
};

export default TextInputWrapper;
