export const fromApi = apiFeatureFlagsData => {
  if (!apiFeatureFlagsData) return {};
  const flags = {
    hasMqttConnectorAccess: apiFeatureFlagsData.has_mqtt_connector_access,
    hasDtlsConnectorAccess: apiFeatureFlagsData.has_dtls_connector_access,
    hasAwsIotCoreInCNNorthAccess: apiFeatureFlagsData.has_aws_iot_core_in_cn_north_access,
    isOnLegacyPricePlan: apiFeatureFlagsData.is_on_legacy_price_plan,
    shouldSuppressInvoiceNotification: !apiFeatureFlagsData.should_suppress_invoice_notification,
    hasConnectorsWithApplicationLayersAccess: apiFeatureFlagsData.has_connectors_with_application_layers_access,
    censorNetworkCostElements: apiFeatureFlagsData.censor_network_cost_elements,
    hideNetworkPriceFilter: apiFeatureFlagsData.hide_network_price_filter,
    hideNetworkCostElements: apiFeatureFlagsData.hide_network_cost_elements,
    hideUsageCostElements: apiFeatureFlagsData.hide_usage_cost_elements,
    hasIotCorePipeConnectorAccess: apiFeatureFlagsData.has_iot_core_pipe_connector_access,
  };

  return flags;
};
