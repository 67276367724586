import React, { useRef, useState } from 'react';
import { SelectEl, SelectElLabel, SelectWrapper } from './Select';
import styled from 'styled-components';
import useOnClickOutside from '~/hooks/use-on-click-outside';
import Tags from './Select/Tags';
import { PropTypes } from 'prop-types';

const PopupWrapper = styled.div`
  padding: 15px;
  background-color: ${props => props.theme.bgColor.main};
  border-radius: 4px;
  box-shadow: 1px 1px 12px 2px rgba(0, 0, 0, 0.15);

  margin-top: 0px;
  position: absolute;
  top: 38px;
  right: -5px;
  z-index: ${props => props.theme.zIndex.modal};
  width: ${props => props.width + 10}px;
`;

function SelectWithPopup({ renderPopup, items, onRemove, width = 200, placeholder }) {
  const [isOpen, setIsOpen] = useState(false);
  const node = useRef();

  useOnClickOutside(node, () => {
    setIsOpen(false);
  });

  function renderPlaceholder() {
    if (items.length === 0 && placeholder) {
      return <SelectElLabel placeholder={true}>{placeholder}</SelectElLabel>;
    }
    return null;
  }

  return (
    <SelectWrapper
      width={width}
      ref={node}
    >
      <SelectEl onClick={() => setIsOpen(prev => !prev)}>
        {renderPlaceholder()}
        <Tags
          onRemove={onRemove}
          items={items}
        />
      </SelectEl>
      {isOpen && (
        <PopupWrapper width={width}>
          {renderPopup({
            closePopup: () => setIsOpen(false),
          })}
        </PopupWrapper>
      )}
    </SelectWrapper>
  );
}

SelectWithPopup.propTypes = {
  renderPopup: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string.isRequired,
    }),
  ),
  onRemove: PropTypes.func,
  width: PropTypes.number,
  placeholder: PropTypes.string,
};

export default SelectWithPopup;
