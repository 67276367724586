import React from 'react';
import styled from 'styled-components';
import useNotification from '~/hooks/use-notification';
import Notification from '~/components/Notification';
import Icon from '~/components/Icon';
import { ModalParagraph } from '~/components/Text';
import Button from '~/components/Button';
import mixpanel from 'mixpanel-browser';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${props => props.theme.zIndex.notification};
  width: 100%;
  margin-top: 14px;
  padding: 0 60px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconWrapper = styled.div`
  margin-right: 5px;
`;

function NotificationCenter() {
  const { notifications, cancelNotification } = useNotification();

  return (
    <Wrapper>
      <List>
        {notifications.map((notification, idx) => (
          <Notification
            key={idx}
            type={notification.type}
            onClick={() => cancelNotification(notification.id)}
          >
            <div>
              {notification.icon && (
                <IconWrapper>
                  <Icon
                    inline
                    {...notification.icon}
                  />
                </IconWrapper>
              )}
              <ModalParagraph>{notification.message}</ModalParagraph>
            </div>
            <div>
              {notification.mode === 'timed' ? (
                <Icon
                  name='close'
                  width={15}
                  height={15}
                />
              ) : (
                <Button
                  variant='tertiary'
                  linkProps={{
                    method: 'external',
                    target: '_blank',
                    url: notification.buttonHref,
                  }}
                  onClick={() => mixpanel.track('Click on notification button', notification)}
                >
                  {notification.buttonLabel || 'See more'}
                </Button>
              )}
            </div>
          </Notification>
        ))}
      </List>
    </Wrapper>
  );
}

export default NotificationCenter;
